<template>
	<VDialog
		ref="baseDialog"
		v-model="isOpen"
		data-testid="elementDialog"
		class="base-dialog"
		:width="!fullscreen ? sizeValue : undefined"
		:fullscreen="fullscreen"
		:scrollable="scrollable"
		:retain-focus="false"
	>
		<VCard
			ref="baseDialogCard"
			class="d-flex flex-column overflow-hidden pa-0"
			color="background"
		>
			<div
				id="base-dialog-fallback-focus"
				tabindex="-1"
			/>
			<VCardTitle
				ref="baseDialogTitle"
				:class="['d-flex align-center px-6 pt-6 pb-4', { 'elevation-2': !isAtTop}]"
			>
				<VIcon
					v-if="headerIcon"
					ref="baseDialogHeaderIcon"
					class="mr-2"
					:color="headerIcon.color"
					:icon="headerIcon.icon"
					:size="headerIcon.size"
				/>
				<div
					ref="baseDialogTitle"
					class="flex-grow-1 flex-shrink-1 text-h3 text-high-emphasis"
				>
					{{ title }}
				</div>
				<div
					v-if="timestamp"
					ref="baseDialogTimestamp"
					:class="[
						'ml-2',
						{ 'mr-2': $slots.headerActions || closeButtonVisible }
					]"
				>
					{{ format(timestamp, 'PP') }}
				</div>
				<slot name="headerActions" />
				<VBtn
					v-if="closeButtonVisible"
					ref="baseDialogCloseButton"
					data-testid="buttonDialogClose"
					color="background"
					icon
					size="small"
					elevation="0"
					@click="close"
				>
					<VIcon
						icon="close"
						size="24"
					/>
				</VBtn>
			</VCardTitle>
			<VCardText
				id="scroll-target"
				ref="baseDialogText"
				:class="[
					'flex-grow-1 flex-shrink-1',
					{'px-6 pt-0 pb-6' : !contentUnstyled},
					{'pa-0' : contentUnstyled},
				]"
			>
				<div
					v-scroll:#scroll-target="onScroll"
					class="h-100"
				>
					<slot />
				</div>
			</VCardText>
			<VCardActions
				v-if="$slots.actions"
				ref="baseDialogActions"
				class="base-dialog__actions pa-0 d-block"
			>
				<VDivider />
				<div class="pa-6 d-flex align-center justify-end ga-2">
					<slot name="actions" />
				</div>
			</VCardActions>
		</VCard>
	</VDialog>
</template>

<script lang="ts" setup>
import { computed, ref, watch, nextTick } from 'vue';
import { format } from 'date-fns';
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap';
import type { VCard } from 'vuetify/components/VCard';


const SIZES = {
	xsmall: 460,
	small: 600,
	medium: 900,
	large: 1200,
	xlarge: 1536,
} as const;
type Size = keyof typeof SIZES;

interface HeaderIcon {
	color: string;
	icon: string;
	size: number;
}

const props = withDefaults(defineProps<{
	closeButtonVisible?: boolean;
	contentUnstyled?: boolean;
	fullscreen?: boolean;
	headerIcon?: HeaderIcon;
	modelValue: boolean;
	size?: Size;
	scrollable?: boolean;
	timestamp?: Date;
	title?: string;
}>(), {
	closeButtonVisible: true,
	contentUnstyled: false,
	fullscreen: false,
	headerIcon: undefined,
	modelValue: false,
	scrollable: true,
	size: 'medium',
	timestamp: undefined,
	title: undefined,
});

const isAtTop = ref(true);
const scrolledToBottom = ref(false);

const emit = defineEmits<{
	(e: 'update:modelValue', payload: boolean): void;
}>();

const isOpen = computed<boolean>({
	get() {
		return props.modelValue;
	},
	set(val) {
		emit('update:modelValue', val);
	},
});

const sizeValue = computed<number>(() => SIZES[props.size]);

const close = () => {
	isOpen.value = false;
};

const onScroll = (e: Event) => {
	const target = e.target as HTMLElement;
	isAtTop.value = target.scrollTop === 0;
	scrolledToBottom.value = target.scrollTop + target.clientHeight >= target.scrollHeight - 50;
};

const baseDialogCard = ref();

const focusTrap = useFocusTrap(baseDialogCard, {
	allowOutsideClick: (e: Event) => {
		return e.target !== baseDialogCard.value || baseDialogCard.value.contains(e.target as Node);
	},
	immediate: true,
	fallbackFocus: '#base-dialog-fallback-focus',
});

watch(isOpen, (val) => {
	if (val) {
		nextTick(() => {
			focusTrap.activate();
		});
	} else {
		focusTrap.deactivate();
	}
});

defineExpose({
	scrolledToBottom,
});
</script>
