<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		version="1.2"
		viewBox="0 0 24 24"
	>
		<path
			fill="currentColor"
			fill-rule="evenodd"
			d="M5 6.6C5 4.1 7.1 2 9.6 2h4.7C16.9 2 19 4.1 19 6.6v10.8c0 2.5-2.1 4.6-4.7 4.6H9.6C7.1 22 5 19.9 5 17.4zm7.8 12.3q.6-.1 1.1-.3.6-.2 1.1-.6.4-.4.7-.9.3-.5.5-1.1v-.8c0-1.2-.6-2.3-1.6-3l-.4-.3.4-.3c1-.6 1.6-1.8 1.6-3v-.7c-.3-1.6-1.8-2.9-3.4-3H8v2.9h4.7c.4 0 .8.4.8.8q.1 0 .1.2c0 1-2 1.8-2.8 2.5v1.3c.8.7 2.8 1.5 2.8 2.5 0 .1-.1.2-.1.2 0 .4-.4.7-.8.8H8v2.8h4.8z"
		/>
	</svg>
</template>
