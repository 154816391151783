import { reactive, unref, ref, watch, defineAsyncComponent, type MaybeRef } from 'vue';

const SendMessageDialog = defineAsyncComponent(() => import('@/components/SendMessageDialog.vue'));
interface UseSendMessageDialogState {
	isOpen: boolean;
	selectedUsers: string[];
	onSuccess: (() => void) | undefined;
	onError: ((payload: unknown) => void) | undefined;
}

const DEFAULT_STATE: UseSendMessageDialogState = {
	isOpen: false,
	selectedUsers: [],
	onSuccess: undefined,
	onError: undefined,
} as const;

const getDefaultState = () => ({ ...DEFAULT_STATE });

const state = reactive<UseSendMessageDialogState>(getDefaultState());

function resetState() {
	Object.assign(state, getDefaultState());
}

const isRendered = ref<boolean>(false);

watch(() => state.isOpen, (isOpen) => {
	if (isOpen && !isRendered.value) isRendered.value = true;
	if (!isOpen) resetState();
});

interface UseSendMessageDialogOptions {
	selectedUsers?: MaybeRef<string[]>;
	onSuccess?: () => void;
	onError?: (payload: unknown) => void;
}

export function useSendMessageDialog(options?: UseSendMessageDialogOptions) {
	const open = (athleteUuid?: Uuid) => {
		state.selectedUsers = athleteUuid ? [athleteUuid] : unref(options?.selectedUsers) || [];
		state.onSuccess = options?.onSuccess;
		state.onError = options?.onError;
		state.isOpen = true;
	};


	return {
		state,
		isRendered,
		resetState,
		open,
		SendMessageDialog,
	};
}
