<template>
	<svg
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M18.8125 14.0081V5.9919C18.8125 5.28263 18.4357 4.6289 17.8229 4.27348L10.8958 0.265381C10.2831 -0.0884605 9.52942 -0.0884605 8.91667 0.265381L1.98958 4.27348C1.37683 4.62732 1 5.28263 1 5.9919V14.0081C1 14.7174 1.37683 15.3711 1.98958 15.7265L8.91667 19.7346C9.52942 20.0885 10.2831 20.0885 10.8958 19.7346L17.8229 15.7265C18.4357 15.3727 18.8125 14.7174 18.8125 14.0081Z"
			fill="#7B62D5"
		/>
		<path
			d="M10.5504 5.01097V9.60029H9.26038V5.01097C9.26038 4.65981 9.54888 4.375 9.90622 4.375C10.0832 4.375 10.2455 4.4462 10.3619 4.56109C10.4783 4.67599 10.5504 4.83458 10.5504 5.01097Z"
			fill="white"
		/>
		<path
			d="M6.35252 8.51768V9.60028H5.0625V8.51768C5.0625 8.16652 5.351 7.88171 5.7067 7.88171C5.88373 7.88171 6.04601 7.95292 6.16239 8.06782C6.28041 8.18271 6.35252 8.34291 6.35252 8.51768Z"
			fill="white"
		/>
		<path
			d="M14.75 8.51768V9.60028H13.46V8.51768C13.46 8.16652 13.7484 7.88171 14.1041 7.88171C14.2828 7.88171 14.4435 7.95292 14.5598 8.06782C14.6779 8.18271 14.75 8.34291 14.75 8.51768Z"
			fill="white"
		/>
		<path
			d="M8.45054 6.93342V9.60028H7.16052V6.93342C7.16052 6.58065 7.45064 6.29584 7.80634 6.29584C7.98501 6.29584 8.14565 6.36703 8.26203 6.48193C8.37841 6.59844 8.45054 6.75704 8.45054 6.93342Z"
			fill="white"
		/>
		<path
			d="M12.6502 6.93342V9.60028H11.3602V6.93342C11.3602 6.75704 11.4323 6.59844 11.5487 6.48193C11.6651 6.36703 11.8273 6.29584 12.0044 6.29584C12.3617 6.29584 12.6502 6.58065 12.6502 6.93342Z"
			fill="white"
		/>
		<path
			d="M9.26202 14.989V10.3997H10.552V14.989C10.552 15.3402 10.2636 15.625 9.90622 15.625C9.72919 15.625 9.56691 15.5538 9.45053 15.4389C9.33415 15.324 9.26202 15.1654 9.26202 14.989Z"
			fill="#9E9EE8"
		/>
		<path
			d="M13.46 11.4823V10.3997H14.75V11.4823C14.75 11.8335 14.4615 12.1183 14.1058 12.1183C13.9288 12.1183 13.7665 12.0471 13.6501 11.9322C13.5321 11.8173 13.46 11.6571 13.46 11.4823Z"
			fill="#9E9EE8"
		/>
		<path
			d="M5.0625 11.4823V10.3997H6.35252V11.4823C6.35252 11.8335 6.06404 12.1183 5.70834 12.1183C5.52967 12.1183 5.36903 12.0471 5.25265 11.9322C5.13463 11.8173 5.0625 11.6571 5.0625 11.4823Z"
			fill="#9E9EE8"
		/>
		<path
			d="M11.3617 13.0666V10.3997H12.6517V13.0666C12.6517 13.4194 12.3616 13.7042 12.0059 13.7042C11.8272 13.7042 11.6666 13.633 11.5502 13.5181C11.4338 13.4016 11.3617 13.243 11.3617 13.0666Z"
			fill="#9E9EE8"
		/>
		<path
			d="M7.16235 13.0666V10.3997H8.45237V13.0666C8.45237 13.243 8.38024 13.4016 8.26386 13.5181C8.14748 13.633 7.9852 13.7042 7.80817 13.7042C7.45084 13.7042 7.16235 13.4194 7.16235 13.0666Z"
			fill="#9E9EE8"
		/>
	</svg>
</template>
