<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 20 20"
	><path
		fill="currentcolor"
		d="M10.5 15.88c-.75.21-1.56.33-2.4.33a7.6 7.6 0 0 0 3.85 1.04 8.8 8.8 0 0 0 1.66-.2l1.27 1A1.3 1.3 0 0 0 17 17.03v-1.69a6.48 6.48 0 0 0 2.18-4.8 6.6 6.6 0 0 0-3.52-5.76c.35.65.61 1.35.76 2.09a5.23 5.23 0 0 1 1.54 3.67c0 1.7-.85 3.23-2.17 4.24v2.25c0 .07-.09.11-.14.07l-1.73-1.36s-1.27.3-1.96.3c-.5 0-.98-.05-1.44-.16Z"
	/><path
		fill="currentcolor"
		fill-rule="evenodd"
		d="M4.36 14.93a.09.09 0 0 1-.14-.07v-2.25a5.32 5.32 0 0 1-2.17-4.23c0-3.04 2.68-5.5 5.99-5.5 3.3 0 5.98 2.46 5.98 5.5 0 3.03-2.68 5.5-5.98 5.5-.69 0-1.96-.3-1.96-.3l-1.72 1.35Zm2.03-.05.2.03c.4.08.98.17 1.45.17 3.88 0 7.2-2.9 7.2-6.7 0-3.8-3.32-6.71-7.2-6.71-3.89 0-7.2 2.9-7.2 6.7 0 1.9.84 3.6 2.16 4.8v1.7a1.3 1.3 0 0 0 2.11 1.01l1.28-1Z"
		clip-rule="evenodd"
	/><path
		fill="currentcolor"
		d="m9.26 7.01.48-.46a.3.3 0 0 0-.03-.47c-.33-.25-.73-.4-1.15-.42V5.1a.3.3 0 0 0-.3-.3h-.44a.3.3 0 0 0-.3.3v.56c-.9.03-1.61.8-1.53 1.72.06.66.57 1.2 1.2 1.38l1.4.4c.14.05.23.17.23.31 0 .17-.14.31-.31.31h-.9a.75.75 0 0 1-.43-.12.3.3 0 0 0-.37.03l-.48.46a.3.3 0 0 0 .04.47c.32.25.73.4 1.14.42v.57c0 .17.14.3.3.3h.44a.3.3 0 0 0 .3-.3v-.57A1.62 1.62 0 0 0 10 10c.3-.9-.22-1.8-1.05-2.04l-1.48-.43a.31.31 0 0 1-.22-.3c0-.17.14-.31.31-.31h.91c.15 0 .3.04.42.13A.3.3 0 0 0 9.26 7Z"
	/></svg>
</template>
