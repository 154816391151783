<template>
	<svg
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M18.625 13.8309V5.91612C18.625 5.21582 18.2521 4.57035 17.6458 4.21942L10.7917 0.262025C10.1854 -0.0873417 9.43963 -0.0873417 8.83333 0.262025L1.97917 4.21942C1.37287 4.56879 1 5.21582 1 5.91612V13.8309C1 14.5312 1.37287 15.1767 1.97917 15.5276L8.83333 19.485C9.43963 19.8344 10.1854 19.8344 10.7917 19.485L17.6458 15.5276C18.2521 15.1783 18.625 14.5312 18.625 13.8309Z"
			fill="#7B62D5"
		/>
		<path
			d="M15.3444 12.7812L9.91431 5.74376C9.88298 5.70459 9.84068 5.68579 9.79681 5.68579V6.44719C9.82501 6.44719 9.85478 6.46129 9.87044 6.49262L11.2193 9.01966C11.2554 9.08546 11.1958 9.16536 11.1253 9.14656L10.1728 8.90216V11.0203C10.1728 11.2193 10.2997 11.3947 10.4877 11.4574L15.1799 13.0256C15.3224 13.0742 15.4368 12.9003 15.3444 12.7812Z"
			fill="#9E9EE8"
		/>
		<path
			d="M9.7968 5.68579V6.44719C9.76704 6.44719 9.73884 6.46129 9.7216 6.49262L8.37428 9.01966C8.33824 9.08546 8.39621 9.16536 8.46827 9.14656L9.4208 8.90216V11.0203C9.4208 11.2193 9.2939 11.3947 9.1059 11.4574L4.41531 13.0256C4.27274 13.0742 4.1568 12.9003 4.2508 12.7812L9.68087 5.74376C9.71064 5.70459 9.7545 5.68579 9.79837 5.68579H9.7968Z"
			fill="white"
		/>
	</svg>
</template>
