import { reactive } from 'vue';
import { useAuthStore } from '@/stores/authStore';
import { useUserStore } from '@/stores/userStore';
import { useRouter, type RouteLocationNormalized } from 'vue-router';
import type { ImpersonationAuthRequest } from '@/types/auth';
import { type UserProfile, ProfileAccessService } from '@/types/users';

interface UseImpersonationAuthOptions {
	onImpersonationSuccess?: () => void;
	onImpersonationError?: () => void;
}

export const isImpersonationLoginRoute = (route: RouteLocationNormalized) => route.name === 'LoginImpersonate' && route.query.acting_as;

export function useImpersonationAuth(options?: UseImpersonationAuthOptions) {
	const authStore = useAuthStore();
	const userStore = useUserStore();
	const router = useRouter();

	const defaultImpersonationTokenRequest: ImpersonationAuthRequest = {
		user: '',
	};
	const impersonationTokenRequest: ImpersonationAuthRequest = reactive({ ...defaultImpersonationTokenRequest });

	function reset() {
		Object.assign(impersonationTokenRequest, { ...defaultImpersonationTokenRequest });
	}

	function handleSuccess() {
		if (options?.onImpersonationSuccess !== undefined) {
			options.onImpersonationSuccess();
		}
	}

	function handleError() {
		reset();
		if (options?.onImpersonationError !== undefined) {
			options.onImpersonationError();
		}
	}

	const checkForAuthorizedImpersonatorRole = (profile: UserProfile) => {
		const profileAccess = new ProfileAccessService(profile);
		return profileAccess.canImpersonate;
	};

	async function logInImpersonation() {
		try {
			await userStore.setUser();
			let authorizedProfileUuid: string | undefined = undefined;

			const profile = userStore.userProfiles![0];
			authorizedProfileUuid = profile.uuid;

			const hasMultipleProfiles = userStore.userProfiles?.length !== 1;
			if (hasMultipleProfiles) {
				const profile = userStore.userProfiles!.find(checkForAuthorizedImpersonatorRole);
				authorizedProfileUuid = profile?.uuid || authorizedProfileUuid;
			}

			userStore.setProfile(authorizedProfileUuid);
			await authStore.getTokenImpersonation(impersonationTokenRequest);
			await userStore.setImpersonatedUser();
			handleSuccess();
		} catch (e) {
			console.error(e);
			handleError();
		}
	}

	async function logOutImpersonation(redirect: boolean = true) {
		const token = authStore.impersonationToken;
		if (token) await authStore.removeImpersonationToken(token.uuid);
		userStore.unsetUser();
		if (redirect) await router.push({ name: 'ProfileChooserIndex' });
	}

	return {
		impersonationTokenRequest,
		logInImpersonation,
		logOutImpersonation,
	};
}
