import type { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
	{
		path: '/release-notes',
		children: [
			{
				path: '',
				name: 'ReleaseNotesIndex',
				component: () => import('@/views/ReleaseNotesIndex.vue'),
				meta: {
					layout: 'LayoutAuthenticated',
					requiresAuth: true,
					title: 'pageReleaseNotes.metaTitle',
				},
			},
		],
	},
];

export default routes;
