<template>
	<VSlideGroup
		ref="slideGroup"
		next-icon="arrow_forward"
		prev-icon="arrow_back"
	>
		<VBreadcrumbs
			ref="baseBreadcrumbs"
			class="px-0 pt-0 pb-2"
			density="compact"
			:items="items"
		>
			<template #title="{ item }">
				<VBtnText
					class="px-2"
					:class="item.disabled ? 'text-high-emphasis' : 'text-medium-emphasis'"
					data-testid="breadcrumbItem"
				>
					{{ item.title }}
				</VBtnText>
			</template>
			<template #divider>
				<VIcon class="text-medium-emphasis">
					chevron_right
				</VIcon>
			</template>
		</VBreadcrumbs>
	</VSlideGroup>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import type { RouteLocationRaw } from 'vue-router';
import type { VSlideGroup } from 'vuetify/components/VSlideGroup';

export interface BreadCrumbItem {
	title: string;
	disabled?: boolean;
	href?: string;
	to?: RouteLocationRaw;
	onClick?: () => void;
	additionalProperties?: Record<string, any>;
}

export interface Props {
	items: BreadCrumbItem[];
}

const props = defineProps<Props>();

const slideGroup = ref<VSlideGroup | null>(null);

watch(() => props.items.length, async () => {
	await new Promise<void>((resolve) => setTimeout(resolve, 150));
	slideGroup.value?.scrollTo('next');
});
</script>

<style lang="scss" scoped>
:deep(.v-breadcrumbs-item--disabled .v-btn__overlay) {
	opacity: calc(0.1 * var(--v-theme-overlay-multiplier));
}
</style>
