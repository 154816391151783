<template>
	<svg
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M8.7544 0.346101C9.55836 -0.117725 10.5458 -0.115198 11.3474 0.352737L17.6998 4.06085C18.5044 4.53053 19 5.39793 19 6.33657V13.6504C19 14.5876 18.5059 15.4539 17.7032 15.9241L11.3508 19.6453C10.5474 20.1159 9.55667 20.1184 8.75095 19.6519L2.3082 15.9218C1.49907 15.4534 1 14.5834 1 13.6415V6.34546C1 5.40206 1.50057 4.53102 2.31163 4.0631L8.7544 0.346101ZM12.6536 9.08837L14.0932 8.19585C14.227 8.1127 14.2691 7.93561 14.1869 7.80024L13.8111 7.18163C13.7288 7.04612 13.5533 7.00368 13.4191 7.08697L11.5136 8.2698C11.0651 8.54817 10.5488 8.69563 10.0222 8.69563H9.9779C9.45131 8.69563 8.93499 8.54817 8.48631 8.2698L6.58081 7.08697C6.44678 7.00368 6.27129 7.04612 6.18882 7.18163L5.81322 7.80024C5.73089 7.93561 5.77291 8.1127 5.90694 8.19585L7.3464 9.08837C8.02582 9.50988 8.30642 9.68539 8.60126 10.0235C8.88998 10.3554 9.1089 10.7467 9.25504 11.1919C9.32298 11.3988 9.35204 11.617 9.35232 11.835L9.35574 14.1613C9.35603 14.3202 9.48351 14.4486 9.64061 14.4486H10.3595C10.5164 14.4486 10.6441 14.3202 10.6444 14.1613L10.6476 11.835C10.6479 11.617 10.677 11.3988 10.7451 11.1919C10.8911 10.7467 11.11 10.3554 11.3988 10.0235C11.6935 9.68539 11.9743 9.50988 12.6536 9.08837Z"
			fill="black"
		/>
	</svg>
</template>
