import type { SortDirection, SortParam } from '@/api/utilities/provider';
import { i18n } from '@/plugins/i18n';
import type { ApiMediaAttachment } from '@/types/media';
import type { BaseTransaction } from '@/types/transactions';
import type { UppyFile } from '@uppy/core';
import { camelCase, isEmpty, isNil, replace, startCase } from 'lodash-es';
import { unref } from 'vue';
import { useUserStore } from '@/stores/userStore';
import { type ContextMenuItem } from '@/components/CommonContextMenuItem.vue';
import {
	type AgreementStatus,
	type ExchangeStatus,
	type LeaderboardStatus,
	type TransactionStatus,
} from '@teamworksdev/influencer-core';

export function formatBytes(bytes: number, decimals = 1) {
	if (bytes == 0) return `${i18n.global.n(0)} ${i18n.global.t('fileSizeAbbreviations.bytes')}`;
	const k = 1024;
	const dm = decimals || 2;
	const sizes = [
		i18n.global.t('fileSizeAbbreviations.bytes'),
		i18n.global.t('fileSizeAbbreviations.kb'),
		i18n.global.t('fileSizeAbbreviations.mb'),
		i18n.global.t('fileSizeAbbreviations.gb'),
		i18n.global.t('fileSizeAbbreviations.tb'),
		i18n.global.t('fileSizeAbbreviations.pb'),
		i18n.global.t('fileSizeAbbreviations.eb'),
		i18n.global.t('fileSizeAbbreviations.zb'),
		i18n.global.t('fileSizeAbbreviations.yb'),
	];
	const i = Math.floor(Math.log(bytes) / Math.log(k));
	return `${i18n.global.n(parseFloat((bytes / Math.pow(k, i)).toFixed(dm)))} ${sizes[i]}`;
}

export function formatUrlDisposition(dataurl: string, type: 'download' | 'view') {
	return new URL(dataurl).search ? dataurl + `&type=${type}` : dataurl + `?type=${type}`;
}

export function downloadUri(dataurl: string, filename: string) {
	const link = document.createElement('a');
	link.href = formatUrlDisposition(dataurl, 'download');
	link.download = filename;
	link.click();
	link.remove();
}

export function toggleItemInArray<T = string>(item: T, array: T[]) {
	if (array.includes(item)) {
		array.splice(array.indexOf(item), 1);
	} else {
		array.push(item);
	}
}

export function parseFileLocation(location: string) {
	const url = new URL(location);
	return url.pathname.replace(/^\//, '');
}

export const commaSeparate = (...values: Array<string | undefined | null>) => {
	return values.filter((i) => i).join(', ');
};

type FileSizeUnit = 'kb' | 'mb' | 'gb' | 'tb' | 'pb';
export const getBytes = (value: number, unit: FileSizeUnit): number => {
	const units: Record<FileSizeUnit, number> = {
		kb: 1,
		mb: 2,
		gb: 3,
		tb: 4,
		pb: 5,
	};

	const bytes = 1024 ** units[unit];
	return bytes * value;
};

export const formatSorts = <T extends string>(sorts: SortParam<T>[]) => {
	return sorts.map<[T, SortDirection]>(({ key, order }) => [key, order]);
};

export function isEmptyOrDefault(value: any, comparator: any) {
	return isEmpty(value) || value === comparator;
}

export function resolveApiMediaAttachmentsFromUppyFiles(files: UppyFile<any, any>[]) {
	if (!files.length) return undefined;
	return files.map<ApiMediaAttachment>((file) => ({
		fileName: file.name ?? '',
		key: parseFileLocation(file.response?.uploadURL ?? ''),
	}));
}

export function convertCentsToDollars(cents: number) {
	return cents / 100;
}

export const convertDollarsToCents = (amount: string | number) => {
	return typeof amount === 'string' ? (Number(amount.split('.')[0]) * 100) + Number(amount.split('.')[1]) : amount * 100;
};

export function statusInArray<T>(statuses: T[], status: T | undefined | null) {
	if (isNil(status)) return false;
	return statuses.includes(status);
}

export const exchangeStatusInArray = statusInArray<ExchangeStatus>;
export const leaderboardStatusInArray = statusInArray<LeaderboardStatus>;
export const agreementStatusInArray = statusInArray<AgreementStatus>;
export const transactionStatusInArray = statusInArray<TransactionStatus>;

export const sortTransactions = (transactions: BaseTransaction[]) => {
	return transactions.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
};

export function capitalizeAndTrim(value: string | undefined) {
	return replace(startCase(camelCase(value)), /\s+/g, '');
}

export function resolveVisibleContextMenuItem(item: ContextMenuItem) {
	const userStore = useUserStore();

	if (unref(item.visible) === false) return false;
	if (item.requiredAccess === undefined) return true;
	return item.requiredAccess instanceof Array ? item.requiredAccess.some((k) => userStore.profileAccess[k]) : userStore.profileAccess[item.requiredAccess];
}

export function resolveVisibleContextMenuItems(items: ContextMenuItem[]) {
	return items.filter((i) => {
		if (!isEmpty(i.items)) return i.items?.some((j) => resolveVisibleContextMenuItem(j)) && resolveVisibleContextMenuItem(i);
		return resolveVisibleContextMenuItem(i);
	});
};

export function truncateString(str: string, count: number) {
	return str.length > count
		? `${str.slice(0, count)}...`
		: str;
}

export const normalizeToArray = <T>(value: MaybeArray<T>): T[] => {
	return Array.isArray(value) ? value : [value];
};
