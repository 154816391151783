<template>
	<svg
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M18.625 13.8309V5.91612C18.625 5.21582 18.2521 4.57036 17.6458 4.21942L10.7917 0.262025C10.1854 -0.0873416 9.43963 -0.0873416 8.83333 0.262025L1.97917 4.21942C1.37287 4.56879 1 5.21582 1 5.91612V13.8309C1 14.5312 1.37287 15.1767 1.97917 15.5276L8.83333 19.485C9.43963 19.8344 10.1854 19.8344 10.7917 19.485L17.6458 15.5276C18.2521 15.1783 18.625 14.5312 18.625 13.8309Z"
			fill="#2CC3F2"
		/>
		<path
			d="M13.3312 8.98679H6.60708C6.35484 8.98679 6.17467 8.74082 6.25301 8.49955L6.54599 7.60029C6.59613 7.44675 6.73869 7.34335 6.90006 7.34335H13.6242C13.8764 7.34335 14.0566 7.58932 13.9782 7.83059L13.6853 8.72985C13.6351 8.88339 13.4926 8.98679 13.3312 8.98679Z"
			fill="white"
		/>
		<path
			d="M12.4163 12.341H5.69218C5.43995 12.341 5.25982 12.095 5.33815 11.8538L5.6311 10.9545C5.68124 10.801 5.8238 10.6976 5.98517 10.6976H12.7093C12.9615 10.6976 13.1417 10.9435 13.0634 11.1848L12.7704 12.0841C12.7203 12.2376 12.5777 12.341 12.4163 12.341Z"
			fill="white"
		/>
		<path
			d="M9.89243 6.11664L7.56282 13.8466C7.51582 14.0032 7.37012 14.1113 7.20718 14.1113H6.34081C6.09171 14.1113 5.91311 13.8716 5.98518 13.6319L8.31483 5.902C8.36183 5.74534 8.50749 5.63724 8.67043 5.63724H9.5368C9.7859 5.63724 9.9645 5.87694 9.89243 6.11664Z"
			fill="white"
		/>
		<path
			d="M13.2388 6.11664L10.9092 13.8466C10.8622 14.0032 10.7165 14.1113 10.5535 14.1113H9.68715C9.43805 14.1113 9.25949 13.8716 9.33155 13.6319L11.6612 5.902C11.7082 5.74534 11.8539 5.63724 12.0168 5.63724H12.8832C13.1323 5.63724 13.3109 5.87694 13.2388 6.11664Z"
			fill="white"
		/>
		<path
			d="M12.0434 10.132L10.7462 8.98679H12.3881L12.0434 10.132Z"
			fill="#74DFFF"
		/>
		<path
			d="M8.68295 10.132L7.38574 8.98679H9.02761L8.68295 10.132Z"
			fill="#74DFFF"
		/>
		<path
			d="M11.0235 13.4862L9.72632 12.341H11.3682L11.0235 13.4862Z"
			fill="#74DFFF"
		/>
		<path
			d="M7.68502 13.4862L6.38782 12.341H8.02968L7.68502 13.4862Z"
			fill="#74DFFF"
		/>
	</svg>
</template>
