import { onBeforeUnmount, reactive, unref, watch, isRef } from 'vue';
import type { MaybeRef } from 'vue';
import { cloneDeep } from 'lodash-es';

interface UsePageGroupTitleState {
	title: string;
}

const DEFAULT_STATE: UsePageGroupTitleState = {
	title: '',
} as const;

const getDefaultState = () => (cloneDeep(DEFAULT_STATE));

const state = reactive<UsePageGroupTitleState>(getDefaultState());

function resetState() {
	Object.assign(state, getDefaultState());
}

export function usePageGroupTitle(title?: MaybeRef<string | undefined>) {
	const setTitle = (val: string) => state.title = val;

	if (isRef(title)) {
		watch(title, (val) => {
			const titleVal = unref(val);
			if (titleVal) setTitle(titleVal);
		}, { immediate: true });
	} else {
		if (title) setTitle(title);
	}

	onBeforeUnmount(() => resetState());

	return {
		state,
		setTitle,
	};
}
