<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="currentColor"
		viewBox="0 0 1000 1000"
	><g><path
		fill="#fff"
		d="M0 0h1000v1000H0z"
	/><path
		fill="#2CC3F2"
		d="M323.571 567.123c-7.201-7.173-17.936-9.899-27.243-5.815C132.491 633.213 19 789.179 19 970c0 10.08.353 20.084 1.047 30h959.906A429.65 429.65 0 0 0 981 970c0-180.821-113.491-336.787-277.328-408.692-9.307-4.084-20.042-1.358-27.243 5.815-45.213 45.037-107.57 72.876-176.429 72.876-68.859 0-131.216-27.839-176.429-72.876Z"
	/><path
		fill="#2CC3F2"
		d="M700 372.5C700 492.622 610.457 590 500 590s-200-97.378-200-217.5S389.543 155 500 155s200 97.378 200 217.5Z"
	/><g><path
		fill="#fff"
		d="M607.674 876.64v-98.144a24.292 24.292 0 0 0-12.149-21.038l-85.04-49.072a24.337 24.337 0 0 0-24.297 0l-85.039 49.072A24.267 24.267 0 0 0 389 778.496v98.144a24.29 24.29 0 0 0 12.149 21.039l85.039 49.072a24.337 24.337 0 0 0 24.297 0l85.04-49.072a24.27 24.27 0 0 0 12.149-21.039Z"
	/><path
		fill="#2CC3F2"
		d="M541.994 816.573h-83.426c-3.13 0-5.365-3.05-4.393-6.042l3.635-11.151a4.615 4.615 0 0 1 4.393-3.186h83.426c3.13 0 5.365 3.05 4.393 6.042l-3.635 11.151a4.615 4.615 0 0 1-4.393 3.186ZM530.643 858.165h-83.427c-3.13 0-5.365-3.05-4.392-6.041l3.634-11.151a4.615 4.615 0 0 1 4.393-3.186h83.426c3.13 0 5.365 3.05 4.393 6.042l-3.634 11.15a4.615 4.615 0 0 1-4.393 3.186Z"
	/><path
		fill="#2CC3F2"
		d="m499.329 780.983-28.904 95.851a4.625 4.625 0 0 1-4.412 3.283h-10.749c-3.091 0-5.307-2.972-4.413-5.944l28.904-95.852a4.626 4.626 0 0 1 4.412-3.283h10.749c3.091 0 5.307 2.973 4.413 5.945ZM540.847 780.983l-28.904 95.851a4.624 4.624 0 0 1-4.412 3.283h-10.749c-3.091 0-5.306-2.972-4.412-5.944l28.903-95.852a4.625 4.625 0 0 1 4.413-3.283h10.749c3.091 0 5.306 2.973 4.412 5.945Z"
	/><path
		fill="#2CC3F2"
		d="m526.016 830.774-16.094-14.201h20.371l-4.277 14.201ZM484.322 830.774l-16.094-14.201h20.37l-4.276 14.201ZM513.362 872.366l-16.094-14.201h20.371l-4.277 14.201ZM471.941 872.366l-16.094-14.201h20.37l-4.276 14.201Z"
	/></g></g><defs><clipPath id="a"><path
		fill="#fff"
		d="M0 0h1000v1000H0z"
	/></clipPath><clipPath id="b"><path
		fill="#fff"
		d="M389 702h221v248H389z"
	/></clipPath></defs></svg>
</template>
