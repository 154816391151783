import type { GetGroupsResponse } from '@/types/teams';
import { getList, isApiError } from './utilities/provider';
import type { AxiosRequestConfig } from 'axios';

const RESOURCE = 'teams';

export function useTeamsService(config?: Omit<AxiosRequestConfig, 'key'>) {
	const getGroups = async (teamId: string) => {
		const response = await getList<GetGroupsResponse>(`${RESOURCE}/${teamId}/groups`, {
			key: 'getGroups',
			...config,
		});
		if (isApiError(response)) throw response;
		return response.data.data;
	};

	return {
		getGroups,
	};
}
