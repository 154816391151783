<template>
	<svg
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M18.625 13.8309V5.91612C18.625 5.21582 18.2521 4.57036 17.6458 4.21943L10.7917 0.262025C10.1854 -0.0873417 9.43963 -0.0873417 8.83333 0.262025L1.97917 4.21943C1.37287 4.56879 1 5.21582 1 5.91612V13.8309C1 14.5312 1.37287 15.1767 1.97917 15.5276L8.83333 19.485C9.43963 19.8344 10.1854 19.8344 10.7917 19.485L17.6458 15.5276C18.2521 15.1783 18.625 14.5312 18.625 13.8309Z"
			fill="#009657"
		/>
		<path
			d="M13.6727 7.74915V12.8189C13.6727 13.0601 13.4769 13.2544 13.2372 13.2544H12.0308C11.7896 13.2544 11.5969 13.0586 11.5969 12.8189V11.4496C11.9823 11.3008 12.2564 10.9263 12.2564 10.4892C12.2564 10.2339 12.164 10.002 12.0105 9.82341L13.6743 7.74915H13.6727Z"
			fill="white"
		/>
		<path
			d="M13.6727 5.31457V5.7783C13.2858 5.92714 13.0116 6.30156 13.0116 6.73866C13.0116 6.93449 13.0664 7.11466 13.162 7.27133L11.5953 9.22497V5.31457C11.5953 5.0733 11.7896 4.87903 12.0293 4.87903H13.2356C13.4769 4.87903 13.6712 5.07487 13.6712 5.31457H13.6727Z"
			fill="#5DD1A4"
		/>
		<path
			d="M8.0312 6.67125V8.30841C7.6505 8.45881 7.38103 8.83168 7.38103 9.26565C7.38103 9.34555 7.39044 9.42389 7.40924 9.49909L6.15903 10.4172C6.0948 10.3749 6.02586 10.3388 5.9538 10.3122V6.67125C5.9538 6.42998 6.14963 6.23572 6.38933 6.23572H7.59566C7.83693 6.23572 8.0312 6.43155 8.0312 6.67125Z"
			fill="#5DD1A4"
		/>
		<path
			d="M8.0312 10.2213V12.8189C8.0312 13.0601 7.83536 13.2544 7.59566 13.2544H6.38933C6.14807 13.2544 5.9538 13.0586 5.9538 12.8189V12.2314C6.3392 12.0841 6.61493 11.7097 6.61493 11.2726C6.61493 11.1707 6.60083 11.0736 6.57106 10.9812L7.78993 10.0866C7.86357 10.143 7.94503 10.19 8.03277 10.2229L8.0312 10.2213Z"
			fill="white"
		/>
		<path
			d="M10.8575 7.70525V9.52884C10.6945 9.58994 10.5536 9.69335 10.4439 9.82182L9.43651 9.37062C9.43965 9.33615 9.44122 9.30011 9.44122 9.26565C9.44122 8.82855 9.16706 8.45255 8.78009 8.30528V7.70525C8.78009 7.46399 8.97592 7.26971 9.21562 7.26971H10.422C10.6617 7.26971 10.8559 7.46555 10.8559 7.70525H10.8575Z"
			fill="#5DD1A4"
		/>
		<path
			d="M10.8575 11.4496V12.8189C10.8575 13.0601 10.6616 13.2544 10.4235 13.2544H9.21715C8.97589 13.2544 8.78162 13.0586 8.78162 12.8189V10.226C8.91478 10.1775 9.03385 10.0991 9.13412 10.0004L10.1995 10.4783V10.4892C10.1995 10.9263 10.4736 11.3023 10.859 11.4496H10.8575Z"
			fill="white"
		/>
		<path
			d="M13.2842 14.8712H6.34075C6.09948 14.8712 5.90521 14.6754 5.90521 14.4357C5.90521 14.196 6.10105 14.0001 6.34075 14.0001H13.2842C13.5255 14.0001 13.7197 14.196 13.7197 14.4357C13.7197 14.6754 13.5239 14.8712 13.2842 14.8712Z"
			fill="white"
		/>
	</svg>
</template>
