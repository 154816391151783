<template>
	<SchoolTheme />
	<div
		class="layout-exchange-school-site h-100"
		:class="backgroundClass"
	>
		<NavigationMenu
			v-if="showNavBar"
			:transparent-background="isTransparentBackground"
			:flat="isTop"
		/>
		<VMain>
			<slot />
		</VMain>
	</div>
	<RegisterNowDialog
		v-if="isRegisterNowDialogRendered"
		v-model="registerNowDialogState.isOpen"
		:athlete="registerNowDialogState.athlete"
	/>
</template>

<script setup lang="ts">
import { computed, defineAsyncComponent } from 'vue';
import { useRouter } from 'vue-router';
import { useWindowScroll } from '@vueuse/core';
import { useExchangeSchoolSiteRegisterNowDialog } from '@/composables';
import SchoolTheme from '@/components/exchange-school-site/SchoolTheme.vue';
import NavigationMenu from '@/components/exchange-school-site/NavigationMenu.vue';

const RegisterNowDialog = defineAsyncComponent(() => import('@/components/exchange-school-site/RegisterNowDialog.vue'));

const router = useRouter();

const showNavBar = computed(() => router.currentRoute.value.name !== 'ExchangeSchoolSiteRegistration');

const { y: scrollY } = useWindowScroll();

const isTop = computed(() => {
	// Vuetify overlays that disable scrolling track the scroll position in a css variable
	const vBodyScroll = parseInt(document.documentElement.style.getPropertyValue('--v-body-scroll-y'), 10) * -1;

	return scrollY.value < 96 && (!Number.isInteger(vBodyScroll) || vBodyScroll < 96);
});

const isTransparentBackground = computed(() => isTop.value && router.currentRoute.value.name === 'ExchangeSchoolSiteIndex');

const backgroundClass = computed(() => router.currentRoute.value.name === 'ExchangeSchoolSiteIndex' ? undefined : 'bg-school-background');

// REGISTER NOW DIALOG
const {
	state: registerNowDialogState,
	isRendered: isRegisterNowDialogRendered,
} = useExchangeSchoolSiteRegisterNowDialog();

</script>
<style lang="scss" scoped>
.layout-exchange-school-site {
	&__app-bar {
		transition:background-color 0.3s, box-shadow 0.3s;
	}
}
</style>
