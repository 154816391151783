<template>
	<div class="login-content-keep-me-logged-in">
		<LoginPageHeader>
			<template #title>
				{{ t('pageLogin.keepMeLoggedIn.title') }}
			</template>
			<template #subtitle>
				{{ t('pageLogin.keepMeLoggedIn.subtitle') }}
			</template>
		</LoginPageHeader>
		<VRow dense>
			<VCol>
				<VBtnSecondary
					ref="buttonSubmitNo"
					:disabled="state.isSubmitted"
					:loading="state.choice === false"
					size="large"
					block
					data-testid="buttonSubmitNo"
					@click="submit(false)"
				>
					{{ t('no') }}
				</VBtnSecondary>
			</VCol>
			<VCol>
				<VBtnPrimary
					ref="buttonSubmitYes"
					:disabled="state.isSubmitted"
					:loading="state.choice === true"
					size="large"
					block
					data-testid="buttonSubmitYes"
					@click="submit(true)"
				>
					{{ t('yes') }}
				</VBtnPrimary>
			</VCol>
		</VRow>
	</div>
</template>

<script setup lang="ts">
import { reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import LoginPageHeader from '@/components/login/LoginPageHeader.vue';

defineProps({
	isLoading: { type: Boolean, default: false },
});

const state = reactive({
	choice: null as boolean | null,
	isSubmitted: false,
});

const emit = defineEmits<{
	(e: 'submit', payload: boolean): void;
}>();

const { t } = useI18n();

function submit(choice: boolean) {
	state.isSubmitted = true;
	state.choice = choice;
	emit('submit', choice);
}
</script>
