<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 40 40"
	>
		<g>
			<path
				d="M4.56017 22.3936V25.9548H5.83115V22.3936H6.87594V21.2914H3.51545V22.3936H4.56017Z"
				fill="currentColor"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M7.49704 25.9548V21.2914H8.82537C9.34562 21.2914 9.70821 21.3153 9.91314 21.3631C10.1181 21.4109 10.2949 21.4911 10.4435 21.6036C10.6113 21.731 10.7403 21.8935 10.8306 22.0909C10.9208 22.2884 10.966 22.5061 10.966 22.7438C10.966 23.1049 10.8773 23.3985 10.7 23.6246C10.5226 23.8508 10.2641 24.001 9.92431 24.0754L11.1531 25.8924L12.7401 21.2914H14.3328L15.9446 25.9548H14.68L14.4443 25.1202H12.6381L12.396 25.9548H9.75868L8.68841 24.1296V25.9548H7.49704ZM8.68849 23.4957H8.92412C9.19804 23.4957 9.39812 23.4489 9.52453 23.3554C9.65094 23.2621 9.71414 23.1156 9.71414 22.9159C9.71414 22.6823 9.65524 22.5161 9.53734 22.4174C9.41953 22.3186 9.22147 22.2693 8.94326 22.2693H8.68849V23.4957ZM14.1767 24.2124H12.9121L13.3804 22.6738C13.4079 22.5782 13.435 22.4784 13.4616 22.3744C13.4881 22.2703 13.5151 22.161 13.5428 22.0463C13.5832 22.2099 13.6161 22.3437 13.6415 22.4476C13.6671 22.5518 13.6872 22.6271 13.7021 22.6738L14.1767 24.2124Z"
				fill="currentColor"
			/>
			<path
				d="M16.3906 25.9548V21.2914H17.617L19.3785 23.69C19.4124 23.7389 19.468 23.837 19.5456 23.9847C19.6231 24.1322 19.7088 24.3079 19.8021 24.5118C19.7766 24.3186 19.7581 24.1466 19.7464 23.9958C19.7347 23.845 19.7288 23.7112 19.7288 23.5944V21.2914H20.9488V25.9547H19.7288L17.9673 23.5466C17.9311 23.4977 17.8744 23.3996 17.7969 23.252C17.7193 23.1042 17.6349 22.9308 17.5437 22.7311C17.5692 22.9265 17.5876 23.0995 17.5994 23.2503C17.611 23.4012 17.617 23.535 17.617 23.6518V25.9548H16.3906Z"
				fill="currentColor"
			/>
			<path
				d="M22.7151 24.9291C22.5314 24.823 22.3451 24.6626 22.1561 24.4481L21.5825 25.3942C21.8289 25.6299 22.0852 25.8031 22.3517 25.9135C22.6182 26.0238 22.9139 26.0791 23.2389 26.0791C23.7804 26.0791 24.2137 25.9289 24.5385 25.6284C24.8634 25.3277 25.0259 24.9302 25.0259 24.4354C25.0259 24.24 24.9971 24.0622 24.9398 23.9018C24.8824 23.7415 24.7985 23.6041 24.6881 23.4893C24.5246 23.3217 24.1837 23.1676 23.6657 23.0274L23.6083 23.0115C23.3683 22.9478 23.2064 22.8809 23.1225 22.8109C23.0386 22.7408 22.9968 22.6474 22.9968 22.5306C22.9968 22.4159 23.0445 22.322 23.1401 22.2487C23.2357 22.1755 23.3599 22.1388 23.5128 22.1388C23.6615 22.1388 23.8118 22.1702 23.9636 22.2329C24.1154 22.2954 24.2635 22.3884 24.408 22.5115L24.8062 21.5973C24.6002 21.4571 24.3819 21.3525 24.1516 21.2836C23.9212 21.2145 23.6776 21.18 23.4206 21.18C22.9449 21.18 22.5578 21.3206 22.2596 21.6021C21.9612 21.8835 21.812 22.2471 21.812 22.693C21.812 23.037 21.9066 23.3078 22.0956 23.5053C22.2845 23.7027 22.6074 23.8631 23.0639 23.9862C23.3974 24.0754 23.6112 24.1619 23.7058 24.2458C23.8002 24.3297 23.8475 24.4492 23.8475 24.6042C23.8475 24.7444 23.7927 24.8601 23.6834 24.9514C23.574 25.0427 23.4344 25.0884 23.2646 25.0884C23.0819 25.0884 22.8988 25.0352 22.7151 24.9291Z"
				fill="currentColor"
			/>
			<path
				d="M25.7396 25.9548V21.2914H28.5968V22.314H26.9787V23.123H28.5044V24.1233H26.9787V25.9548H25.7396Z"
				fill="currentColor"
			/>
			<path
				d="M29.2881 21.2914V25.9548H32.1453V24.9099H30.5271V24.1233H32.053V23.123H30.5271V22.314H32.1453V21.2914H29.2881Z"
				fill="currentColor"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M32.9863 25.9548V21.2914H34.3146C34.8348 21.2914 35.1974 21.3153 35.4024 21.3631C35.6073 21.4109 35.7842 21.4911 35.9327 21.6036C36.1005 21.731 36.2295 21.8935 36.3197 22.0909C36.4099 22.2884 36.4551 22.5061 36.4551 22.7438C36.4551 23.1049 36.3664 23.3985 36.1892 23.6246C36.0118 23.8508 35.7533 24.001 35.4135 24.0754L36.6845 25.9548H35.2479L34.1776 24.1296V25.9548H32.9863ZM34.1776 23.4957H34.4133C34.6873 23.4957 34.8874 23.4489 35.0138 23.3554C35.1401 23.2621 35.2034 23.1156 35.2034 22.9159C35.2034 22.6823 35.1445 22.5161 35.0266 22.4174C34.9087 22.3186 34.7107 22.2693 34.4325 22.2693H34.1776V23.4957Z"
				fill="currentColor"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M11.2744 14.2281V20.446H13.0286C13.7363 20.446 14.246 20.422 14.5575 20.3738C14.869 20.3256 15.1309 20.2449 15.3432 20.1316C15.6037 19.993 15.804 19.7976 15.9442 19.5455C16.0844 19.2936 16.1544 19.0019 16.1544 18.6707C16.1544 18.2092 16.0297 17.8404 15.7807 17.5643C15.5315 17.2882 15.186 17.1332 14.7444 17.0992C15.1378 16.9916 15.4246 16.8338 15.6044 16.6257C15.7842 16.4175 15.8741 16.1366 15.8741 15.7826C15.8741 15.5023 15.814 15.2552 15.6936 15.0415C15.5732 14.8276 15.3984 14.6571 15.1691 14.5297C14.968 14.4192 14.728 14.3414 14.4492 14.2961C14.1703 14.2508 13.6798 14.2281 12.9775 14.2281H11.2744ZM13.1857 16.7127H12.8459V15.4598H13.2111C13.633 15.4598 13.9169 15.5073 14.0627 15.602C14.2085 15.697 14.2814 15.8619 14.2814 16.0968C14.2814 16.3234 14.2034 16.4827 14.0478 16.5747C13.8921 16.6668 13.6046 16.7127 13.1857 16.7127ZM13.4404 19.176H12.8459L12.8458 17.8212H13.4659C13.8793 17.8212 14.1624 17.8729 14.3153 17.9762C14.4683 18.0795 14.5447 18.2615 14.5447 18.522C14.5447 18.7569 14.4613 18.9247 14.2941 19.0252C14.127 19.1258 13.8425 19.176 13.4404 19.176Z"
				fill="currentColor"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M16.4432 20.4459L18.5881 14.2281H20.7116L22.8607 20.4459H21.1746L20.8603 19.3332H18.4523L18.1294 20.4459H16.4432ZM18.8173 18.1227H20.5035L19.8706 16.0714C19.8507 16.0092 19.8239 15.9086 19.7899 15.7699C19.7559 15.6312 19.712 15.4527 19.6582 15.2347C19.6214 15.3876 19.5853 15.5335 19.5499 15.6721C19.5144 15.8109 19.4783 15.9439 19.4416 16.0714L18.8173 18.1227Z"
				fill="currentColor"
			/>
			<path
				d="M23.4553 14.2281V20.4459H25.0905V17.3752C25.0905 17.2195 25.0826 17.0411 25.0672 16.8401C25.0516 16.6391 25.0268 16.4083 24.9928 16.1478C25.1144 16.4139 25.2271 16.6454 25.3304 16.8422C25.4338 17.039 25.5094 17.17 25.5576 17.2351L27.9063 20.4459H29.5329V14.2281H27.9063V17.2988C27.9063 17.4546 27.9141 17.6329 27.9297 17.8339C27.9451 18.035 27.97 18.2643 28.004 18.522C27.8793 18.2502 27.7654 18.0159 27.6621 17.8191C27.5586 17.6223 27.4843 17.4913 27.4391 17.4262L25.0904 14.2281H23.4553Z"
				fill="currentColor"
			/>
			<path
				d="M30.8665 20.4459V14.2281H32.5441V17.0524L34.5106 14.2281H36.4855L34.1877 17.2605L36.6851 20.4459H34.57L32.5441 17.6555V20.4459H30.8665Z"
				fill="currentColor"
			/>
			<path
				d="M3.02877 14.2122H9.91485V15.4445H3.02877V14.2122Z"
				fill="currentColor"
			/>
			<path
				d="M9.91487 16.6043H3.02879V17.8365H9.91487V16.6043Z"
				fill="currentColor"
			/>
			<path
				d="M3.02879 19.2136H9.91487V20.4459H3.02879V19.2136Z"
				fill="currentColor"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M2.33658 7.59375H37.6292C38.9291 7.59375 39.9829 8.64753 39.9829 9.94742V30.0965C39.9829 31.3964 38.9292 32.4502 37.6292 32.4502H2.33658C1.03669 32.4502 -0.0170898 31.3964 -0.0170898 30.0965V9.94742C-0.0170898 8.64753 1.03669 7.59375 2.33658 7.59375ZM39.5923 9.94742C39.5923 8.86496 38.7116 7.98439 37.6292 7.98439H2.33658C1.25412 7.98439 0.373547 8.86504 0.373547 9.94742V30.0965C0.373547 31.1789 1.2542 32.0595 2.33658 32.0595H37.6292C38.7117 32.0595 39.5923 31.1789 39.5923 30.0965V9.94742Z"
				fill="currentColor"
			/>
		</g>
	</svg>
</template>
