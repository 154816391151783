<template>
	<div
		ref="baseField"
		v-remove-default-label
	>
		<div class="d-flex align-center justify-space-between">
			<VTooltip
				v-if="tooltip"
				location="top"
			>
				<template #activator="{ props: toolTipProps }">
					<label
						ref="baseFieldLabel"
						:for="id"
						v-bind="toolTipProps"
						:class="[
							'text-subtitle-1 font-weight-semibold d-block text-medium-emphasis mb-1',
							hideLabel && 'd-sr-only',
						]"
						class="d-flex align-center"
					>
						<span>{{ label }}</span>
						<VIcon
							size="16"
							class="ml-1"
						>
							info
						</VIcon>
					</label>
				</template>
				<span ref="baseFieldTooltip">{{ tooltip }}</span>
			</VTooltip>

			<label
				v-else
				ref="baseFieldLabel"
				:for="id"
				:class="[
					'text-subtitle-1 font-weight-semibold d-block text-medium-emphasis mb-1',
					hideLabel && 'd-sr-only',
				]"
			>
				{{ label }}
			</label>
			<span
				v-if="hint"
				ref="baseFieldHint"
				class="text-caption text-medium-emphasis"
			>{{ hint }}</span>
		</div>
		<slot :id="id" />
	</div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useMutationObserver } from '@vueuse/core';

withDefaults(defineProps<{
	label: string;
	hideLabel?: boolean;
	hint?: string;
	tooltip?: string;
}>(), {
	hideLabel: false,
	hint: undefined,
	tooltip: undefined,
});

const baseField = ref<HTMLDivElement | null>(null);

const id = ref(`field-${crypto.randomUUID()}`);

const removeDefaultLabel = () => {
	const label = baseField.value?.querySelector('label.v-field-label');
	if (!label) return;
	label.remove();
	stop();
};

const { stop } = useMutationObserver(baseField, removeDefaultLabel, { childList: true });

const vRemoveDefaultLabel = { mounted: removeDefaultLabel };
</script>
