import type { RouteRecordRaw } from 'vue-router';
import { useUserStore } from '@/stores/userStore';
import { useRouter } from 'vue-router';
import { isEmpty } from 'lodash-es';
import { ROLES, type Role } from '@teamworksdev/influencer-core';

const routes: RouteRecordRaw[] = [
	{
		path: '/choose-profile',
		children: [
			{
				path: '',
				name: 'ProfileChooserIndex',
				component: () => import('@/views/ProfileChooserIndex.vue'),
				meta: {
					layout: 'LayoutDefault',
					requiresAuth: true,
					title: 'pageProfileChooser.metaTitle',
				},
				beforeEnter: async () => {
					const router = useRouter();
					const userStore = useUserStore();
					const redirect = router.currentRoute.value.query.redirect as string;
					const adminRoles: Role[] = [ROLES.ADMINISTRATOR, ROLES.CLIENT_SUCCESS, ROLES.SUPER_ADMINISTRATOR];
					if (userStore.userProfiles?.some((profile) => adminRoles.includes(profile.role_id)) || !userStore.hasUserAcceptedLatestTermsOfUse || (userStore.hasMultipleProfiles && isEmpty(userStore.defaultProfile))) return true;
					userStore.setProfile(userStore.defaultProfile ? userStore.defaultProfile.uuid : userStore.userProfiles![0].uuid);
					return redirect ? router.push({ path: redirect }) : router.push({ name: userStore.profileDefaultRoute });
				},
			},
		],
	},
];

export default routes;
