<template>
	<VList
		ref="helpMenu"
		class="px-5 mb-md-2"
		density="compact"
		nav
	>
		<VListItem
			class="text-decoration-none pl-2"
			prepend-icon="help"
			:title="t('navigationMenu.teamworksHelp')"
			data-testid="navigationHelpMenu"
			link
		>
			<VMenu
				v-model="isMenuActive"
				theme="INFLCRLight"
				activator="parent"
				location="top"
			>
				<VCard
					border
					class="pa-3"
					width="238"
				>
					<div class="text-medium-emphasis px-2 pb-2 text-caption">
						{{ t('navigationMenu.teamworksHelp') }}
					</div>
					<VList
						class="pa-0"
						density="compact"
						nav
					>
						<TheNavigationMenuItem
							v-for="(item, index) in appendedItems"
							:key="index"
							:nav-item="item"
							:is-expanded="isExpanded"
							:tooltip-visible="false"
						/>
					</VList>
				</VCard>
			</VMenu>
			<VTooltip
				v-if="!isExpanded"
				activator="parent"
				location="right"
			>
				{{ t('navigationMenu.teamworksHelp') }}
			</VTooltip>
		</VListItem>
	</VList>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { usePrimaryNavigation } from '@/composables';
import { useI18n } from 'vue-i18n';
import TheNavigationMenuItem from '@/components/TheNavigationMenuItem.vue';

defineProps<{
	isExpanded: boolean;
}>();
const { t } = useI18n();

const isMenuActive = ref(false);

const { appendedItems } = usePrimaryNavigation();
</script>
