import { i18n } from '@/plugins/i18n';
import { camelCase } from 'lodash-es';
import { AGREEMENT_STATUSES } from '@teamworksdev/influencer-core';

const agreementStatuses = Object.keys(AGREEMENT_STATUSES).map((key) => ({
	id: AGREEMENT_STATUSES[key as keyof typeof AGREEMENT_STATUSES],
	name: i18n.global.t(`agreementStatuses.${camelCase(key)}.title`),
	conversationText: i18n.global.t(`agreementStatuses.${camelCase(key)}.conversationText`),
	agentText: (agent: string, athlete: string) => {
		return i18n.global.t(`agreementStatuses.${camelCase(key)}.conversationText`, { agent, athlete });
	},
	tooltip: undefined,
}));

export {
	agreementStatuses,
};
