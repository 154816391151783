<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			fill="currentColor"
			d="m16.874 14.652-4.57-7.22a.922.922 0 0 0-.343-.316.973.973 0 0 0-.922 0 .922.922 0 0 0-.342.317l-4.571 7.22a.869.869 0 0 0-.003.893c.082.137.199.25.34.33.141.08.302.122.466.124h9.142a.971.971 0 0 0 .466-.124.918.918 0 0 0 .34-.33.867.867 0 0 0-.003-.894Z"
		/>
	</svg>
</template>
