<template>
	<svg
		viewBox="0 0 20 21"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M19.0383 14.1346V6.11837C19.0383 5.4091 18.6606 4.75537 18.0466 4.39994L11.1046 0.391846C10.4905 0.0380043 9.73524 0.0380043 9.12118 0.391846L2.17921 4.39994C1.56514 4.75378 1.1875 5.4091 1.1875 6.11837V14.1346C1.1875 14.8438 1.56514 15.4976 2.17921 15.853L9.12118 19.8611C9.73524 20.2149 10.4905 20.2149 11.1046 19.8611L18.0466 15.853C18.6606 15.4992 19.0383 14.8438 19.0383 14.1346Z"
			fill="#009657"
		/>
		<path
			d="M10.5064 11.4292C10.4604 11.4292 10.4143 11.4212 10.3699 11.4054C10.2446 11.3594 10.1494 11.2531 10.1192 11.1214L9.63843 9.03004L9.00215 10.2978C8.93551 10.4327 8.79746 10.5168 8.6483 10.5168H7.32179C7.10282 10.5168 6.92511 10.3391 6.92511 10.1201C6.92511 9.90116 7.10282 9.72344 7.32179 9.72344H8.40236L9.42263 7.68925C9.49721 7.54009 9.66064 7.45441 9.82408 7.47345C9.99069 7.49408 10.1256 7.61626 10.1636 7.7781L10.7539 10.347L11.4775 9.80278C11.5457 9.75042 11.6298 9.72344 11.7155 9.72344H13.1483C13.3673 9.72344 13.545 9.90116 13.545 10.1201C13.545 10.3391 13.3673 10.5168 13.1483 10.5168H11.8472L10.7412 11.3498C10.6714 11.4022 10.5873 11.4292 10.5032 11.4292H10.5064Z"
			fill="#5DD1A4"
		/>
		<path
			d="M16.4091 11.2911H15.7522V8.95387H16.4091C16.5011 8.95387 16.5757 9.02845 16.5757 9.12048V11.1245C16.5757 11.2166 16.5011 11.2911 16.4091 11.2911Z"
			fill="#5DD1A4"
		/>
		<path
			d="M15.522 7.9447H14.5811C14.4532 7.9447 14.3495 8.04842 14.3495 8.17637V12.0686C14.3495 12.1966 14.4532 12.3003 14.5811 12.3003H15.522C15.65 12.3003 15.7537 12.1966 15.7537 12.0686V8.17637C15.7537 8.04842 15.65 7.9447 15.522 7.9447Z"
			fill="white"
		/>
		<path
			d="M13.7195 7.18311H12.8547C12.7057 7.18311 12.585 7.30388 12.585 7.45285V12.7906C12.585 12.9396 12.7057 13.0604 12.8547 13.0604H13.7195C13.8685 13.0604 13.9892 12.9396 13.9892 12.7906V7.45285C13.9892 7.30388 13.8685 7.18311 13.7195 7.18311Z"
			fill="white"
		/>
		<path
			d="M3.82619 8.95386H4.48309V11.2911H3.82619C3.73416 11.2911 3.65958 11.2166 3.65958 11.1245V9.12047C3.65958 9.02844 3.73416 8.95386 3.82619 8.95386Z"
			fill="#5DD1A4"
		/>
		<path
			d="M4.71474 12.2987H5.65567C5.78362 12.2987 5.88733 12.195 5.88733 12.067L5.88733 8.17478C5.88733 8.04684 5.78362 7.94312 5.65567 7.94312H4.71474C4.58679 7.94312 4.48307 8.04684 4.48307 8.17478V12.067C4.48307 12.195 4.58679 12.2987 4.71474 12.2987Z"
			fill="white"
		/>
		<path
			d="M6.51576 13.062H7.38053C7.52951 13.062 7.65027 12.9412 7.65027 12.7922L7.65027 7.45442C7.65027 7.30545 7.52951 7.18469 7.38053 7.18469H6.51576C6.36678 7.18469 6.24601 7.30545 6.24601 7.45442L6.24601 12.7922C6.24601 12.9412 6.36678 13.062 6.51576 13.062Z"
			fill="white"
		/>
	</svg>
</template>
