<template>
	<svg
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M18.8508 14.0081V5.99191C18.8508 5.28264 18.4731 4.6289 17.8591 4.27347L10.9171 0.265381C10.303 -0.0884605 9.54774 -0.0884605 8.93367 0.265381L1.99171 4.27347C1.37764 4.62732 1 5.28264 1 5.99191V14.0081C1 14.7174 1.37764 15.3711 1.99171 15.7265L8.93367 19.7346C9.54774 20.0885 10.303 20.0885 10.9171 19.7346L17.8591 15.7265C18.4731 15.3727 18.8508 14.7174 18.8508 14.0081Z"
			fill="#7B62D5"
		/>
		<path
			d="M9.92534 4.40039C6.89785 4.40039 4.43524 6.863 4.43524 9.89049C4.43524 10.6966 4.60978 11.4614 4.92237 12.1516L5.30001 10.7045C5.25241 10.4411 5.22861 10.1682 5.22861 9.89049C5.22861 7.30094 7.33579 5.19376 9.92534 5.19376C12.5149 5.19376 14.6221 7.30094 14.6221 9.89049C14.6221 10.1666 14.5983 10.4363 14.5507 10.6997L14.9299 12.15C15.2409 11.4598 15.4154 10.695 15.4154 9.89049C15.4154 6.863 12.9528 4.40039 9.92534 4.40039Z"
			fill="#9E9EE8"
		/>
		<path
			d="M14.4682 12.8974L14.1937 11.8469L13.8335 10.4633C13.8161 10.3951 13.7844 10.3411 13.7447 10.3078C13.4432 10.5442 13.064 10.6839 12.6514 10.6839C12.4515 10.6839 12.2611 10.6505 12.0818 10.5902L12.7625 13.1068C12.7815 13.1766 12.7942 13.248 12.7974 13.321H14.2842C14.4111 13.321 14.5016 13.1401 14.4793 12.9545C14.4762 12.9354 14.473 12.9164 14.4682 12.8974Z"
			fill="white"
		/>
		<path
			d="M11.7707 9.63504C11.8834 9.7715 12.0278 9.88099 12.1928 9.95239C12.3324 10.0159 12.4879 10.0492 12.6514 10.0492C12.8148 10.0492 12.9703 10.0143 13.1115 9.95239C13.2766 9.88099 13.4209 9.7715 13.5336 9.63504C13.6986 9.43511 13.7986 9.17965 13.7986 8.90197C13.7986 8.26886 13.2861 7.75476 12.6514 7.75476C12.3689 7.75476 12.1103 7.85631 11.9104 8.02768C11.9453 8.17683 11.9643 8.33233 11.9643 8.49259C11.9643 8.86706 11.8628 9.21773 11.6866 9.51921C11.712 9.56047 11.739 9.59855 11.7707 9.63504Z"
			fill="white"
		/>
		<path
			d="M7.05499 13.321C7.05816 13.2496 7.07086 13.1782 7.0899 13.1068L7.76902 10.5902C7.58972 10.6505 7.39931 10.6839 7.19938 10.6839C6.78842 10.6839 6.40919 10.5442 6.10771 10.3094C6.06804 10.3411 6.03631 10.3951 6.01885 10.4633L5.65708 11.8485L5.38416 12.8974C5.3794 12.9164 5.37623 12.9354 5.37305 12.9561C5.35084 13.1417 5.44128 13.321 5.56822 13.321H7.05499Z"
			fill="white"
		/>
		<path
			d="M7.19939 7.75476C6.56629 7.75476 6.05219 8.26886 6.05219 8.90197C6.05219 9.17965 6.15215 9.43511 6.31717 9.63504C6.43141 9.7715 6.57581 9.88099 6.74083 9.95239C6.88046 10.0159 7.03596 10.0492 7.19939 10.0492C7.36283 10.0492 7.51833 10.0159 7.65796 9.95239C7.82298 9.88099 7.96737 9.7715 8.08162 9.63504C8.11177 9.59855 8.14033 9.56047 8.16413 9.51921C7.988 9.21773 7.88645 8.86706 7.88645 8.49259C7.88645 8.33233 7.90549 8.17683 7.9404 8.02768C7.74047 7.85631 7.48183 7.75476 7.19939 7.75476Z"
			fill="white"
		/>
		<path
			d="M8.42119 10.6141L7.7024 13.2735C7.69764 13.2893 7.69447 13.3052 7.69288 13.3211C7.67225 13.4369 7.69288 13.5527 7.73889 13.6384C7.78174 13.7209 7.84997 13.7749 7.92772 13.7749H11.9231C12.0009 13.7749 12.0691 13.7209 12.1119 13.6384C12.1595 13.5527 12.1786 13.4369 12.158 13.3211C12.1564 13.3052 12.1532 13.2893 12.1484 13.2735L11.4312 10.6141L11.3725 10.3951C11.3487 10.3047 11.3011 10.2333 11.2424 10.1968C11.2123 10.1777 11.1805 10.1666 11.1472 10.1666H11.0869C11.0139 10.219 10.9362 10.265 10.8552 10.3063C10.576 10.4507 10.2602 10.5316 9.92541 10.5316C9.59061 10.5316 9.27644 10.4507 8.99717 10.3063C8.91625 10.265 8.8385 10.219 8.76392 10.1666H8.70522C8.67189 10.1666 8.64016 10.1761 8.61001 10.1968C8.5513 10.2317 8.5037 10.3031 8.4799 10.3951L8.42119 10.6141Z"
			fill="white"
		/>
		<path
			d="M12.2737 13.9557H12.2642L12.2515 13.9684L12.2499 13.97C12.188 14.0065 12.1246 14.0398 12.0611 14.0716C11.7898 14.2112 11.5042 14.3255 11.2043 14.4096C10.7981 14.5254 10.3681 14.5873 9.92536 14.5873C9.48267 14.5873 9.05425 14.5254 8.64804 14.4096C8.34656 14.3255 8.05936 14.2112 7.78645 14.07C7.72456 14.0398 7.66268 14.0065 7.60238 13.97L7.5881 13.9557H6.23621C7.21205 14.8396 8.50682 15.3806 9.92536 15.3806C11.3439 15.3806 12.6387 14.8396 13.6145 13.9557H12.2737Z"
			fill="#9E9EE8"
		/>
		<path
			d="M8.89559 9.44462C8.92256 9.47635 8.95271 9.50492 8.98286 9.53189C9.14629 9.68104 9.34463 9.79053 9.5636 9.84924C9.67943 9.88097 9.80003 9.89684 9.92538 9.89684C10.0507 9.89684 10.1713 9.88097 10.2872 9.84924C10.5061 9.79053 10.7045 9.68104 10.8679 9.53189C10.898 9.50492 10.9282 9.47635 10.9568 9.44462C11.0551 9.33831 11.1376 9.21613 11.1995 9.08126V9.07808C11.2836 8.89878 11.3296 8.70361 11.3296 8.49258C11.3296 8.42911 11.3249 8.36564 11.3169 8.30376C11.2979 8.1546 11.2535 8.01338 11.1916 7.88327C10.9647 7.4136 10.4823 7.08832 9.92538 7.08832C9.36843 7.08832 8.88765 7.4136 8.65916 7.88327C8.59728 8.0118 8.55444 8.15302 8.53381 8.30058C8.52588 8.36405 8.52112 8.42752 8.52112 8.49258C8.52112 8.70361 8.56872 8.90513 8.65123 9.08443C8.7147 9.21772 8.79562 9.33831 8.89559 9.44462Z"
			fill="white"
		/>
	</svg>
</template>
