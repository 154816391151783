<template>
	<BaseDialog
		ref="termsOfUseDialog"
		:model-value="!userStore.hasUserAcceptedLatestTermsOfUse"
		content-class="terms-of-use-dialog"
		:close-button-visible="false"
		persistent
		:title="t('termsOfUseDialog.title')"
		data-testid="elementTermsOfUseDialog"
	>
		<template #default>
			<div
				class="terms-of-use-dialog__text"
				data-testid="elementTermsOfUseText"
				v-html="formattedTermsOfUse"
			/>
		</template>
		<template #actions>
			<VBtnSecondary
				ref="buttonDeclineTermsOfUse"
				data-testid="buttonDeclineTermsOfUse"
				:loading="declineTermsOfUse.isLoading"
				@click="declineTermsOfUse.execute()"
			>
				{{ t('decline') }}
			</VBtnSecondary>
			<VBtnPrimary
				ref="buttonAcceptTermsOfUse"
				:loading="acceptTermsOfUse.isLoading"
				:disabled="acceptDisabled"
				data-testid="buttonAcceptTermsOfUse"
				@click="acceptTermsOfUse.execute()"
			>
				{{ t('accept') }}
			</VBtnPrimary>
		</template>
	</BaseDialog>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { computed, ref, reactive, watch } from 'vue';
import type { TermsOfUse } from '@/types/termsOfUse';
import { useBasicAuth } from '@/composables';
import { useTermsOfUseService } from '@/api';
import { marked } from 'marked';
import { useUserStore } from '@/stores/userStore';
import { useFeatureFlagStore } from '@/stores/featureFlagsStore';
import { useAsyncState } from '@vueuse/core';
import BaseDialog from '@/components/BaseDialog.vue';

const { t } = useI18n();
const userStore = useUserStore();
const { logOut } = useBasicAuth();
const { fetchFeatureFlags } = useFeatureFlagStore();

const termsOfUse = ref<TermsOfUse | undefined>(undefined);
const termsOfUseDialog = ref();

const getTermsOfUse = reactive(useAsyncState(
	async () => {
		termsOfUse.value = await useTermsOfUseService().getTermsOfUse();
	},
	undefined,
	{
		immediate: false,
	},
));

const formattedTermsOfUse = computed(() => {
	return marked(termsOfUse.value?.terms ?? '');
});

function onSuccessfulAction() {
	termsOfUse.value = undefined;
	try {
		fetchFeatureFlags();
	} catch (error) {
		console.error('Error fetching feature flags:', error);
	}
}

const acceptDisabled = computed<boolean>(() => {
	return !termsOfUse.value || getTermsOfUse.isLoading || !termsOfUseDialog.value?.scrolledToBottom;
});

const acceptTermsOfUse = reactive(useAsyncState(
	async () => {
		try {
			await userStore.acceptTermsOfUse(termsOfUse.value!.version);
			onSuccessfulAction();
		} catch (error) {
			return error;
		}
	}, undefined, { immediate: false },
));

const declineTermsOfUse = reactive(useAsyncState(
	async () => {
		await logOut();
		onSuccessfulAction();
	}, undefined, { immediate: false },
));

watch(() => userStore.hasUserAcceptedLatestTermsOfUse, (newVal) => {
	if (!newVal) getTermsOfUse.execute();
}, { immediate: true });
</script>

<style lang="scss">
.terms-of-use-dialog__text {
    * {
        padding: revert;
        margin: revert;
    }
}
</style>
