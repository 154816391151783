import { defineStore } from 'pinia';
import { reactive, toRefs } from 'vue';
import type { ExchangeSchoolSite } from '@/types/exchangeSchoolSite';
import { cloneDeep } from 'lodash-es';

interface UseExchangeSchoolSiteStoreState {
	schoolSite: ExchangeSchoolSite | undefined;
}

export const useExchangeSchoolSiteStore = defineStore('exchange-school-site', () => {
	const DEFAULT_STATE = {
		schoolSite: undefined,
	};

	const state = reactive<UseExchangeSchoolSiteStoreState>(cloneDeep(DEFAULT_STATE));

	function setSchoolSite(schoolSite: ExchangeSchoolSite) {
		state.schoolSite = schoolSite;
	}

	const $reset = () => {
		Object.assign(state, cloneDeep(DEFAULT_STATE));
	};

	return {
		...toRefs(state),
		setSchoolSite,
		$reset,
	};
});
