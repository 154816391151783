<template>
	<svg
		width="36"
		height="36"
		viewBox="0 0 57 64"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M56.4 45.0684V19.741C56.4 17.5001 55.2068 15.4346 53.2667 14.3116L31.3333 1.64793C29.3932 0.529955 27.0068 0.529955 25.0667 1.64793L3.13333 14.3116C1.19317 15.4296 0 17.5001 0 19.741V45.0684C0 47.3094 1.19317 49.3749 3.13333 50.4979L25.0667 63.1615C27.0068 64.2795 29.3932 64.2795 31.3333 63.1615L53.2667 50.4979C55.2068 49.3799 56.4 47.3094 56.4 45.0684Z"
			fill="#2CC3F2"
		/>
		<path
			d="M39.4599 29.5671H17.9427C17.1356 29.5671 16.5591 28.7801 16.8097 28.008L17.7473 25.1303C17.9077 24.639 18.3639 24.3082 18.8803 24.3082H40.3975C41.2046 24.3082 41.7812 25.0953 41.5305 25.8673L40.5929 28.745C40.4325 29.2363 39.9763 29.5671 39.4599 29.5671Z"
			fill="white"
		/>
		<path
			d="M36.5322 40.3007H15.0149C14.2077 40.3007 13.6313 39.5136 13.882 38.7416L14.8194 35.8639C14.9799 35.3726 15.4361 35.0417 15.9524 35.0417H37.4696C38.2768 35.0417 38.8533 35.8288 38.6027 36.6009L37.6652 39.4785C37.5048 39.9698 37.0486 40.3007 36.5322 40.3007Z"
			fill="white"
		/>
		<path
			d="M28.4557 20.3827L21.0009 45.1185C20.8505 45.6198 20.3843 45.9658 19.8629 45.9658H17.0905C16.2934 45.9658 15.7219 45.1987 15.9525 44.4317L23.4074 19.6959C23.5578 19.1946 24.0239 18.8486 24.5453 18.8486H27.3177C28.1148 18.8486 28.6863 19.6157 28.4557 20.3827Z"
			fill="white"
		/>
		<path
			d="M39.1642 20.3827L31.7093 45.1185C31.5589 45.6198 31.0927 45.9658 30.5713 45.9658H27.7989C27.0018 45.9658 26.4304 45.1987 26.661 44.4317L34.1157 19.6959C34.2661 19.1946 34.7324 18.8486 35.2538 18.8486H38.0262C38.8233 18.8486 39.3948 19.6157 39.1642 20.3827Z"
			fill="white"
		/>
		<path
			d="M35.339 33.2319L31.188 29.5671H36.4421L35.339 33.2319Z"
			fill="#74DFFF"
		/>
		<path
			d="M24.5854 33.2319L20.4343 29.5671H25.6883L24.5854 33.2319Z"
			fill="#74DFFF"
		/>
		<path
			d="M32.0754 43.9655L27.9243 40.3007H33.1784L32.0754 43.9655Z"
			fill="#74DFFF"
		/>
		<path
			d="M21.392 43.9655L17.241 40.3007H22.4949L21.392 43.9655Z"
			fill="#74DFFF"
		/>
	</svg>
</template>
