import type { ListResponseCursor, SortDirection } from '@/api/utilities/provider';
import type { AcademicYear, BulletinCategory, BulletinType, Sport } from '@teamworksdev/influencer-core';
import type { ApiMediaAttachment, Media } from './media';
interface BulletinReach {
	count: number;
}

interface BulletinBase {
	uuid: string;
	name: string;
	logo: string | null;
	limit_reached: boolean;
	applicant_limit: number | null;
	is_archived: boolean;
	is_featured: boolean;
	is_global?: boolean;
	applied_at?: string | null;
	created_at: string;
	updated_at: string;
	category: BulletinCategory;
	exchange_brand?: {
		uuid: string | null;
		name: string | null;
		logo: string | null;
	};
}

interface ExchangeBulletin extends BulletinBase {
	post_type: BulletinType;
	applications: number;
	views: number;
}

interface SingleExchangeBulletin extends BulletinBase {
	description: string;
	type: BulletinType;
	url: string;
	visibility: {
		athletes: Uuid[];
		sport_types: Sport[];
		schools: { uuid: string; name: string }[];
		academic_year_ids: AcademicYear[];
	};
	questions: { uuid: string; question: string }[];
	attachments: Media[];
}

type BulletinApplicantsSortValue = 'last_name' | 'created_at';

interface BulletinApplicant {
	uuid: string;
	user: {
		uuid: string;
		first_name: string;
		last_name: string;
		profile_image: string;
	};
	team?: {
		name: string;
	};
	is_approval_required_for_payment: boolean;
	created_at: string;
	updated_at: string;
}

interface BulletinApplicantQuestionAnswer {
	question: string;
	answer: string;
}

interface BulletinApplicantExpanded extends BulletinApplicant {
	question_answers: BulletinApplicantQuestionAnswer[];
	exchange_bulletin: Omit<SingleExchangeBulletin, 'visibility' | 'questions'>;
}

const isBulletinApplicantExpanded = (data: BulletinApplicant | BulletinApplicantExpanded): data is BulletinApplicantExpanded => {
	return 'question_answers' in data;
};

type SingleBulletinApplicant = {
	applicationUuid: string;
	fullName: string | null;
	profileImage: string | null;
	applicantUuid: string | null;
	teamName: string;
	isApprovalRequiredForPayment: boolean;
	createdAt: string;
	updatedAt: string;
	questionAnswers: BulletinApplicantQuestionAnswer[];
};

type BulletinsSortValue = 'available_at' | 'is_featured';

interface GetBulletinsRequestParams {
	cursor: string;
	sort?: [BulletinsSortValue, SortDirection][];
	is_featured?: boolean;
}

type GetBulletinsResponse = ListResponseCursor<ExchangeBulletin>;

type GetSingleBulletinResponse = {
	data: SingleExchangeBulletin;
};

interface GetBulletinReachParams {
	sport_type_ids?: Sport[];
	school_uuids?: string[];
	academic_year_ids?: AcademicYear[];
}

interface ApiCreateBulletinRequestModel {
	applicant_limit?: number;
	name: string;
	description?: string;
	type: BulletinType;
	category: BulletinCategory;
	url: string;
	is_featured: boolean;
	visibility: {
		school_uuids: string[];
		sport_type_ids: Sport[];
		academic_year_ids: AcademicYear[];
	};
	questions: string[];
	logo?: ApiMediaAttachment;
	attachments?: ApiMediaAttachment[];
}

interface ApiCreateBulletinApplicationRequestModel {
	answers: {
		question: string;
		question_uuid: string;
		answer: string;
	}[];
}

interface CreateBulletinApplicationResponse {
	data: Omit<BulletinApplicantExpanded, 'exchange_bulletin'>;
}
interface GetSingleBulletinApplicantResponse {
	data: BulletinApplicantExpanded;
}

interface GetBulletinApplicantsRequestParams {
	sort?: [BulletinApplicantsSortValue, SortDirection][];
	page?: number;
	per_page?: number;
	search?: string;
}

class SingleBulletinApplicantProfile implements SingleBulletinApplicant {
	applicationUuid;
	fullName;
	profileImage;
	applicantUuid;
	teamName;
	isApprovalRequiredForPayment;
	createdAt;
	updatedAt;
	questionAnswers;

	constructor(data: BulletinApplicant | BulletinApplicantExpanded) {
		this.applicationUuid = data.uuid;
		this.fullName = data.user ? `${data.user.first_name} ${data.user.last_name}` : null;
		this.profileImage = data.user?.profile_image;
		this.applicantUuid = data.user?.uuid;
		this.teamName = data.team ? data.team.name : '';
		this.isApprovalRequiredForPayment = data.is_approval_required_for_payment;
		this.createdAt = data.created_at;
		this.updatedAt = data.updated_at;
		this.questionAnswers = [] as BulletinApplicantQuestionAnswer[];
		if (isBulletinApplicantExpanded(data)) {
			this.questionAnswers = data.question_answers;
		}
	}
}

export {
	type BulletinBase,
	type BulletinReach,
	type ExchangeBulletin,
	type BulletinApplicant,
	type BulletinApplicantExpanded,
	type BulletinApplicantsSortValue,
	type BulletinsSortValue,
	type SingleExchangeBulletin,
	type SingleBulletinApplicant,
	type GetBulletinsRequestParams,
	type GetBulletinsResponse,
	type GetSingleBulletinResponse,
	type GetBulletinReachParams,
	type ApiCreateBulletinRequestModel,
	type ApiCreateBulletinApplicationRequestModel,
	type CreateBulletinApplicationResponse,
	type GetSingleBulletinApplicantResponse,
	type GetBulletinApplicantsRequestParams,

	SingleBulletinApplicantProfile,
};
