import type { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
	{
		path: '/agent',
		redirect: { name: 'ExchangeTransactions' },
		children: [
			{
				path: 'athletes',
				redirect: { name: 'ExchangeTransactions' },
				children: [
					{
						path: 'browse',
						redirect: { name: 'ExchangeTransactions' },
					},
				],
			},
			{
				path: 'transactions',
				redirect: { name: 'ExchangeTransactions' },
			},
			{
				path: 'transactions/:transactionUuid',
				redirect: (to) => {
					return {
						name: 'ExchangeTransaction',
						params: { transactionUuid: to.params.transactionUuid },
					};
				},
			},
		],
	},
];

export default routes;
