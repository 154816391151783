import { reactive, unref, ref, watch, defineAsyncComponent, type MaybeRef } from 'vue';
import { Task } from '@/types/tasks';

const TransactionLinkDialog = defineAsyncComponent(() => import('@/components/TransactionLinkDialog.vue'));
interface UseTransactionLinkDialogState {
	isOpen: boolean;
	task: Task | undefined;
	transactionUuid: string | undefined;
	onSuccess: ((payload: Task) => void) | undefined;
	onError: ((payload: unknown) => void) | undefined;
}

const DEFAULT_STATE: UseTransactionLinkDialogState = {
	isOpen: false,
	task: undefined,
	transactionUuid: undefined,
	onSuccess: undefined,
	onError: undefined,
} as const;

const getDefaultState = () => ({ ...DEFAULT_STATE });

const state = reactive<UseTransactionLinkDialogState>(getDefaultState());

function resetState() {
	Object.assign(state, getDefaultState());
}

const isRendered = ref<boolean>(false);

watch(() => state.isOpen, (isOpen) => {
	if (isOpen && !isRendered.value) isRendered.value = true;
	if (!isOpen) resetState();
});

interface UseTransactionLinkDialogOptions {
	onSuccess?: (payload: Task) => void;
	onError?: (payload: unknown) => void;
}

export function useTransactionLinkDialog(options: UseTransactionLinkDialogOptions) {
	const open = (task: MaybeRef<Task>, transactionUuid: MaybeRef<string>) => {
		state.task = unref(task);
		state.onSuccess = options?.onSuccess;
		state.onError = options?.onError;
		state.isOpen = true;
		state.transactionUuid = unref(transactionUuid);
	};


	return {
		state,
		isRendered,
		resetState,
		open,
		TransactionLinkDialog,
	};
}
