<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			fill="currentcolor"
			d="M20.75 10.733h-.07C20.09 8.033 17.557 6 14.57 6h-3.473v1.533H8.181V6H2v3.367L8.32 12c0 3.3 2.812 6 6.25 6 3.055 0 5.59-2.1 6.145-4.9h.035c.694 0 1.25-.533 1.25-1.2 0-.633-.556-1.167-1.25-1.167Zm-2.639 5.334-1.944-.5c-.486.2-1.007.3-1.563.3-2.257 0-4.062-1.734-4.062-3.9 0-2.167 1.805-3.9 4.062-3.9 2.257 0 4.063 1.733 4.063 3.9 0 .966-.348 1.833-.938 2.5l.382 1.6Z"
		/>
	</svg>
</template>
