import { reactive, ref, watch, type MaybeRef, unref } from 'vue';
import type { SingleExchangeAthlete, SingleExchangeAthleteRateCard } from '@/types/exchangeAthletes';
import type { Transaction } from '@/types/transactions';

interface UseTransactionProposeState {
	isOpen: boolean;
	isEditing: boolean;
	activeTransactionUuid: string | undefined;
	activeBulletinUuid: string | undefined;
	activeRateCard: SingleExchangeAthleteRateCard | undefined;
	selectedAthletesUuids: string[];
	onUpdated: ((payload: Transaction) => void) | undefined;
	onSuccess: (() => void) | undefined;
}

const DEFAULT_STATE: UseTransactionProposeState = {
	isOpen: false,
	isEditing: false,
	activeTransactionUuid: undefined,
	activeBulletinUuid: undefined,
	activeRateCard: undefined,
	selectedAthletesUuids: [],
	onUpdated: undefined,
	onSuccess: undefined,
};

const getDefaultState = () => ({ ...DEFAULT_STATE });

const state = reactive<UseTransactionProposeState>(getDefaultState());

function resetState() {
	Object.assign(state, getDefaultState());
}

const isRendered = ref<boolean>(false);

watch(() => state.isOpen, (isOpen) => {
	if (isOpen && !isRendered.value) isRendered.value = true;
	if (!isOpen) resetState();
});

interface UseTransactionProposeDialogOptions {
	onUpdated?: (payload: Transaction) => void;
	onSuccess?: () => void;
	selectedAthletes?: MaybeRef<MaybeArray<Uuid> | SingleExchangeAthlete | null | undefined>;
	bulletin?: string;
}

export function useTransactionProposeDialog(options?: UseTransactionProposeDialogOptions) {
	async function open(uuid?: string, context?: 'edit') {
		state.isOpen = true;
		state.onUpdated = options?.onUpdated;
		state.onSuccess = options?.onSuccess;
		state.isEditing = context === 'edit';
		state.activeBulletinUuid = options?.bulletin;

		const selectedAthletesUuids = unref(options?.selectedAthletes);
		if (selectedAthletesUuids) {
			state.selectedAthletesUuids = Array.isArray(selectedAthletesUuids)
				? selectedAthletesUuids
				: typeof selectedAthletesUuids === 'string'
					? [selectedAthletesUuids]
					: [selectedAthletesUuids.uuid];
		}

		state.activeTransactionUuid = uuid;
	}

	return {
		state,
		isRendered,
		resetState,
		open,
	};
}
