<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		version="1.2"
		viewBox="0 0 24 24"
	>
		<path
			fill="currentColor"
			fill-rule="evenodd"
			d="M13.6 16.8c-.1 1.4-1.3 2.6-2.8 2.6q-.5 0-1-.2.5.2 1 .2c1.5 0 2.7-1.2 2.8-2.6v-13H16c.3 1.3 1 2.3 2 3 .8.4 1.6.7 2.6.7v3.2c-1.8 0-3.4-.5-4.7-1.4v6.6c0 3.2-2.7 5.9-6 5.9-1.3 0-2.5-.4-3.5-1.1-1.6-1-2.6-2.8-2.6-4.8 0-3.3 2.7-6 6.1-6q.4 0 .8.1v.7c-3.1.1-5.6 2.4-5.9 5.3.3-2.9 2.8-5.2 5.9-5.3v2.6q-.4-.2-.8-.2c-1.6 0-2.8 1.3-2.8 2.8 0 1 .6 1.9 1.5 2.4q.6.3 1.3.3c1.5 0 2.7-1.2 2.7-2.6V3h3.3q0 .4.1.8h-2.4z"
		/>
	</svg>
</template>
