import LayoutAuthenticated from './LayoutAuthenticated.vue';
import LayoutLogin from './LayoutLogin.vue';
import LayoutDefault from './LayoutDefault.vue';
import LayoutExchangeSchoolSite from './LayoutExchangeSchoolSite.vue';

export default {
	LayoutAuthenticated,
	LayoutLogin,
	LayoutDefault,
	LayoutExchangeSchoolSite,
};
