<template>
	<div class="app-badges d-flex justify-center align-center mt-8">
		<div>
			<a
				href="https://apps.apple.com/us/app/inflcr/id1319654053"
				target="_blank"
				rel="noopener"
			>
				<VImg
					:aspect-ratio="152/58"
					:src="appStoreBadge"
					:alt="t('pageLogin.appStoreBadgeAlt')"
					width="152px"
				/>
			</a>
		</div>
		<div>
			<a
				href="https://play.google.com/store/apps/details?id=com.inflcr.athlete"
				target="_blank"
				rel="noopener"
			>
				<VImg
					:aspect-ratio="152/58"
					:src="googlePlayBadge"
					:alt="t('pageLogin.googlePlayBadgeAlt')"
					width="152px"
				/>
			</a>
		</div>
	</div>
</template>

<script setup lang="ts">
import appStoreBadge from '@/assets/badge__app_store.png';
import googlePlayBadge from '@/assets/badge__google_play.png';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
</script>
