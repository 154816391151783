<template>
	<svg
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M18.625 13.8309V5.91612C18.625 5.21582 18.2521 4.57036 17.6458 4.21943L10.7917 0.262025C10.1854 -0.0873417 9.43963 -0.0873417 8.83333 0.262025L1.97917 4.21943C1.37287 4.56879 1 5.21582 1 5.91612V13.8309C1 14.5312 1.37287 15.1767 1.97917 15.5276L8.83333 19.485C9.43963 19.8344 10.1854 19.8344 10.7917 19.485L17.6458 15.5276C18.2521 15.1783 18.625 14.5312 18.625 13.8309Z"
			fill="#7B62D5"
		/>
		<path
			d="M10.6993 8.67505V9.98008C10.4 9.92838 10.093 9.90175 9.77962 9.90175C9.46629 9.90175 9.15922 9.92838 8.85999 9.98008V8.67505H10.6993Z"
			fill="#9E9EE8"
		/>
		<path
			d="M8.11893 8.67505V10.1618C7.68027 10.3028 7.2651 10.4971 6.8844 10.7399V8.67505H8.11893Z"
			fill="#9E9EE8"
		/>
		<path
			d="M12.6764 8.67505V10.7399C12.2941 10.4986 11.8805 10.3028 11.4418 10.1618V8.67505H12.6764Z"
			fill="#9E9EE8"
		/>
		<path
			d="M13.0085 7.81805H6.58363C6.44346 7.81805 6.32983 7.93168 6.32983 8.07185V8.42122C6.32983 8.56139 6.44346 8.67502 6.58363 8.67502H13.0085C13.1487 8.67502 13.2623 8.56139 13.2623 8.42122V8.07185C13.2623 7.93168 13.1487 7.81805 13.0085 7.81805Z"
			fill="white"
		/>
		<path
			d="M13.5709 6.3219H6.02272C5.8566 6.3219 5.72192 6.45657 5.72192 6.6227V7.03473C5.72192 7.20086 5.8566 7.33553 6.02272 7.33553H13.5709C13.7371 7.33553 13.8717 7.20086 13.8717 7.03473V6.6227C13.8717 6.45657 13.7371 6.3219 13.5709 6.3219Z"
			fill="white"
		/>
		<path
			d="M10.7008 13.6664C10.7024 13.8325 10.5661 13.9672 10.4 13.9672H9.16235C8.99629 13.9672 8.86155 13.8325 8.86155 13.6664V10.4109L8.85999 9.98007C9.15922 9.92837 9.46629 9.90173 9.77962 9.90173C10.093 9.90173 10.4 9.92837 10.6993 9.98007V13.6664H10.7008Z"
			fill="white"
		/>
		<path
			d="M8.12052 13.3124C8.12052 13.4784 7.98579 13.6132 7.81972 13.6132H7.18679C7.02072 13.6132 6.88599 13.4784 6.88599 13.3124V10.7399C7.26512 10.4971 7.68029 10.3028 8.11895 10.1618V13.3124H8.12052Z"
			fill="white"
		/>
		<path
			d="M12.6764 10.7399V13.3124C12.6764 13.4784 12.5432 13.6132 12.3771 13.6132H11.7426C11.5766 13.6132 11.4434 13.4784 11.4434 13.3124V10.6537L11.4418 10.1618C11.8805 10.3028 12.2941 10.4986 12.6764 10.7399Z"
			fill="white"
		/>
	</svg>
</template>
