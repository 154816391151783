<template>
	<VSheet
		ref="baseToast"
		:class="[
			'toast pa-4 text-decoration-none d-flex no-wrap',
			`toast-${toast.uuid}`,
			{ 'cursor-pointer': toast.link, },
		]"
		rounded
		:border="border"
		:elevation="elevation"
		:height="height"
		:width="width"
		:tabindex="toast.link ? 0 : -1"
		v-bind="toastAttrs"
		data-testid="elementToastNotification"
		@click="onToastClick"
	>
		<VIcon
			:color="computedColor"
			:icon="computedIcon"
		/>
		<div class="toast__details px-2">
			<div
				class="toast__details-title text- text-body-1 font-weight-semibold"
			>
				{{ toast.title }}
			</div>
			<div
				class="toast__details-description text-body-2 font-weight-regular text-medium-emphasis"
				v-html="toast.description"
			/>
		</div>
		<VBtn
			ref="baseToastCloseButton"
			data-testid="buttonToastClose"
			class="toast__close"
			icon
			variant="text"
			size="24"
			:aria-label="t('baseToast.dismissText')"
			@click.stop="close"
		>
			<VIcon icon="close" />
		</VBtn>
	</VSheet>
</template>

<script lang="ts" setup>
import { onMounted, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import {
	useToast,
	linkIsRouteLocation,
	type Toast,
} from '@/composables';

const props = withDefaults(defineProps<{
	border?: boolean;
	elevation?: number | string;
	height?: number | string;
	toast: Toast;
	timeout?: number;
	width?: number | string;
}>(), {
	border: false,
	elevation: 0,
	height: undefined,
	timeout: undefined,
	width: 394,
});

const emit = defineEmits<{
	(e: 'close', uuid: string): void;
}>();

const { t } = useI18n();

const {
	computedColor,
	computedIcon,
} = useToast(props);

function close() {
	emit('close', props.toast.uuid);
}

function setAutoClose() {
	if (!props.timeout) return;
	setTimeout(() => {
		close();
	}, props.timeout);
}

const toastAttrs = computed(() => {
	if (!props.toast.link || linkIsRouteLocation(props.toast.link)) return;
	return {
		tag: 'a',
		href: props.toast.link,
		target: '_blank',
		rel: 'noopener noreferrer',
	};
});

const router = useRouter();

const onToastClick = () => {
	if (!props.toast.link) close();
	if (!linkIsRouteLocation(props.toast.link)) return;
	router.push(props.toast.link);
	close();
};

onMounted(() => {
	setAutoClose();
});
</script>

<style lang="scss" scoped>
.toast {
	position: relative;
	&__details {
		flex: 1 1 auto;
		min-width: 1px;
		min-height: 1px;
		&-description {
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}
	}
}
</style>
