import { ref, reactive, watch } from 'vue';
import type { Media } from '@/types/media';
import type { ExchangeStatus } from '@teamworksdev/influencer-core';
import type { UserUpdateableTransactionStatus } from '@/types/transactions';

interface UseTransactionDrawerState {
	activeTransactionUuid: string | undefined;
	isOpen: boolean;
	reviewsRemaining: number | undefined;
	onAgreementArchived: (() => void) | undefined;
	onAgreementStatusUpdated: (() => void) | undefined;
	onAgreementUpdated: (() => void) | undefined;
	onArchived: (() => void) | undefined;
	onPaymentSent: (() => void) | undefined;
	onPreviewMedia: ((payload: { index: number; media: Media[] }) => void) | undefined;
	onReviewed: (() => void) | undefined;
	onTaskUpdated: (() => void) | undefined;
	onUpdated: (() => void) | undefined;
	onUpdateStatusSuccess: ((payload: ExchangeStatus) => void) | undefined;
	onUpdateTransactionStatusSuccess: ((payload: UserUpdateableTransactionStatus) => void) | undefined;
}

const DEFAULT_STATE: UseTransactionDrawerState = {
	activeTransactionUuid: undefined,
	isOpen: false,
	reviewsRemaining: undefined,
	onAgreementArchived: undefined,
	onAgreementStatusUpdated: undefined,
	onAgreementUpdated: undefined,
	onArchived: undefined,
	onPaymentSent: undefined,
	onPreviewMedia: undefined,
	onReviewed: undefined,
	onTaskUpdated: undefined,
	onUpdated: undefined,
	onUpdateStatusSuccess: undefined,
	onUpdateTransactionStatusSuccess: undefined,
} as const;

const getDefaultState = () => ({ ...DEFAULT_STATE });

const state = reactive<UseTransactionDrawerState>(getDefaultState());

function resetState() {
	Object.assign(state, getDefaultState());
}

const isRendered = ref<boolean>(false);

watch(() => state.isOpen, (isOpen) => {
	if (isOpen && !isRendered.value) isRendered.value = true;
	if (!isOpen) resetState();
});

interface UseTransactionDrawerOptions {
	onAgreementArchived?: () => void;
	onAgreementStatusUpdated?: () => void;
	onAgreementUpdated?: () => void;
	onArchived?: () => void;
	onPaymentSent?: () => void;
	onPreviewMedia?: (payload: { index: number; media: Media[] }) => void;
	onReviewed?: () => void;
	onTaskUpdated?: () => void;
	onUpdated?: () => void;
	onUpdateStatusSuccess?: (payload: ExchangeStatus) => void;
	onUpdateTransactionStatusSuccess?: (payload: UserUpdateableTransactionStatus) => void;
}

export function useTransactionDrawer(options?: UseTransactionDrawerOptions) {
	async function open(uuid?: string, openOptions?: {
		reviewsRemaining?: number;
	}) {
		state.isOpen = true;
		state.activeTransactionUuid = uuid;
		state.reviewsRemaining = openOptions?.reviewsRemaining;
		state.onAgreementArchived = () => closeWithCallback(options?.onAgreementArchived);
		state.onAgreementStatusUpdated = options?.onAgreementStatusUpdated;
		state.onAgreementUpdated = () => closeWithCallback(options?.onAgreementUpdated);
		state.onArchived = () => closeWithCallback(options?.onArchived);
		state.onPaymentSent = options?.onPaymentSent;
		state.onPreviewMedia = options?.onPreviewMedia;
		state.onReviewed = options?.onReviewed;
		state.onTaskUpdated = options?.onTaskUpdated;
		state.onUpdated = options?.onUpdated;
		state.onUpdateStatusSuccess = options?.onUpdateStatusSuccess;
		state.onUpdateTransactionStatusSuccess = options?.onUpdateTransactionStatusSuccess;
	}

	function close() {
		state.isOpen = false;
	}

	function closeWithCallback(fn: (() => void) | undefined) {
		close();
		fn?.();
	}

	return {
		state,
		isRendered,
		resetState,
		open,
		close,
	};
}
