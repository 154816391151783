<template>
	<div
		class="sub-layout-list"
		:class="mobile ? '--mobile':''"
		data-testid="elementSubLayoutList"
	>
		<VTable
			class="bg-background border rounded"
			:hover="hover"
		>
			<thead
				v-if="!mobile"
				class="bg-surface sub-layout-grid__header"
			>
				<tr
					class="item item--header"
					:style="gridColumnsStyle"
				>
					<th
						v-for="header,index in headers"
						:key="index"
						class="text-body-2 font-weight-semibold"
						:style="{ justifyContent: header.align || 'start' }"
					>
						<span class="text-truncate">{{ header.text }}</span>
					</th>
				</tr>
			</thead>
			<Grid
				v-bind="$attrs"
				:length="length"
				:page-provider="pageProvider"
				:page-size="pageSize"
				:page-provider-debounce-time="10"
				class="grid"
				tag="tbody"
			>
				<template #probe>
					<tr
						class="item item--body"
						:style="{...gridColumnsStyle}"
					>
						<td
							v-for="header in headers"
							:key="header.value"
							class="text-body-2 font-weight-semibold"
						>
							<span class="text-truncate">{{ header.text }}</span>
						</td>
					</tr>
				</template>
				<template #placeholder="{ style }">
					<div
						class="item item--body"
						:style="style"
					>
						<VSheet
							class="border-b"
							color="surface"
							width="100%"
							height="100%"
						/>
					</div>
				</template>
				<template #default="{ item, index, style }">
					<tr
						class="item item--body"
						:style="{...style, ...gridColumnsStyle}"
					>
						<template v-if="mobile">
							<td
								v-for="header in headers"
								:key="header.value"
								class="text-body-2 font-weight-semibold"
							>
								<span class="text-truncate">{{ header.text }}</span>
							</td>
						</template>
						<slot
							:item="item"
							:index="index"
						/>
					</tr>
				</template>
			</Grid>
		</VTable>
	</div>
</template>

<script setup lang="ts">
import { computed }	from 'vue';
import { useDisplay } from 'vuetify';
import Grid from 'vue-virtual-scroll-grid';


export interface Header<T = any> {
	text: string;
	value: Extract<keyof T, string>;
	align?: string;
	width?: string;
}

const props = defineProps<{
	length: number;
	pageProvider: (pageNumber: number, pageSize: number) => Promise<unknown[]>;
	pageSize: number;
	headers: Header[];
	hover?: boolean;
}>();

const { mobile } = useDisplay();

const gridColumnsStyle = computed(() => {
	if (mobile.value) {
		const rows = props.headers.map(() => '1fr').join(' ');
		return { gridTemplateRows: rows, gridTemplateColumns: 'minmax(0,1fr) minmax(0,1fr)', gridAutoFlow: 'column' };
	}
	const columns = props.headers.map((header) => header.width || 'minmax(0,1fr)').join(' ');
	return {
		gridTemplateColumns: columns,
	};
});


</script>

<style lang="scss" scoped>
@use '@/styles/settings.scss';

.grid {
	display: grid;
	grid-template-columns: 1fr;
}

.item {
	display: grid;
	align-self: center;

	&--header {
		height: settings.$table-header-height;
	}

	&--body {
		height: settings.$table-row-height;
	}

	th, :deep(td) {
		display: flex;
		align-items: center;
	}
}

:deep(.v-table--hover){
	tr:hover{
		cursor: pointer;
	}
	thead tr:hover{
		cursor: default;
	}
}

:deep(.v-table__wrapper) {
	overflow: initial;
	& > table > tbody > tr > td {
		border-bottom: settings.$table-border;
	}
}

.--mobile{
	.item{
		&--header {
			height: auto;
		}

		&--body {
			height: auto;
		}
	}
	:deep(.v-table .v-table__wrapper) {
		& > table > tbody > tr > td {
			border-bottom: none;
		}
		& > table > tbody > tr {
			border-bottom: settings.$table-border;
		}
	}
}
</style>
