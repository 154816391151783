<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 113 126"
	>
		<path
			fill="#EDB23B"
			d="M112.5 88.25v-50.5c0-4.5-2.4-8.6-6.3-10.8L62.5 1.65c-3.9-2.2-8.6-2.2-12.5 0L6.3 26.85c-3.9 2.3-6.3 6.4-6.3 10.9v50.5c0 4.5 2.4 8.6 6.3 10.8l43.7 25.3c3.9 2.2 8.6 2.2 12.5 0l43.7-25.3c3.9-2.2 6.3-6.4 6.3-10.8Z"
		/>
		<path
			fill="#fff"
			d="M83.6 35.15H28.9c-5.1 0-9.2 4.1-9.2 9.2v37.4c0 5.1 4.1 9.2 9.2 9.2h54.7c5 0 9.2-4.1 9.2-9.2v-37.4c0-5.1-4.1-9.2-9.2-9.2Z"
		/>
		<path
			fill="#ECB13B"
			d="m85.4 44.85-61.9 7.5h-.1v-.2c-.5-3.9 2.3-7.5 6.3-8l47.8-5.8c3.9-.5 7.5 2.3 8 6.3l-.1.2ZM86.5 53.25l-62 7.5.7 5.5 64 9.1-2.7-22.1Z"
		/>
		<path
			fill="#AD7C3B"
			d="m86.5 53.25-62.1 7.5-1-8.4h.1l61.9-7.5 1.1 8.4ZM81.8 66.35l-7.9 1c-.9.1-1.7-.5-1.8-1.4l-.6-4.7c-.1-.9.5-1.6 1.4-1.8l7.9-1c.9-.1 1.7.5 1.8 1.4l.6 4.7c.1 1-.5 1.7-1.4 1.8Z"
		/>
	</svg>
</template>
