import type { GetSchoolsRequestParams, GetSchoolsResponse } from '@/types/schools';
import { getList, isApiError } from './utilities/provider';
import type { AxiosRequestConfig } from 'axios';

const RESOURCE = 'schools';

export function useSchoolsService(config?: Omit<AxiosRequestConfig, 'key'>) {
	const getSchools = async (params?: GetSchoolsRequestParams) => {
		const response = await getList<GetSchoolsResponse>(RESOURCE, {
			key: 'getSchools',
			params,
			...config,
		});
		if (isApiError(response)) throw response;
		return response.data.data;
	};

	return {
		getSchools,
	};
}
