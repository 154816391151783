<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			fill="currentColor"
			fill-rule="evenodd"
			d="M16.7755 12c0-2.6374-2.138-4.7755-4.7755-4.7755-2.6374 0-4.7755 2.138-4.7755 4.7755S9.3625 16.7755 12 16.7755s4.7755-2.138 4.7755-4.7755Zm-7.8367 0c0-1.6907 1.3705-3.0612 3.0612-3.0612 1.6907 0 3.0612 1.3705 3.0612 3.0612 0 1.6907-1.3705 3.0612-3.0612 3.0612-1.6907 0-3.0612-1.3705-3.0612-3.0612Z"
			clip-rule="evenodd"
		/>
		<path
			fill="currentColor"
			fill-rule="evenodd"
			d="M19.3877 12c0-4.0801-3.3076-7.3877-7.3878-7.3877-4.0801 0-7.3877 3.3076-7.3877 7.3877 0 4.0802 3.3076 7.3878 7.3877 7.3878 4.0802 0 7.3878-3.3076 7.3878-7.3878ZM6.3265 12c0-3.1334 2.54-5.6735 5.6734-5.6735S17.6734 8.8666 17.6734 12s-2.5401 5.6735-5.6735 5.6735c-3.1333 0-5.6734-2.5401-5.6734-5.6735Z"
			clip-rule="evenodd"
		/>
		<path
			fill="currentColor"
			fill-rule="evenodd"
			d="M22 12c0-5.5229-4.4772-10-10-10C6.4771 2 2 6.4771 2 12c0 5.5228 4.4771 10 10 10 5.5228 0 10-4.4772 10-10ZM3.7143 12c0-4.576 3.7096-8.2857 8.2857-8.2857S20.2857 7.4239 20.2857 12 16.5761 20.2857 12 20.2857c-4.576 0-8.2857-3.7096-8.2857-8.2857Z"
			clip-rule="evenodd"
		/>
	</svg>
</template>
