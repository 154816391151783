<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		version="1.2"
		viewBox="0 0 1400 1400"
		width="24"
		height="24"
	>
		<path
			fill="currentColor"
			d="M1337.7 702.3c0-352-285.4-637.3-637.4-637.3S63 350.3 63 702.3c0 298.9 205.8 549.7 483.4 618.6V897.1H414.9V702.3h131.5v-83.9c0-216.9 98.1-317.5 311.1-317.5 40.4 0 110.1 8 138.6 15.9v176.5c-15.1-1.6-41.2-2.4-73.7-2.4-104.5 0-144.9 39.6-144.9 142.6v68.8h208.2L950 897.1H777.5V1335c315.6-38.1 560.2-306.8 560.2-632.7z"
		/>
	</svg>
</template>
