<template>
	<svg
		viewBox="0 0 18 16"
		fill="currentColor"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M3.50771 5.30302C3.28433 5.16994 3.00839 5.16923 2.78435 5.30114L1.02814 6.33524C0.804473 6.46694 0.666626 6.71076 0.666626 6.97467V9.01453C0.666626 9.27805 0.804057 9.52156 1.02719 9.6534L2.7834 10.6911C3.00792 10.8238 3.2848 10.8231 3.50866 10.6893L5.24021 9.65402C5.46154 9.52169 5.59759 9.27921 5.59759 9.01703V6.97217C5.59759 6.7096 5.46112 6.4668 5.23925 6.33462L3.50771 5.30302Z"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M6.39583 0.18357C6.17245 0.050488 5.89651 0.0497705 5.67247 0.181689L3.91625 1.21578C3.69259 1.34748 3.55474 1.5913 3.55474 1.85522V3.89508C3.55474 4.15859 3.69217 4.4021 3.9153 4.53395L5.67151 5.57169C5.89604 5.70436 6.17292 5.70364 6.39678 5.5698L8.12832 4.53456C8.34966 4.40223 8.4857 4.15975 8.4857 3.89758V1.85272C8.4857 1.59014 8.34924 1.34735 8.12737 1.21517L6.39583 0.18357Z"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M6.39583 10.4295C6.17245 10.2964 5.89651 10.2957 5.67247 10.4276L3.91625 11.4617C3.69259 11.5934 3.55474 11.8372 3.55474 12.1011V14.141C3.55474 14.4045 3.69217 14.648 3.9153 14.7799L5.67151 15.8176C5.89604 15.9503 6.17292 15.9495 6.39678 15.8157L8.12832 14.7805C8.34966 14.6481 8.4857 14.4057 8.4857 14.1435V12.0986C8.4857 11.836 8.34924 11.5932 8.12737 11.4611L6.39583 10.4295Z"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M12.2637 0.18357C12.0403 0.050488 11.7644 0.0497705 11.5404 0.181689L9.78415 1.21578C9.56049 1.34748 9.42264 1.5913 9.42264 1.85522V3.89508C9.42264 4.15859 9.56007 4.4021 9.7832 4.53395L11.5394 5.57169C11.7639 5.70436 12.0408 5.70364 12.2647 5.5698L13.9962 4.53456C14.2176 4.40223 14.3536 4.15975 14.3536 3.89758V1.85272C14.3536 1.59014 14.2171 1.34735 13.9953 1.21517L12.2637 0.18357Z"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M12.2637 10.4295C12.0403 10.2964 11.7644 10.2957 11.5404 10.4276L9.78415 11.4617C9.56049 11.5934 9.42264 11.8372 9.42264 12.1011V14.141C9.42264 14.4045 9.56007 14.648 9.7832 14.7799L11.5394 15.8176C11.7639 15.9503 12.0408 15.9495 12.2647 15.8157L13.9962 14.7805C14.2176 14.6481 14.3536 14.4057 14.3536 14.1435V12.0986C14.3536 11.836 14.2171 11.5932 13.9953 11.4611L12.2637 10.4295Z"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M9.37552 5.30302C9.15214 5.16994 8.8762 5.16923 8.65217 5.30114L6.89595 6.33524C6.67228 6.46694 6.53444 6.71076 6.53444 6.97467V9.01453C6.53444 9.27805 6.67187 9.52156 6.895 9.6534L8.65121 10.6911C8.87573 10.8238 9.15261 10.8231 9.37647 10.6893L11.108 9.65402C11.3294 9.52169 11.4654 9.27921 11.4654 9.01703V6.97217C11.4654 6.7096 11.3289 6.4668 11.1071 6.33462L9.37552 5.30302Z"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M15.2434 5.30302C15.02 5.16994 14.7441 5.16923 14.5201 5.30114L12.7638 6.33524C12.5402 6.46694 12.4023 6.71076 12.4023 6.97467V9.01453C12.4023 9.27805 12.5398 9.52156 12.7629 9.6534L14.5191 10.6911C14.7436 10.8238 15.0205 10.8231 15.2444 10.6893L16.9759 9.65402C17.1972 9.52169 17.3333 9.27921 17.3333 9.01703V6.97217C17.3333 6.7096 17.1968 6.4668 16.975 6.33462L15.2434 5.30302Z"
		/>
	</svg>
</template>
