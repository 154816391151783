import Pusher from 'pusher-js';
import { useAuthStore } from '@/stores/authStore';

const getAuthToken = () => {
	const { token } = useAuthStore();
	return token?.access_token;
};

export const usePusher = () => {
	return new Pusher(import.meta.env.VITE_PUSHER_APP_KEY, {
		cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
		channelAuthorization: {
			endpoint: `${import.meta.env.VITE_API_BASE}/broadcasting/auth`,
			headers: {
				Authorization: `Bearer ${getAuthToken()}`,
			},
			transport: 'ajax',
		},
		forceTLS: true,
	});
};
