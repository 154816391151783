import type { ListResponse, SortDirection } from '@/api/utilities/provider';
import type { Gallery } from './galleries';
import type { User } from './users';
import {
	type MediaFileType,
	type MediaConversionSize,
	type MediaModelType,
	type MediaActivityType,
	type TagType,
	type TagStatus,
} from '@teamworksdev/influencer-core';


interface MediaConversion {
	size: number | null;
	url: string | null;
}

interface Media {
	uuid: string;
	file_name: string;
	mime_type: string;
	file_size: number;
	file_type: MediaFileType;
	conversions: Partial<Record<MediaConversionSize, MediaConversion>>
	& { original: NonNullableFields<MediaConversion> };
}

interface ApiMediaAttachment {
	fileName: string;
	key: string;
}


interface MediaUserTag {
	uuid: string;
	first_name: string;
	last_name: string;
	jersey_number: string | null;
}

interface MediaTags {
	users: MediaUserTag[];
	total: number;
}


interface MediaGallery extends Media {
	tags: MediaTags;
	gallery: Pick<Gallery, 'uuid' | 'title' | 'category'>;
}

const isMediaGallery = (media: Media | MediaGallery): media is MediaGallery => {
	return 'gallery' in media;
};

interface MediaGalleryDetailed extends MediaGallery {
	keywords: string[];
	uploaded_by: Pick<User, 'uuid' | 'first_name' | 'last_name'>;
	source: string | null;
	attribution: string;
	shared_copy: string;
	activities: {
		total_likes: number;
		total_dislikes: number;
		total_views: number;
		total_shares: number;
		total_downloads: number;
	};
}

type GetMediaResponse = {
	data: MediaGalleryDetailed;
};

type GetMediaListResponse = ListResponse<MediaGallery>;

interface GetMediaUuidsResponse {
	data: {
		uuid: string;
	}[];
}

type MediaActivity = {
	first_name: string;
	last_name: string;
	group: string;
	created_at: string;
} & ({
	type: Exclude<MediaActivityType, 'tagged'>;
} | {
	type: 'tagged';
	tag_type: TagType;
});

type GetMediaActivityResponse = {
	data: MediaActivity[];
};

type MediaRequestSortValue = 'file_name' | 'created_at' | 'tag_per_athlete';
type MediaRequestSort = [MediaRequestSortValue, SortDirection][];

interface MediaRequestFilters {
	tagged?: TagStatus;
	tag_type_ids?: TagType[];
	content_keywords?: string[];
}


interface MediaRequestParams extends MediaRequestFilters {
	belongs_to: MediaModelType;
	belongs_to_id?: string;
	tagged_user_id?: string;
	per_page?: number;
	sort?: MediaRequestSort;
	all?: 0 | 1;
}

interface AttachMediaRequestModel {
	attachments: ApiMediaAttachment[];
}

interface MediaModel {
	type: MediaModelType;
	id: string;
}

interface MediaUpdateModel {
	shared_copy?: string;
	attribution?: string;
	keywords?: {
		add?: string[];
		remove?: string[];
	};
	tags?: {
		add?: string[];
		remove?: string[];
		overwrite?: boolean;
	};
}

interface MediaDownloadModel {
	conversion_size: MediaConversionSize;
	include_metadata?: boolean;
}

export {
	isMediaGallery,
	type MediaConversion,
	type Media,
	type ApiMediaAttachment,
	type MediaUserTag,
	type MediaTags,
	type MediaGallery,
	type MediaGalleryDetailed,
	type GetMediaResponse,
	type GetMediaListResponse,
	type GetMediaUuidsResponse,
	type MediaActivity,
	type GetMediaActivityResponse,
	type MediaRequestSortValue,
	type MediaRequestSort,
	type MediaRequestFilters,
	type MediaRequestParams,
	type MediaModel,
	type MediaUpdateModel,
	type MediaDownloadModel,
	type AttachMediaRequestModel,
};
