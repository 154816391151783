<template>
	<VList
		ref="userMenu"
		class="px-4 mb-md-2"
		density="compact"
		nav
	>
		<VListItem
			class="pl-1"
			link
			:title="user.name"
		>
			<template #prepend>
				<CommonAvatar
					:src="user.avatar"
					size="40"
				/>
			</template>
			<VMenu
				v-model="isMenuActive"
				theme="INFLCRLight"
				activator="parent"
				location="top"
			>
				<VCard
					border
					class="pa-3"
					width="238"
				>
					<div class="text-medium-emphasis px-2 pb-2 text-caption">
						{{ t('settings') }}
					</div>
					<VList
						class="pa-0"
						density="compact"
						nav
					>
						<template
							v-for="(item, index) in userItems"
							:key="index"
						>
							<VDivider v-if="index === userItems.length - 1" />
							<TheNavigationMenuItem
								:class="{ 'text-error' : item.title === 'Logout' }"
								:nav-item="item"
								:is-expanded="isExpanded"
								:tooltip-visible="false"
							/>
						</template>
					</VList>
				</VCard>
			</VMenu>
			<VTooltip
				v-if="!isExpanded"
				activator="parent"
				location="right"
			>
				{{ `${user.name} ${t('settings')}` }}
			</VTooltip>
		</VListItem>
	</VList>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { usePrimaryNavigation } from '@/composables';
import { useI18n } from 'vue-i18n';
import { useUserStore } from '@/stores/userStore';
import CommonAvatar from '@/components/CommonAvatar.vue';
import TheNavigationMenuItem from '@/components/TheNavigationMenuItem.vue';

defineProps<{
	isExpanded: boolean;
}>();
const { t } = useI18n();

const isMenuActive = ref(false);

const { userItems } = usePrimaryNavigation();

const userStore = useUserStore();
const user = computed(() => ({
	name: `${userStore.user?.first_name} ${userStore.user?.last_name}`,
	avatar: userStore.user?.image,
}));
</script>
