import type {
	AppSwitcherUserProfile,
	ConfirmUserRequest,
	UpdateUserRequest,
	UserConfirmationCodeResponse,
	UserResponse,
} from '@/types/users';
import {
	getList,
	patchWithoutId,
	getSingle,
	isApiError,
	post,
} from './utilities/provider';
import type { AxiosRequestConfig } from 'axios';
import type { AuthToken } from '@/types/auth';

const RESOURCE = 'users';

export function useUserService(config?: Omit<AxiosRequestConfig, 'key'>) {
	const getUser = async (bypassImpersonation: boolean = false) => {
		const response = await getList<UserResponse>(`${RESOURCE}/me`, {
			key: 'getUser',
			requiresInflcrId: false,
			requiresAcceptedTou: false,
			bypassImpersonation,
			...config,
		});
		if (isApiError(response)) throw response;
		return response.data.data;
	};

	const updateUser = async (data: UpdateUserRequest) => {
		const response = await patchWithoutId<UserResponse>(`${RESOURCE}/me`, data, {
			key: 'updateUser',
			requiresInflcrId: false,
			...config,
		});
		if (isApiError(response)) throw response;
		return response.data.data;
	};

	const getAppSwitcherUserProfile = async () => {
		const response = await getSingle<AppSwitcherUserProfile>(`${RESOURCE}/app-switcher`, '', {
			key: 'getUserAppSwitcherUserProfile',
			...config,
		});
		if (isApiError(response)) throw response;
		return response.data;
	};

	const getUserConfirmation = async (confirmationCode: string) => {
		const response = await getSingle<UserConfirmationCodeResponse>(`${RESOURCE}/confirm`, confirmationCode, {
			key: 'getUserConfirmation',
			requiresAuth: false,
			requiresInflcrId: false,
			requiresAcceptedTou: false,
			...config,
		});
		if (isApiError(response)) throw response;
		return response.data;
	};

	const confirmUser = async (confirmationCode: string, model: ConfirmUserRequest) => {
		const response = await post<AuthToken>(`${RESOURCE}/confirm/${confirmationCode}`, model, <AxiosRequestConfig>{
			key: 'confirmUser',
			requiresAuth: false,
			requiresInflcrId: false,
			requiresAcceptedTou: false,
			...config,
		});
		if (isApiError(response)) throw response;
		return response.data;
	};

	const updateDefaultProfile = async (profileUuid: string) => {
		const response = await post(`${RESOURCE}/user-profiles/${profileUuid}/default`, {}, {
			key: 'updateDefaultProfile',
			requiresInflcrId: false,
			...config,
		});
		if (isApiError(response)) throw response;
		return response.status;
	};

	return {
		getUser,
		updateUser,
		getAppSwitcherUserProfile,
		getUserConfirmation,
		confirmUser,
		updateDefaultProfile,
	};
}
