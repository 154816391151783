<template>
	<main class="layout-login">
		<VContainer
			class="pa-0 h-screen"
			fluid
		>
			<VRow
				class="h-100 pa-4 bg-background rounded d-flex align-center"
				no-gutters
			>
				<VCol
					cols="12"
					md="6"
					class="d-flex justify-center pr-md-6"
				>
					<VSheet
						class="layout-login__sheet"
						max-width="100%"
						width="400px"
					>
						<VImg
							:src="logoInflcr"
							:aspect-ratio="131/44"
							width="175"
							:alt="`Influencer ${t('logo')}`"
							class="mb-14"
						/>
						<slot />
					</VSheet>
				</VCol>
				<VCol
					cols="6"
					class="d-none d-md-flex h-100"
				>
					<LoginPreview />
				</VCol>
			</VRow>
		</VContainer>
	</main>
</template>

<script setup lang="ts">
import LoginPreview from '@/components/login/LoginPreview.vue';
import logoInflcr from '@/assets/logo__inflcr.svg';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
</script>
