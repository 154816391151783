<template>
	<svg
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M18.625 13.8309V5.91612C18.625 5.21582 18.2521 4.57036 17.6458 4.21942L10.7917 0.262025C10.1854 -0.0873417 9.43963 -0.0873417 8.83333 0.262025L1.97917 4.21942C1.37287 4.56879 1 5.21582 1 5.91612V13.8309C1 14.5312 1.37287 15.1767 1.97917 15.5276L8.83333 19.485C9.43963 19.8344 10.1854 19.8344 10.7917 19.485L17.6458 15.5276C18.2521 15.1783 18.625 14.5312 18.625 13.8309Z"
			fill="#1A6AFF"
		/>
		<path
			d="M9.80307 12.9144C9.64327 12.9144 9.48504 12.8737 9.34247 12.7922L7.4938 11.7253C7.21024 11.5624 7.0332 11.2569 7.0332 10.9294V8.79564C7.0332 8.4682 7.20867 8.1627 7.49224 7.99977L9.3409 6.93287C9.62447 6.76994 9.97697 6.76994 10.2605 6.93287L12.1092 7.99977C12.3928 8.16427 12.5698 8.4682 12.5698 8.79564V10.9294C12.5698 11.2569 12.3943 11.5624 12.1092 11.7253L10.2605 12.7922C10.118 12.8737 9.95974 12.9144 9.79994 12.9144H9.80307ZM9.80307 7.5893C9.77957 7.5893 9.75607 7.59557 9.73414 7.6081L7.88547 8.675C7.84317 8.70007 7.81654 8.74394 7.81654 8.7925V10.9263C7.81654 10.9749 7.84317 11.0203 7.8839 11.0438L9.73257 12.1107C9.77487 12.1358 9.82657 12.1358 9.86887 12.1107L11.7175 11.0438C11.7598 11.0187 11.7865 10.9749 11.7865 10.9263V8.7925C11.7865 8.74394 11.7598 8.6985 11.7175 8.675L9.86887 7.6081C9.8485 7.59557 9.825 7.5893 9.79994 7.5893H9.80307Z"
			fill="#34AFFF"
		/>
		<path
			d="M9.8141 9.16696C9.5368 9.16696 9.2595 9.10586 8.99943 8.98366L5.81753 7.47339C5.65617 7.39662 5.55277 7.23526 5.54963 7.05666C5.5465 6.87806 5.6452 6.71356 5.80343 6.63052L8.9634 4.98866C9.4992 4.72232 10.1306 4.72232 10.6585 4.98552L13.8263 6.63209C13.9846 6.71512 14.0833 6.87962 14.0801 7.05822C14.077 7.23682 13.9736 7.39819 13.8122 7.47496L10.6319 8.98522C10.3734 9.10742 10.0961 9.17009 9.81723 9.17009L9.8141 9.16696ZM7.07557 7.02846L9.40363 8.13296C9.66527 8.25672 9.9645 8.25672 10.2261 8.13296L12.5526 7.02846L10.2324 5.82212C9.9739 5.69366 9.65587 5.69366 9.38953 5.82526L7.07713 7.02689L7.07557 7.02846Z"
			fill="white"
		/>
		<path
			d="M8.87091 14.9605C8.80041 14.9605 8.72991 14.9448 8.66567 14.9135L6.08067 13.6586C5.43207 13.3437 5.01221 12.6732 5.01221 11.9525V8.82229C5.01221 8.66249 5.09367 8.51365 5.22684 8.42749C5.36157 8.34132 5.52921 8.32879 5.67491 8.39302L8.53094 9.67612C9.02287 9.89702 9.34091 10.389 9.34091 10.9295V14.4921C9.34091 14.6534 9.25787 14.8038 9.12001 14.89C9.04481 14.937 8.95707 14.9621 8.87091 14.9621V14.9605ZM5.95221 9.54765V11.9509C5.95221 12.3144 6.16371 12.6528 6.49114 12.811L8.40091 13.7385V10.9263C8.40091 10.7556 8.30064 10.6005 8.14554 10.5315L5.95221 9.54609V9.54765Z"
			fill="white"
		/>
		<path
			d="M10.7541 14.9573C10.6679 14.9573 10.5802 14.9338 10.505 14.8853C10.3671 14.7991 10.2841 14.6487 10.2841 14.4873V10.9247C10.2841 10.3842 10.6021 9.89387 11.094 9.67141L13.9485 8.38831C14.0942 8.32251 14.2618 8.33504 14.3966 8.42277C14.5297 8.50894 14.6112 8.65777 14.6112 8.81757V11.9478C14.6112 12.67 14.1913 13.339 13.5427 13.6554L10.9577 14.9103C10.8919 14.9417 10.823 14.9573 10.7525 14.9573H10.7541ZM13.6728 9.54451L11.4794 10.5299C11.3243 10.6004 11.2241 10.7555 11.2241 10.9247V13.7369L13.1338 12.8094C13.4613 12.6496 13.6728 12.3128 13.6728 11.9478V9.54451Z"
			fill="white"
		/>
	</svg>
</template>
