import { toRefs, reactive, onBeforeUnmount } from 'vue';
import { type BreadCrumbItem } from '@/components/CommonBreadcrumbs.vue';

interface UseBreadcrumbsState {
	breadcrumbItems: BreadCrumbItem[];
	isBreadcrumbsVisible: boolean;
}

const DEFAULT_STATE: UseBreadcrumbsState = {
	breadcrumbItems: [],
	isBreadcrumbsVisible: false,
} as const;

const getDefaultState = () => ({ ...DEFAULT_STATE });

const state = reactive<UseBreadcrumbsState>(getDefaultState());

function resetBreadcrumbsState() {
	Object.assign(state, getDefaultState());
}

export function useBreadcrumbs() {
	const setBreadcrumbItems = (items: BreadCrumbItem[]) => {
		state.breadcrumbItems = items;
		state.isBreadcrumbsVisible = true;
	};

	onBeforeUnmount(() => {
		resetBreadcrumbsState();
	});

	return {
		...toRefs(state),
		setBreadcrumbItems,
	};
}
