import {
	getList,
	remove,
	removeMany,
	patch,
	post,
	isApiError,
} from './utilities/provider';
import type { AxiosRequestConfig } from 'axios';
import type {
	UserListCreateModel,
	UserListCreateResponse,
	UserListCreateUsersModel,
	UserList,
	GetUserListsRequestParams,
	GetUserListsResponse,
	GetUserListUsersResponse,
	UserListUpdateModel,
} from '@/types/userLists';
import { trackEvent, EVENTS } from '@/plugins/gainsight';

const RESOURCE = 'exchange/user-lists';

export function useUserListsService(config?: Omit<AxiosRequestConfig, 'key'>) {
	const createUserList = async (model: UserListCreateModel) => {
		const response = await post<UserListCreateResponse>(RESOURCE, model, <AxiosRequestConfig>{
			key: 'createUserList',
			...config,
		});
		if (isApiError(response)) throw response;
		trackEvent(EVENTS.USER_LIST_CREATED, {
			name: response.data.data.name,
			uuid: response.data.data.uuid,
		});
		return response.data;
	};

	const createUserListUsers = async (uuid: string, model: UserListCreateUsersModel) => {
		const response = await post<UserList>(`${RESOURCE}/${uuid}/users`, model, <AxiosRequestConfig>{
			key: 'createUserListUsers',
			...config,
		});
		if (isApiError(response)) throw response;
		return response.status;
	};

	const getUserLists = async (params?: GetUserListsRequestParams) => {
		const response = await getList<GetUserListsResponse>(RESOURCE, {
			key: 'getLists',
			params,
			...config,
		});
		if (isApiError(response)) throw response;
		return response.data;
	};

	const getUserListUsers = async (uuid: string) => {
		const response = await getList<GetUserListUsersResponse>(`${RESOURCE}/${uuid}/users`, {
			key: 'getUserListUsers',
			...config,
		});
		if (isApiError(response)) throw response;
		return response.data;
	};

	const deleteUserList = async (uuid: string) => {
		const response = await remove(`${RESOURCE}`, uuid, <AxiosRequestConfig>{
			key: 'deleteUserList',
			...config,
		});
		if (isApiError(response)) throw response;
		return response.status;
	};

	const deleteUserListUsers = async (uuid: string, userIds: string[]) => {
		const response = await removeMany(`${RESOURCE}/${uuid}/users`, {
			data: { user_uuids: userIds },
			key: 'deleteUserListUsers',
			...config,
		});
		if (isApiError(response)) throw response;
		return response.status;
	};

	const updateUserList = async (uuid: string, model: UserListUpdateModel) => {
		const response = await patch(`${RESOURCE}`, uuid, model, <AxiosRequestConfig>{
			key: 'updateUserList',
			...config,
		});
		if (isApiError(response)) throw response;
		return response.status;
	};

	return {
		getUserLists,
		deleteUserList,
		getUserListUsers,
		deleteUserListUsers,
		updateUserList,
		createUserList,
		createUserListUsers,
	};
}
