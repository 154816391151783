import { ref, computed, onBeforeUnmount, onBeforeMount } from 'vue';
import { useAuth } from '@okta/okta-vue';
import { get, isEmpty } from 'lodash-es';
import { useAuthStore } from '@/stores/authStore';
import { jwtDecode } from 'jwt-decode';
import type { TeamworksAuthRequest, OktaRegion, Region } from '@/types/auth';

interface UseTeamworksAuthOptions {
	onLogInSuccess?: () => void;
	onLogInError?: () => void;
}

const regions: Record<OktaRegion, Region> = {
	US: {
		awsRegion: 'us-east-1',
		redirect: 'https://app.inflcr.com',
	},
	AP: {
		awsRegion: 'ap-southeast-2',
		redirect: 'https://app.inflcr.au',
	},
};

export function useTeamworksAuth(options?: UseTeamworksAuthOptions) {
	const authStore = useAuthStore();
	const oktaAuth = useAuth();
	const teamworksToken = ref<Partial<TeamworksAuthRequest>>({});

	async function getTeamworksToken() {
		await oktaAuth.token.getWithRedirect({
			prompt: 'login consent',
		});
	}

	const isInitLoginTeamworks = computed(() => !isEmpty(teamworksToken.value));

	function resetLogInTeamWorks() {
		oktaAuth.clearStorage();
		teamworksToken.value = {};
	}

	function handleSuccess() {
		if (options?.onLogInSuccess !== undefined) {
			options.onLogInSuccess();
		}
	}

	function handleError() {
		resetLogInTeamWorks();
		if (options?.onLogInError !== undefined) {
			options.onLogInError();
		}
	}

	async function logInTeamworks() {
		try {
			const region: Region = regions[getRegionFromOktaToken(teamworksToken.value.token?.access_token ?? 'US')];
			if (region.awsRegion === import.meta.env.VITE_AWS_REGION) {
				await authStore.getTokenTeamworks(teamworksToken.value as TeamworksAuthRequest);
				handleSuccess();
			} else {
				window.location.replace(region.redirect);
			}
		} catch {
			handleError();
		}
	}

	const getRegionFromOktaToken = (token: string): OktaRegion => {
		const decodedToken = jwtDecode(token);
		const region: OktaRegion = get(decodedToken, 'region', 'US');
		return Object.keys(regions).includes(region) ? region : 'US';
	};

	const getExpiresIn = (expiresAt: number): number => {
		const now = new Date();
		return expiresAt - Math.floor(now.getTime() / 1000);
	};

	async function parseTeamworksToken(): Promise<void> {
		const expiresAt: number | null = get(
			await oktaAuth.tokenManager.get('accessToken'),
			'expiresAt',
			null,
		);
		const access_token = oktaAuth.getAccessToken();
		const id_token = oktaAuth.getIdToken();
		const refresh_token = oktaAuth.getRefreshToken();
		if (!access_token || !id_token || !refresh_token || !expiresAt) return;
		Object.assign(teamworksToken.value, {
			token: {
				access_token,
				id_token,
				refresh_token,
				expires_in: getExpiresIn(expiresAt),
			},
		});
	}

	async function initTeamworksAuth() {
		await parseTeamworksToken();
	}

	onBeforeMount(() => initTeamworksAuth());
	onBeforeUnmount(() => resetLogInTeamWorks());

	return {
		getTeamworksToken,
		isInitLoginTeamworks,
		logInTeamworks,
		teamworksToken,
		resetLogInTeamWorks,
	};
}
