<template>
	<span
		v-if="isMenuVisible"
		ref="contextMenu"
	>
		<slot
			name="activator"
		/>
		<VMenu
			ref="contextMenuMenu"
			:activator="`#${activatorId}`"
			:location="location"
			:theme="theme"
		>
			<VMenuList
				ref="contextMenuList"
				@keydown.down.capture="interceptMenuNavigation"
				@keydown.up.capture="interceptMenuNavigation"
				@keydown.enter.capture="toggleSubMenu"
			>
				<template v-if="visibleItems.length">
					<CommonContextMenuItem
						v-for="item, index in visibleItems"
						:key="index"
						ref=""
						:item="item"
						:index="index"
						:context="context"
					/>
				</template>
				<template v-if="visibleItemGroups.length">
					<div
						v-for="group, groupIndex in visibleItemGroups"
						:key="groupIndex"
						:ref="`contextMenuItemGroup-${groupIndex}`"
					>
						<VListItem
							v-if="group.label"
							:ref="`contextMenuItemGroup-${groupIndex}-label`"
							class="font-weight-medium mb-0 pb-0 text-body-2 text-medium-emphasis"
						>{{ group.label }}</VListItem>
						<CommonContextMenuItem
							v-for="item, index in resolveVisibleContextMenuItems(group.items)"
							:key="index"
							:ref="`contextMenuItemGroup-${groupIndex}-item-${index}`"
							:item="item"
							:index="index"
							:context="context"
						/>
						<VDivider
							v-if="group.divider && visibleItemGroups.length > 1"
							:ref="`contextMenuItemGroup-${groupIndex}-divider`"
						/>
					</div>
				</template>
			</VMenuList>
		</VMenu>
	</span>
</template>

<script setup lang="ts">
import { computed, ref, unref, type MaybeRef } from 'vue';
import type { VMenu } from 'vuetify/components/VMenu';
import { resolveVisibleContextMenuItems } from '@/utils/helpers';
import type { ContextMenuItem } from '@/components/CommonContextMenuItem.vue';
import CommonContextMenuItem from '@/components/CommonContextMenuItem.vue';


export interface ContextMenuItemGroup {
	divider?: boolean;
	label?: string;
	items: ContextMenuItem[];
	visible?: MaybeRef<boolean>;
}

const props = withDefaults(defineProps<{
	activatorId: string;
	itemGroups?: ContextMenuItemGroup[];
	items?: ContextMenuItem[];
	location?: VMenu['location'];
	theme?: string;
	context?: any;
}>(), {
	itemGroups: () => [],
	items: () => [],
	location: 'bottom',
	theme: undefined,
	context: undefined,
});


const contextMenuList = ref();

const interceptMenuNavigation = (e: KeyboardEvent) => {
	const target = e.target as HTMLElement;
	if (target.ariaHasPopup === 'menu') {
		e.stopPropagation();
		e.preventDefault();
		if (e.key === 'ArrowDown') {
			contextMenuList.value.focus('next');
		}
		if (e.key === 'ArrowUp') {
			contextMenuList.value.focus('prev');
		}
	}
};

const toggleSubMenu = (e: KeyboardEvent) => {
	const target = e.target as HTMLElement;
	if (target.ariaHasPopup === 'menu') {
		target.click();
	}
};

const visibleItems = computed<ContextMenuItem[]>(() => resolveVisibleContextMenuItems(props.items));

const visibleItemGroups = computed<ContextMenuItemGroup[]>(() => props.itemGroups.filter((g) => {
	if (unref(g.visible) === false) return false;
	if (!resolveVisibleContextMenuItems(g.items).length) return false;
	return true;
}));

const isMenuVisible = computed<boolean>(() => {
	return !!visibleItems.value.length || !!visibleItemGroups.value.length;
});

defineExpose({
	isMenuVisible,
});
</script>
