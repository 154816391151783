import {
	SingleBulletinApplicantProfile,
	type ApiCreateBulletinRequestModel,
	type ApiCreateBulletinApplicationRequestModel,
	type BulletinApplicant,
	type BulletinReach,
	type GetBulletinApplicantsRequestParams,
	type GetBulletinReachParams,
	type GetBulletinsRequestParams,
	type GetBulletinsResponse,
	type GetSingleBulletinApplicantResponse,
	type GetSingleBulletinResponse,
	type CreateBulletinApplicationResponse,
} from '@/types/bulletins';
import { EVENTS, trackEvent } from '@/plugins/gainsight';
import type { AxiosRequestConfig } from 'axios';
import {
	getList,
	getSingle,
	isApiError,
	patch,
	post,
	remove,
} from './utilities/provider';

const RESOURCE = 'exchange/bulletins';

export function useBulletinsService(config?: Omit<AxiosRequestConfig, 'key'>) {
	const getBulletins = async (params?: GetBulletinsRequestParams) => {
		const response = await getList<GetBulletinsResponse>(RESOURCE, {
			key: 'getBulletins',
			params,
			...config,
		});
		if (isApiError(response)) throw response;
		return response.data;
	};

	const getBulletin = async (uuid: string) => {
		const response = await getSingle<GetSingleBulletinResponse>(RESOURCE, uuid, {
			key: 'getBulletin',
			...config,
		});
		if (isApiError(response)) throw response;
		return response.data.data;
	};

	const getBulletinAudienceReach = async (params?: GetBulletinReachParams) => {
		const response = await getSingle<BulletinReach>(`${RESOURCE}/audience-reach`, '', {
			key: 'getBulletinAudienceReach',
			params,
			...config,
		});
		if (isApiError(response)) throw response;
		return response.data;
	};

	const createBulletin = async (data: ApiCreateBulletinRequestModel) => {
		const response = await post<GetSingleBulletinResponse>(RESOURCE, data, {
			key: 'createBulletin',
			...config,
		});
		if (isApiError(response)) throw response;

		const { type, name } = response.data.data;
		trackEvent(EVENTS.EXCHANGE_BULLETIN_CREATED, { type, name });

		return response.data.data;
	};

	const createBulletinApplication = async (uuid: string, data: ApiCreateBulletinApplicationRequestModel) => {
		const response = await post<CreateBulletinApplicationResponse>(`${RESOURCE}/${uuid}/applications`, data, {
			key: 'createBulletinApplication',
			...config,
		});
		if (isApiError(response)) throw response;

		return response.data.data;
	};

	const updateBulletin = async (uuid: string, data: Partial<ApiCreateBulletinRequestModel>) => {
		const response = await patch(RESOURCE, uuid, data, {
			key: 'updateGallery',
			...config,
		});
		if (isApiError(response)) throw response;
		return response.status;
	};

	const archiveBulletin = async (bulletinUuid: string) => {
		const response = await remove(RESOURCE, bulletinUuid, {
			key: 'archiveBulletin',
			...config,
		});
		if (isApiError(response)) throw response;
		return response.status;
	};

	const renewBulletin = async (bulletinUuid: string) => {
		const response = await patch(`${RESOURCE}/${bulletinUuid}/renew`, '', {
			key: 'renewBulletin',
			...config,
		});
		if (isApiError(response)) throw response;
		return response.status;
	};

	const getBulletinApplicants = async (uuid: string, params?: GetBulletinApplicantsRequestParams) => {
		const response = await getList<BulletinApplicant>(`${RESOURCE}/${uuid}/applications`, {
			key: 'getBulletinApplicants',
			params,
			...config,
		});
		if (isApiError(response)) throw response;
		return response.data;
	};

	const getBulletinApplicant = async (bulletinUuid: string, uuid: string) => {
		const response = await getSingle<GetSingleBulletinApplicantResponse>(`${RESOURCE}/${bulletinUuid}/applications`, uuid, {
			key: 'getBulletinApplicant',
			...config,
		});
		if (isApiError(response)) throw response;
		return new SingleBulletinApplicantProfile(response.data.data);
	};

	return {
		getBulletins,
		getBulletin,
		getBulletinAudienceReach,
		getBulletinApplicants,
		getBulletinApplicant,
		createBulletin,
		createBulletinApplication,
		updateBulletin,
		archiveBulletin,
		renewBulletin,
	};
}
