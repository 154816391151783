<template>
	<svg
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M18.625 13.8309V5.91612C18.625 5.21582 18.2521 4.57036 17.6458 4.21943L10.7917 0.262025C10.1854 -0.0873417 9.43963 -0.0873417 8.83333 0.262025L1.97917 4.21943C1.37287 4.56879 1 5.21582 1 5.91612V13.8309C1 14.5312 1.37287 15.1767 1.97917 15.5276L8.83333 19.485C9.43963 19.8344 10.1854 19.8344 10.7917 19.485L17.6458 15.5276C18.2521 15.1783 18.625 14.5312 18.625 13.8309Z"
			fill="#009657"
		/>
		<path
			d="M5.32716 9.87352C5.32716 7.40132 7.33876 5.38972 9.81252 5.38972C9.90652 5.38972 10.0005 5.39285 10.093 5.39912L10.2496 5.26752L10.2559 5.26125L10.7494 4.84608L10.9014 4.71918C10.5504 4.64555 10.1854 4.60638 9.81252 4.60638C6.90322 4.60638 4.54382 6.96422 4.54382 9.87352C4.54382 11.5796 5.35536 13.0962 6.61339 14.0581H6.61496L6.64316 13.9813L6.71209 13.7949L6.87189 13.3703L6.90166 13.2889V13.2826C5.93972 12.4617 5.32716 11.2381 5.32716 9.87352Z"
			fill="#5DD1A4"
		/>
		<path
			d="M12.808 5.54169L12.7234 5.76729L12.7187 5.78139L12.714 5.79235L12.6544 5.95372L12.5228 6.30465C13.5991 7.12402 14.2963 8.41809 14.2963 9.87352C14.2963 12.3457 12.2847 14.3573 9.8125 14.3573C9.6386 14.3573 9.46783 14.3479 9.3002 14.3276L9.1122 14.4842L9.03387 14.55L8.61557 14.9025L8.52313 14.9809C8.93517 15.0858 9.36757 15.1407 9.8125 15.1407C12.7202 15.1407 15.0796 12.7828 15.0796 9.87352C15.0796 8.07812 14.1804 6.49109 12.808 5.54169Z"
			fill="white"
		/>
		<path
			d="M13.0132 10.3858L12.1343 11.1253L10.3483 10.7712C10.3483 10.7712 10.3358 10.7696 10.3326 10.7696C10.2637 10.7696 10.2324 10.8621 10.2935 10.9028L11.4951 11.6626L9.3566 13.4612L8.31007 13.2559C8.31007 13.2559 8.2991 13.2544 8.2944 13.2544C8.22547 13.2544 8.1957 13.3468 8.25837 13.386L8.93517 13.8137L8.70644 14.0064L8.62967 14.0722L8.51844 14.1662L8.2145 14.4215L8.03904 14.5688L7.96854 14.6283L7.82127 14.7521L7.31367 15.1767L7.26197 15.2205C7.21967 15.255 7.17424 15.2707 7.13037 15.2707C6.9972 15.2707 6.87814 15.1391 6.93454 14.9887L7.1476 14.4184L7.20087 14.2774L7.23064 14.2006L7.29957 14.0142L7.42804 13.6695L7.45937 13.5881L7.48914 13.5097L8.15027 11.7441L9.4036 11.9133C9.4036 11.9133 9.41144 11.9149 9.41457 11.9149C9.48507 11.9149 9.5164 11.8162 9.44904 11.7801L8.36177 11.1817L8.69547 10.2918L6.4614 9.80771C6.2546 9.76228 6.1888 9.49908 6.35174 9.36122L10.6507 5.74848L10.6585 5.74222L10.9139 5.52602L11.152 5.32548L11.3149 5.18918L11.3259 5.17978L11.6267 4.92755L12.0528 4.57035L12.1045 4.52805C12.1453 4.49202 12.1923 4.47635 12.2377 4.47635C12.3677 4.47635 12.4868 4.60952 12.432 4.75835L12.2628 5.21112L12.1374 5.54795L12.1327 5.55892L12.0669 5.73282L11.9855 5.95372L11.8774 6.24042L11.8742 6.24668L10.671 9.45678L12.902 9.93931C13.1103 9.98475 13.1746 10.2495 13.0132 10.3858Z"
			fill="white"
		/>
		<path
			d="M13.2717 8.81131L12.8252 9.12934L12.3896 9.43954L12.2972 9.50691L12.2862 9.51474L11.0862 9.25154V9.24371L11.8726 7.14908L11.9024 7.29008L12.0465 8.03268L12.1562 8.59668L11.6831 8.94134L13.2027 8.64055C13.2027 8.64055 13.2137 8.63898 13.2184 8.63898C13.3061 8.63898 13.35 8.75804 13.2732 8.81288L13.2717 8.81131Z"
			fill="#5DD1A4"
		/>
	</svg>
</template>
