import { useSchoolsService } from '@/api';
import type { School } from '@/types/schools';
import { sortBy } from 'lodash-es';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

export const useSchoolsStore = defineStore('schools', () => {
	const schoolsRaw = ref<School[]>();
	const isFetchingSchools = ref(false);
	const fetchPromise = ref<Promise<void> | null>(null);

	const schools = computed<School[]>(() => sortBy(schoolsRaw.value, 'name'));

	const fetchSchools = async () => {
		if (schoolsRaw.value) return;
		if (fetchPromise.value) return fetchPromise.value;

		isFetchingSchools.value = true;

		fetchPromise.value = (async () => {
			try {
				schoolsRaw.value = await useSchoolsService().getSchools({ all: 1 });
			} catch (error) {
				console.error(error);
			} finally {
				isFetchingSchools.value = false;
				fetchPromise.value = null;
			}
		})();

		return fetchPromise.value;
	};

	const $reset = () => {
		schoolsRaw.value = undefined;
		isFetchingSchools.value = false;
		fetchPromise.value = null;
	};

	return {
		schools,
		isFetchingSchools,
		fetchSchools,
		$reset,
	};
});
