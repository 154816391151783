<template>
	<Grid
		:length="length"
		:page-provider="pageProvider"
		:page-size="pageSize"
		:page-provider-debounce-time="10"
		class="grid"
		data-testid="elementSubLayoutTile"
	>
		<template #probe>
			<slot name="probe">
				<div class="item" />
			</slot>
		</template>
		<template #placeholder="{ style }">
			<div
				:class="slots.placeholder ? '' : 'item'"
				:style="style"
			>
				<slot name="placeholder">
					<VSheet
						class="item"
						height="100%"
						width="100%"
						rounded
						color="grey lighten-3"
					/>
				</slot>
			</div>
		</template>
		<template #default="{ item, index, style }">
			<div
				:class="slots.probe ? '' : 'item'"
				:style="style"
			>
				<slot
					:item="item"
					:index="index"
				/>
			</div>
		</template>
	</Grid>
</template>

<script setup lang="ts">
import Grid from 'vue-virtual-scroll-grid';
import { useSlots } from 'vue';

defineProps<{
	length: number;
	pageProvider: (pageNumber: number, pageSize: number) => Promise<unknown[]>;
	pageSize: number;
}>();

const slots = useSlots();

</script>

<style lang="scss" scoped>
@use 'sass:map';
@use '@/styles/settings.scss';

$cols-per-bp: (
	sm-and-up: 2,
	md-and-up: 3,
	lg-and-up: 4,
	xl-and-up: 5,
);
.grid {
	display: grid;
	grid-gap: settings.$grid-gutter;
	grid-template-columns: repeat(1, 1fr);

	@each $bp, $cols in $cols-per-bp {
		@media #{map.get(settings.$display-breakpoints, $bp)} {
			grid-template-columns: repeat($cols, minmax(0,1fr));
		}
	}
}

.item {
  aspect-ratio: 1;
  overflow: hidden;
}
</style>
