import { OktaAuth } from '@okta/okta-auth-js';
import type { OktaVueOptions } from '@okta/okta-vue';

const oktaAuth = new OktaAuth({
	issuer: import.meta.env.VITE_OKTA_CLIENT_ORGURL + '/oauth2/default',
	clientId: import.meta.env.VITE_OKTA_CLIENT_ID,
	redirectUri: window.location.origin + '/login/callback',
	scopes: ['openid', 'profile', 'email', 'offline_access'],
});

export const oktaConfig: OktaVueOptions = { oktaAuth };
