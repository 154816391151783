<template>
	<VProgressLinear />
</template>

<script setup lang="ts">
import { onBeforeMount } from 'vue';
import { useAuth } from '@okta/okta-vue';
import { useRouter } from 'vue-router';

const oktaAuth = useAuth();
const router = useRouter();
const UNAUTHORIZED_REDIRECT = 'https://www.teamworksapp.com/exceptions/no_app_access.html';

const handleLogin = async () => {
	if (!oktaAuth.isLoginRedirect()) router.replace({ name: 'LoginIndex' });

	try {
		await oktaAuth.handleLoginRedirect();
	} catch (e) {
		const errorStr = (e as object).toString();
		const oAuthError = errorStr.slice(errorStr.indexOf('{'));
		const { error_type } = JSON.parse(oAuthError);

		if (error_type === 'UNAUTHORIZED_USER') {
			window.location.replace(UNAUTHORIZED_REDIRECT);
		}

		router.push({ name: 'LoginIndex' });
	}
};

onBeforeMount(handleLogin);
</script>
