<template>
	<svg
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M18.6234 6.13205V13.2096C18.6234 14.1165 18.1393 14.9547 17.3544 15.4113L11.1348 19.0111C10.3467 19.4677 9.37697 19.4692 8.58893 19.0189L2.27997 15.4082C1.4888 14.9563 1 14.1134 1 13.2017V6.14143C1 5.22976 1.4888 4.3869 2.28467 3.93342L8.5905 0.335235C9.37853 -0.113561 10.3467 -0.111997 11.1301 0.34149L17.3497 3.93029C18.1378 4.38534 18.6219 5.22507 18.6219 6.13361L18.6234 6.13205Z"
			fill="#2CC3F2"
		/>
		<path
			d="M15.2739 12.6951L14.7491 13.2174C14.688 13.2784 14.5893 13.2784 14.5266 13.2174L11.7959 10.4933C11.7348 10.4324 11.6361 10.4324 11.5734 10.4933L11.5155 10.5496C11.4528 10.6106 11.4528 10.7107 11.5155 10.7733L13.8044 13.0579C13.867 13.1189 13.867 13.2174 13.8044 13.2799L13.2811 13.8022C13.22 13.8632 13.1197 13.8632 13.0586 13.8022L10.7682 11.516C10.7071 11.4551 10.6099 11.4551 10.5488 11.516L10.4893 11.5755C10.4282 11.6364 10.4266 11.7365 10.4893 11.7975L12.3348 13.6396C12.3959 13.7006 12.3959 13.8007 12.3348 13.8617L11.8116 14.384C11.7505 14.4465 11.6502 14.4465 11.5891 14.384L9.74199 12.5434C9.68089 12.4824 9.58219 12.4824 9.52109 12.5434L9.46313 12.5997C9.40046 12.6607 9.40046 12.7608 9.46313 12.8233L10.8669 14.2229C10.928 14.2839 10.928 14.384 10.8669 14.4449L10.342 14.9672C10.2809 15.0282 10.1822 15.0282 10.1196 14.9672L8.80356 13.6521L8.91323 13.5427C9.04796 13.4082 9.12159 13.2205 9.11219 13.0282C9.10436 12.8515 9.03073 12.6842 8.90383 12.5575L8.37899 12.0336V12.0133C8.46359 11.8929 8.51059 11.7428 8.50746 11.5911C8.50119 11.4113 8.42756 11.2408 8.29909 11.1126L7.77426 10.5872C7.63953 10.4543 7.46093 10.3792 7.27293 10.3792C7.13193 10.3792 6.99876 10.4214 6.88439 10.498L6.34703 9.96168C6.21543 9.83032 6.03839 9.75682 5.85039 9.75682C5.71879 9.75682 5.59189 9.79279 5.48223 9.86003C5.47753 9.85378 5.47126 9.84752 5.46499 9.84127L4.94016 9.31585C4.84616 9.22359 4.73023 9.15947 4.60333 9.13133L4.32446 8.49019C4.25866 8.3432 4.29156 8.17118 4.40593 8.05703L7.08649 5.3799C7.17736 5.28763 7.30739 5.24541 7.43586 5.26731L8.18316 5.40022L6.98309 6.61369C6.71206 6.8936 6.71206 7.3252 6.97526 7.59885L7.60506 8.25094C7.73353 8.38073 7.89646 8.45579 8.07193 8.46673C8.08603 8.46673 8.10013 8.46673 8.11423 8.46673C8.25836 8.46673 8.51686 8.34007 8.51686 8.34007L9.79056 7.74115C9.92999 7.67548 10.0961 7.69737 10.2136 7.79901L13.0054 10.2056L15.277 12.4731C15.3381 12.534 15.3381 12.6341 15.277 12.6951H15.2739Z"
			fill="white"
		/>
		<path
			d="M6.47546 11.097L6.35796 11.2142L5.5903 11.9789C5.55896 12.0118 5.51666 12.0274 5.47436 12.0274C5.44146 12.0274 5.40856 12.018 5.38036 11.9961C5.37566 11.993 5.3694 11.9883 5.3647 11.9836L4.83986 11.4598C4.83986 11.4598 4.8289 11.4488 4.82576 11.4426C4.78033 11.3816 4.78816 11.2909 4.84456 11.2346L5.72973 10.3511C5.76106 10.3198 5.80336 10.3026 5.84566 10.3026C5.88483 10.3026 5.924 10.3182 5.95376 10.3479L6.48016 10.8718C6.48016 10.8718 6.48016 10.8718 6.4833 10.8749C6.54126 10.9359 6.53656 11.0344 6.47546 11.097Z"
			fill="white"
		/>
		<path
			d="M7.91206 11.7115L6.60703 13.0141C6.57883 13.0422 6.54279 13.0563 6.50519 13.0563C6.47073 13.0563 6.43626 13.0454 6.40806 13.0219C6.40179 13.0172 6.39709 13.0125 6.39239 13.0078L5.86756 12.484C5.86756 12.484 5.85659 12.4715 5.85346 12.4652C5.80646 12.4042 5.80959 12.3214 5.86129 12.2697L6.63836 11.4941L7.06606 11.0672L7.16633 10.9671C7.19453 10.939 7.23056 10.9249 7.26816 10.9249C7.30889 10.9249 7.34963 10.9421 7.38096 10.9734L7.90579 11.4973C7.96689 11.5582 7.97003 11.6536 7.91206 11.7115Z"
			fill="white"
		/>
		<path
			d="M5.07174 10.4464L4.55944 10.9578C4.52967 10.9891 4.48894 11.0031 4.44978 11.0031C4.41688 11.0031 4.38398 10.9937 4.35734 10.9734C4.35108 10.9703 4.34481 10.964 4.33854 10.9578L3.81371 10.4339C3.75261 10.3729 3.75261 10.2744 3.81371 10.2134L4.32601 9.70209C4.35577 9.67081 4.39651 9.65674 4.43724 9.65674C4.47798 9.65674 4.51714 9.67081 4.54691 9.70209L5.07174 10.2259C5.07174 10.2259 5.07487 10.2291 5.07487 10.2306C5.13284 10.2916 5.13127 10.387 5.07174 10.4464Z"
			fill="white"
		/>
		<path
			d="M8.52002 13.1549L8.41193 13.2643L7.63173 14.0431C7.60509 14.0697 7.57063 14.0822 7.53459 14.0822C7.49386 14.0822 7.45156 14.065 7.41866 14.0337L6.89383 13.5098C6.89383 13.5098 6.88443 13.5005 6.88129 13.4942C6.83273 13.4348 6.83429 13.3488 6.88443 13.2972L7.66463 12.5184L7.77429 12.4105C7.80093 12.3839 7.83539 12.3714 7.87143 12.3714C7.91059 12.3714 7.95133 12.3855 7.98266 12.4168C7.98266 12.4168 7.9837 12.4178 7.98579 12.4199L8.51063 12.9438C8.57173 13.0047 8.57642 13.0986 8.52002 13.1549Z"
			fill="white"
		/>
		<path
			d="M14.9183 10.5809L14.7491 10.7795C14.7178 10.8155 14.6723 10.8343 14.6285 10.8343H14.6191C14.5815 10.8343 14.5407 10.8139 14.511 10.7827L10.5348 7.35492L10.2998 7.15164C10.1823 7.05156 10.0162 7.0281 9.87675 7.09378L8.32262 7.82561C8.17065 7.89755 7.99049 7.86314 7.87455 7.74273L7.36852 7.21888C7.30899 7.15789 7.31055 7.05938 7.37009 6.99683L8.83492 5.51596L9.31902 5.02651L9.38169 4.96239C9.53209 4.81071 9.73419 4.73096 9.94099 4.73096C9.95665 4.73096 9.97232 4.73096 9.98799 4.73096C10.0601 4.73565 10.1353 4.74816 10.2058 4.77474L13.3438 5.89595C13.5772 5.97883 13.7558 6.16804 13.8295 6.40417L14.9935 10.2119C15.0342 10.3386 15.0045 10.4777 14.9183 10.5794V10.5809Z"
			fill="white"
		/>
		<path
			d="M13.1276 10.3307L8.51373 8.34009L9.78743 7.74117C9.92687 7.67549 10.0929 7.69895 10.2104 7.79903L12.927 10.14L13.0022 10.2056L13.1276 10.3307Z"
			fill="#74DFFF"
		/>
	</svg>
</template>
