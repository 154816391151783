import { useUserStore } from '@/stores/userStore';
import type { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
	{
		path: '/compliance',
		beforeEnter: [canAccessComplianceRoutes],
		children: [
			{
				path: 'transactions',
				name: 'ComplianceTransactionsParent',
				component: () => import('@/views/ExchangeTransactionsParent.vue'),
				beforeEnter: [canAccessTransactions],
				children: [
					{
						path: '',
						name: 'ComplianceTransactions',
						component: () => import('@/views/ExchangeTransactions.vue'),
						meta: {
							requiresAuth: true,
							layout: 'LayoutAuthenticated',
							title: 'pageExchangeTransactions.metaTitle',
							requiredAccess: 'canAccessTransactions',
							profileSwitchRedirect: () => {
								const userStore = useUserStore();
								return userStore.profileAccess.isComplianceOfficer
									? 'ComplianceTransactions'
									: 'ExchangeTransactions';
							},
						},
					},
				],
			},
			{
				path: 'transactions/:transactionUuid',
				name: 'ComplianceTransaction',
				component: () => import('@/views/ExchangeTransaction.vue'),
				props: true,
				meta: {
					requiresAuth: true,
					layout: 'LayoutAuthenticated',
					title: 'pageTransaction.metaTitle',
					requiredAccess: 'canAccessTransactions',
					profileSwitchRedirect: 'ComplianceTransactions',
				},
				beforeEnter: [canAccessTransactions],
			},
			{
				path: 'agreements',
				name: 'ComplianceAgreements',
				redirect: { name: 'ComplianceTransactions' },
			},
			{
				path: 'agreements/:agreementUuid',
				name: 'ComplianceAgreement',
				component: () => import('@/views/ExchangeAgreement.vue'),
				props: true,
				meta: {
					requiresAuth: true,
					layout: 'LayoutAuthenticated',
					title: 'pageAgreement.metaTitle',
					requiredAccess: 'canAccessTransactions',
				},
				beforeEnter: [canAccessTransactions],
			},
		],
	},
];

function canAccessComplianceRoutes() {
	const userStore = useUserStore();
	if (!userStore.profileAccess.isComplianceOfficer) {
		return { name: 'NotFound' };
	}
}

function canAccessTransactions() {
	const userStore = useUserStore();
	if (!userStore.canAccessTransactions) {
		return { name: 'NotFound' };
	}
}

export default routes;
