import { h, ref, computed, type Component, type ComponentOptions, type ComponentPropsOptions } from 'vue';
import SubLayoutParent from '@/components/SubLayoutParent.vue';
import SubLayoutTile from '@/components/SubLayoutTile.vue';
import SubLayoutVirtualTile from '@/components/SubLayoutVirtualTile.vue';
import SubLayoutList from '@/components/SubLayoutList.vue';
import SubLayoutListB from '@/components/SubLayoutListB.vue';

export const LAYOUTS = {
	TILE: 'tile',
	LIST: 'list',
	VIRTUAL_TILE: 'virtualTile',
	LIST_B: 'listB',
} as const;

export type Layout = typeof LAYOUTS[keyof typeof LAYOUTS];

export type LayoutComponents = Partial<Record<Layout, Component>>;

const layoutComponents: LayoutComponents = {
	tile: SubLayoutTile,
	list: SubLayoutList,
	virtualTile: SubLayoutVirtualTile,
	listB: SubLayoutListB,
};

const layoutFactory = (
	LayoutComponent: Component,
	layoutComponents: LayoutComponents,
	defaultLayout: Layout,
) => {
	const layout = ref(defaultLayout);

	const currentLayoutComponent = computed(() => layoutComponents[layout.value]);

	const setSubLayout = (layoutType: Layout): void => {
		layout.value = layoutType;
	};

	const useSubLayouts = (initialLayout?: Layout) => {
		if (initialLayout) setSubLayout(initialLayout);

		return {
			currentLayoutComponent,
			layout,
			setSubLayout,
		};
	};

	const LayoutComponentWithUseProp: Component = (
		props: ComponentPropsOptions,
		{ attrs, slots }: ComponentOptions,
	) => {
		const options = { useSubLayouts, ...props, ...attrs };
		return h(LayoutComponent, options, slots);
	};

	return { SubLayoutSwitcher: LayoutComponentWithUseProp, useSubLayouts };
};

const { SubLayoutSwitcher, useSubLayouts } = layoutFactory(
	SubLayoutParent,
	layoutComponents,
	LAYOUTS.VIRTUAL_TILE,
);

export { SubLayoutSwitcher, useSubLayouts };
