<template>
	<component :is="'style'">
		.v-theme--INFLCRLight, .v-theme--INFLCRDark{
		{{ navbarTopColor ? `--v-theme-axle-navbar-top: ${navbarTopColor};` : undefined }}
		{{ textOnNavbarTopColor ? `--v-theme-on-axle-navbar-top: ${textOnNavbarTopColor};` : undefined }}
		{{ navbarBottomColor ? `--v-theme-axle-navbar-bottom: ${navbarBottomColor};` : undefined }}
		{{ textOnNavbarBottomColor ? `--v-theme-on-axle-navbar-bottom: ${textOnNavbarBottomColor};` : undefined }}
		}
	</component>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/userStore';
import { hexToRgbValues, rgbValuesToHex, textOnColorRgbValues } from '@/utils/colorContrast';

const { appSwitcherProfile } = storeToRefs(useUserStore());

const navbarTopColor = computed(() => {
	if (!appSwitcherProfile) return undefined;
	return hexToRgbValues(appSwitcherProfile.value?.navbar_top_color ?? undefined);
});

const textOnNavbarTopColor = computed(() => {
	return navbarTopColor.value && textOnColorRgbValues(rgbValuesToHex(navbarTopColor.value));
});

const navbarBottomColor = computed(() => {
	if (!appSwitcherProfile) return undefined;
	return hexToRgbValues(appSwitcherProfile.value?.navbar_bottom_color ?? undefined);
});

const textOnNavbarBottomColor = computed(() => {
	return navbarBottomColor.value && textOnColorRgbValues(rgbValuesToHex(navbarBottomColor.value));
});
</script>
