<template>
	<div class="login-page-header mb-8">
		<h1 class="text-h1">
			<slot name="title" />
		</h1>
		<p class="text-subtitle-1 text-medium-emphasis">
			<slot name="subtitle" />
		</p>
	</div>
</template>
