<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			fill="currentcolor"
			fill-rule="evenodd"
			d="M12.727 3.207V5.14c0 1.15.732 2.122 1.813 2.406A1.499 1.499 0 0 1 15.637 9v8.793c0 .113.09.207.2.207h1.053c.11 0 .201-.094.201-.207V9c0-1.406-.95-2.594-2.22-2.915a.917.917 0 0 1-.689-.904V4.5h.019c2.39 0 4.345 2.016 4.345 4.48v10.312c0 .113-.091.207-.201.207h-3.962c-.11 0-.201-.093-.201-.207v-8.649a.205.205 0 0 0-.2-.207h-1.054c-.11 0-.2.094-.2.207v8.907c-.002.798.63 1.449 1.404 1.449h4.464c.773 0 1.404-.65 1.404-1.449V9c0-3.3-2.618-6-5.818-6h-1.254c-.11 0-.2.093-.2.207Zm-2.708 7.23h1.053c.11 0 .201.094.201.207v8.907c0 .798-.632 1.449-1.405 1.449H5.405C4.63 21 4 20.35 4 19.551V4.45C4 3.65 4.631 3 5.405 3h5.667c.11 0 .201.093.201.207V5.14c0 1.15-.732 2.122-1.812 2.406A1.5 1.5 0 0 0 8.364 9v8.792c0 .114-.09.207-.2.207H7.11c-.11 0-.2-.093-.2-.207V9c0-1.4.955-2.592 2.222-2.914a.917.917 0 0 0 .686-.904V4.5H5.656c-.111 0-.201.093-.201.207v14.586c0 .113.09.207.2.207h3.963c.11 0 .2-.093.2-.207v-8.649c0-.114.09-.207.201-.207Z"
			clip-rule="evenodd"
		/>
	</svg>
</template>
