<template>
	<VList
		v-if="hasItems"
		ref="appSwitcher"
		class="px-5 mb-md-2"
		density="compact"
		nav
	>
		<VListItem
			class="text-decoration-none pl-2"
			prepend-icon="$twi-app-switcher"
			:title="t('navigationMenu.teamworksApps')"
			data-testid="navigationAppSwitcherMenu"
			link
		>
			<VMenu
				ref="appSwitcherMenu"
				v-model="isMenuActive"
				theme="INFLCRLight"
				activator="parent"
				location="top"
			>
				<VCard
					border
					class="pa-3"
				>
					<VList
						class="pa-0"
						density="compact"
						nav
					>
						<TheNavigationMenuItem
							v-for="(item, index) in appSwitcherItems"
							:key="index"
							:nav-item="item"
							:is-expanded="isExpanded"
							:tooltip-visible="false"
						/>
					</VList>
				</VCard>
			</VMenu>
			<VTooltip
				v-if="!isExpanded"
				activator="parent"
				location="right"
			>
				{{ t('navigationMenu.teamworksApps') }}
			</VTooltip>
		</VListItem>
	</VList>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import { usePrimaryNavigation } from '@/composables';
import { useI18n } from 'vue-i18n';
import TheNavigationMenuItem from '@/components/TheNavigationMenuItem.vue';

defineProps<{
	isExpanded: boolean;
}>();
const { t } = useI18n();

const isMenuActive = ref(false);

const { appSwitcherItems } = usePrimaryNavigation();

const hasItems = computed(() => {
	// Hide the app switcher if there is only one item (the current app, INFLUENCER)
	return appSwitcherItems.value.length > 1;
});
</script>
