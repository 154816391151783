<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			fill="currentColor"
			d="m6.126 8.348 4.57 7.22a.921.921 0 0 0 .343.316.972.972 0 0 0 .922 0 .921.921 0 0 0 .342-.316l4.571-7.22a.87.87 0 0 0 .003-.894.92.92 0 0 0-.34-.33A.972.972 0 0 0 16.07 7H6.929a.972.972 0 0 0-.466.124.92.92 0 0 0-.34.33.868.868 0 0 0 .003.894Z"
		/>
	</svg>
</template>
