import { ref, reactive, watch, defineAsyncComponent, type MaybeRef, unref } from 'vue';
import { useExchangeAthletesStore } from '@/stores/exchangeAthletesStore';
import { useRouter } from 'vue-router';

const ExchangeUserListCreateDrawer = defineAsyncComponent(() => import('@/components/exchange/ExchangeUserListCreateDrawer.vue'));
interface UseExchangeUserListCreateDrawerState {
	isOpen: boolean;
	selectedUsers: string[];
}

const DEFAULT_STATE: UseExchangeUserListCreateDrawerState = {
	isOpen: false,
	selectedUsers: [],
} as const;

const getDefaultState = () => ({ ...DEFAULT_STATE });

const state = reactive<UseExchangeUserListCreateDrawerState>(getDefaultState());

function resetState() {
	Object.assign(state, getDefaultState());
}

const isRendered = ref<boolean>(false);


watch(() => state.isOpen, (isOpen) => {
	if (isOpen && !isRendered.value) isRendered.value = true;
	if (!isOpen) resetState();
});



export function useUserLists(selectedUsers?: MaybeRef<string[]>) {
	const router = useRouter();
	const exchangeAthletesStore = useExchangeAthletesStore();

	const open = (scopedUsers?: MaybeRef<MaybeArray<string>>) => {
		const users = unref(scopedUsers) ? unref(scopedUsers) : unref(selectedUsers);
		if (users) state.selectedUsers = Array.isArray(users) ? users : [users];
		state.isOpen = true;
	};

	const viewUserListFilter = (listUuids: string[]) => {
		exchangeAthletesStore.setAthletesFilters({
			...exchangeAthletesStore.athletesFilters,
			list: listUuids,
		});
		router.push({ name: 'ExchangeAthletesBrowse' });
	};

	return {
		state,
		isRendered,
		resetState,
		open,
		viewUserListFilter,
		ExchangeUserListCreateDrawer,
	};
}
