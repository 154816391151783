import type { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
	{
		path: '/disclose-nil-activity',
		name: 'DiscloseNilActivity',
		component: () => import('@/views/DiscloseNilActivity.vue'),
		meta: {
			layout: 'LayoutAuthenticated',
			requiresAuth: true,
			title: 'pageDiscloseNilActivity.metaTitle',
			requiredAccess: 'hasTransactionsImportWrite',
		},
	},
	{
		path: '/ncaa-service-providers',
		name: 'ServiceProvidersDashboard',
		component: () => import('@/views/ServiceProvidersDashboard.vue'),
		meta: {
			layout: 'LayoutAuthenticated',
			requiresAuth: true,
			title: 'pageServiceProvidersDashboard.metaTitle',
			requiredAccess: 'hasTransactionsImportWrite',
		},
	},
	{
		path: '/nil-assist-dashboard',
		name: 'NilAssistDashboard',
		component: () => import('@/views/NilAssistDashboard.vue'),
		meta: {
			layout: 'LayoutAuthenticated',
			requiresAuth: true,
			title: 'pageNilAssistDashboard.metaTitle',
			requiredAccess: 'isComplianceOfficer',
		},
	},
	{
		path: '/:username',
		name: 'AthletePublicProfile',
		component: () => import('@/views/AthletePublicProfile.vue'),
		props: true,
		meta: {
			layout: 'LayoutDefault',
			requiresAuth: false,
			title: 'pageAthletePublicProfile.metaTitle',
		},
	},
];

export default routes;
