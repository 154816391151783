<template>
	<component :is="'style'">
		.v-theme--INFLCRLight, .v-theme--INFLCRDark{
		{{ primaryColor ? `--v-theme-school-primary: ${primaryColor}` : '' }};
		{{ textOnPrimaryColor ? `--v-theme-on-school-primary: ${textOnPrimaryColor}` : '' }};
		{{ backgroundColor ? `--v-theme-school-background: ${backgroundColor}` : '' }};
		{{ textOnBackgroundColor ? `--v-theme-on-school-background: ${textOnBackgroundColor}` : '' }};
		{{ headerBackgroundColor ? `--v-theme-school-header-background: ${headerBackgroundColor}` : '' }};
		{{ textOnHeaderBackgroundColor ? `--v-theme-on-school-header-background: ${textOnHeaderBackgroundColor}` : '' }};
		}
	</component>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useExchangeSchoolSiteStore } from '@/stores/exchangeSchoolSiteStore';
import { hexToRgbValues, rgbValuesToHex, textOnColorRgbValues } from '@/utils/colorContrast';

const { schoolSite } = storeToRefs(useExchangeSchoolSiteStore());


const primaryColor = computed(() => {
	return hexToRgbValues(schoolSite.value?.primaryColor);
});
const textOnPrimaryColor = computed(() => {
	return primaryColor.value && textOnColorRgbValues(rgbValuesToHex(primaryColor.value));
});

const backgroundColor = computed(() => {
	return hexToRgbValues(schoolSite.value?.backgroundColor);
});
const textOnBackgroundColor = computed(() => {
	return backgroundColor.value && textOnColorRgbValues(rgbValuesToHex(backgroundColor.value));
});

const headerBackgroundColor = computed(() => {
	return hexToRgbValues(schoolSite.value?.headerBackgroundColor);
});
const textOnHeaderBackgroundColor = computed(() => {
	return headerBackgroundColor.value && textOnColorRgbValues(rgbValuesToHex(headerBackgroundColor.value));
});
</script>
