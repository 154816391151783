<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			fill="currentColor"
			d="M20.5714 2H3.4241C2.6384 2 2 2.6473 2 3.442v17.116C2 21.3527 2.6384 22 3.4241 22h17.1473C21.3571 22 22 21.3527 22 20.558V3.442C22 2.6473 21.3571 2 20.5714 2ZM8.0446 19.1429H5.0804V9.5982H8.049v9.5447h-.0045ZM6.5625 8.2946c-.9509 0-1.7188-.7723-1.7188-1.7187 0-.9464.768-1.7188 1.7188-1.7188.9464 0 1.7188.7724 1.7188 1.7188 0 .9509-.768 1.7187-1.7188 1.7187ZM19.1562 19.143H16.192V14.5c0-1.1071-.0224-2.5312-1.5402-2.5312-1.5447 0-1.7813 1.2053-1.7813 2.4508v4.7233H9.9063V9.5982H12.75v1.3036h.0402c.3973-.75 1.3661-1.5402 2.808-1.5402 3 0 3.558 1.9777 3.558 4.5491v5.2322Z"
		/>
	</svg>
</template>
