import { reactive, ref, watch, type MaybeRef, unref } from 'vue';
import { type SingleExchangeSchoolSiteAthlete } from '@/types/exchangeSchoolSite';

interface UseRegisterNowState {
	isOpen: boolean;
	athlete: SingleExchangeSchoolSiteAthlete | undefined;

}

const DEFAULT_STATE: UseRegisterNowState = {
	isOpen: false,
	athlete: undefined,
};

const getDefaultState = () => ({ ...DEFAULT_STATE });

const state = reactive<UseRegisterNowState>(getDefaultState());

function resetState() {
	Object.assign(state, getDefaultState());
}

const isRendered = ref<boolean>(false);

watch(() => state.isOpen, (isOpen) => {
	if (isOpen && !isRendered.value) isRendered.value = true;
	if (!isOpen) resetState();
});


export function useExchangeSchoolSiteRegisterNowDialog() {
	async function open(athlete?: MaybeRef<SingleExchangeSchoolSiteAthlete | undefined>) {
		state.isOpen = true;
		const _athlete = unref(athlete);
		if (_athlete) {
			state.athlete = _athlete;
		}
	}

	return {
		state,
		isRendered,
		resetState,
		open,
	};
}
