import { defineStore } from 'pinia';
import { reactive, computed } from 'vue';

import { useFeatureFlagService } from '@/api/featureFlagService';
import { SCHOOL_FLAGS } from '@teamworksdev/influencer-core';
import type { AnyFlag, ApiFeatureFlags } from '@/types/featureFlags';
import type { UserProfile } from '@/types/users';
import { isBrandUserProfile, isTeamUserProfile, isComplianceOfficerProfile } from '@/types/users';

interface FeatureFlagStoreState {
	featureFlags: ApiFeatureFlags;
	currentProfileFlags?: { [key: string]: boolean }[];
}

const transformFlags = <T extends string>(flags: Record<T, boolean> | undefined): { [key: string]: boolean }[] => {
	return flags ? Object.entries(flags).map(([key, value]) => ({ [key]: value as boolean })) : [];
};

const initialState: FeatureFlagStoreState = {
	featureFlags: {
		data: {
			app: [{ identifier: null, flags: {} }],
			school: [],
			team: [],
			user: [{ identifier: null, flags: {} }],
			brand: [],
		},
	},
	currentProfileFlags: [],
};

export const useFeatureFlagStore = defineStore('featureFlags', () => {
	const state = reactive<FeatureFlagStoreState>(structuredClone(initialState));

	const featureFlagService = useFeatureFlagService();

	const fetchFeatureFlags = async () => {
		try {
			const featureFlags = await featureFlagService.getFeatureFlags();
			state.featureFlags = featureFlags;
		} catch (error) {
			console.error('Error fetching feature flags:', error);
			state.featureFlags = initialState.featureFlags;
		}
	};

	// on profile switch, we'll want to just set the flags for the new profile by checking team and school uuids since these are an array. we will always get the app and user flags
	const setFlagsForProfile = (profile: UserProfile) => {
		let schoolId: string | undefined;
		let teamId: string | undefined;
		let brandId: string | undefined;

		if (isComplianceOfficerProfile(profile) && 'school' in profile) {
			schoolId = profile.school.uuid;
		} else if (isTeamUserProfile(profile) && 'academic_profile' in profile) {
			schoolId = profile.academic_profile?.school.uuid;
			teamId = profile.academic_profile?.team.uuid;
		} else if (isBrandUserProfile(profile) && 'exchange_brand' in profile) {
			brandId = profile.exchange_brand.uuid;
		}

		const schoolFlags = state.featureFlags.data?.school.find((school) => school.identifier === schoolId) || null;
		const teamFlags = state.featureFlags.data.team?.find((team) => team.identifier === teamId) || null;
		const brandFlags = state.featureFlags.data.brand?.find((brand) => brand.identifier === brandId) || null;
		const appFlags = state.featureFlags.data.app[0] || null;
		const userFlags = state.featureFlags.data.user[0] || null;

		state.currentProfileFlags = [
			...transformFlags(appFlags?.flags),
			...transformFlags(schoolFlags?.flags),
			...transformFlags(teamFlags?.flags),
			...transformFlags(userFlags?.flags),
			...transformFlags(brandFlags?.flags),
		];
	};

	const $reset = () => {
		Object.assign(state, structuredClone(initialState));
	};

	/** Flag Getters */
	const isFlagEnabled = (flag: AnyFlag) => {
		const flagValue = state.currentProfileFlags?.find((flagObj) =>
			Object.prototype.hasOwnProperty.call(flagObj, flag),
		);
		return flagValue ? flagValue[flag] : false;
	};

	const isWalletEnabled = computed(() => isFlagEnabled(SCHOOL_FLAGS.WALLET_ENABLED));

	const isComplianceBetaEnabled = computed(() => isFlagEnabled(SCHOOL_FLAGS.COMPLIANCE_V3_BETA));

	return {
		featureFlags: state.featureFlags,
		fetchFeatureFlags,
		setFlagsForProfile,
		isWalletEnabled,
		isComplianceBetaEnabled,
		$reset,
	};
});
