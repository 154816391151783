import { reactive, ref, watch, type MaybeRef, unref } from 'vue';
import type { SingleExchangeAthlete, SingleExchangeAthleteRateCard } from '@/types/exchangeAthletes';

interface UseAgreementDialogState {
	isOpen: boolean;
	activeBulletinUuid: string | undefined;
	activeRateCard: SingleExchangeAthleteRateCard | undefined;
	selectedAthletes: Uuid[];
	agreementUuid: Uuid | undefined;
	isDuplicating: boolean;
	isEditing: boolean;
	onCreated: (() => void) | undefined;
	onUpdated: (() => void) | undefined;
}

const DEFAULT_STATE: UseAgreementDialogState = {
	isOpen: false,
	activeBulletinUuid: undefined,
	activeRateCard: undefined,
	selectedAthletes: [],
	agreementUuid: undefined,
	isDuplicating: false,
	isEditing: false,
	onCreated: undefined,
	onUpdated: undefined,
};

const getDefaultState = () => ({ ...DEFAULT_STATE });

const state = reactive<UseAgreementDialogState>(getDefaultState());

function resetState() {
	Object.assign(state, getDefaultState());
}

const isRendered = ref<boolean>(false);

watch(() => state.isOpen, (isOpen) => {
	if (isOpen && !isRendered.value) isRendered.value = true;
	if (!isOpen) resetState();
});

interface UseAgreementDialogOptions {
	bulletin?: string;
	selectedAthletes?: MaybeRef<MaybeArray<Uuid> | SingleExchangeAthlete | null | undefined>;
	onCreated?: () => void;
	onUpdated?: () => void;
}

export function useAgreementDialog(options?: UseAgreementDialogOptions) {
	async function open(agreementUuid?: Uuid, context?: 'duplicate' | 'edit') {
		state.isOpen = true;
		state.activeBulletinUuid = options?.bulletin;
		state.isDuplicating = context === 'duplicate';
		state.isEditing = context === 'edit';
		state.onCreated = options?.onCreated;
		state.onUpdated = options?.onUpdated;

		const selectedAthletesUuids = unref(options?.selectedAthletes);
		if (selectedAthletesUuids)
			state.selectedAthletes = Array.isArray(selectedAthletesUuids)
				? selectedAthletesUuids
				: typeof selectedAthletesUuids === 'string'
					? [selectedAthletesUuids]
					: [selectedAthletesUuids.uuid];

		state.agreementUuid = agreementUuid;
	}

	return {
		state,
		isRendered,
		resetState,
		open,
	};
}
