<template>
	<div class="view-login">
		<LoginContentDefault
			v-if="!initLogIn || isImpersonationLoginRoute(route)"
			:is-loading="isLogInLoading"
			@log-in-basic="initLogInBasic"
			@log-in-teamworks="getTeamworksToken"
		/>
		<LoginContentKeepMeLoggedIn
			v-else
			:is-loading="isLogInLoading"
			@submit="logIn"
		/>
	</div>
</template>

<script setup lang="ts">
import { computed, ref, onBeforeMount } from 'vue';
import { useBasicAuth, useTeamworksAuth, useImpersonationAuth, isImpersonationLoginRoute } from '@/composables';
import LoginContentDefault from '@/components/login/LoginContentDefault.vue';
import LoginContentKeepMeLoggedIn from '@/components/login/LoginContentKeepMeLoggedIn.vue';
import { useRouter, useRoute } from 'vue-router';
import { useUserStore } from '@/stores/userStore';
import { watch } from 'vue';

const router = useRouter();
const route = useRoute();

const {
	unsetUser,
} = useUserStore();

const {
	authTokenRequest,
	initLogInBasic,
	isInitLoginBasic,
	logInBasic,
	logOut,
	resetLogInBasic,
} = useBasicAuth({
	onLogInSuccess,
	onLogInError,
});

const {
	getTeamworksToken,
	logInTeamworks,
	isInitLoginTeamworks,
	teamworksToken,
	resetLogInTeamWorks,
} = useTeamworksAuth({
	onLogInSuccess,
	onLogInError,
});

const {
	logInImpersonation,
	logOutImpersonation,
	impersonationTokenRequest,
} = useImpersonationAuth({ onImpersonationSuccess, onImpersonationError });

const isLogInLoading = ref(false);

const initLogIn = computed<'basic' | 'teamworks' | undefined>(() => {
	if (isInitLoginBasic.value) return 'basic';
	if (isInitLoginTeamworks.value) return 'teamworks';
	return undefined;
});

function onLogInSuccess() {
	if (isImpersonationLoginRoute(route)) {
		impersonationTokenRequest.user = route.query.acting_as as string;
		logInImpersonation();
	} else {
		const redirect = router.currentRoute.value.query.redirect;
		router.push(redirect ? { name: 'ProfileChooserIndex', query: { redirect } } : { name: 'ProfileChooserIndex' });
	}
}

function onLogInError() {
	isLogInLoading.value = false;
}

watch(initLogIn, (value) => {
	if (value && isImpersonationLoginRoute(route)) {
		logIn(false);
	}
});

function onImpersonationSuccess() {
	router.push({ name: 'ProfileChooserIndex' });
}

function onImpersonationError() {
	logOut({ redirect: false });
	resetLogInBasic();
	resetLogInTeamWorks();
	isLogInLoading.value = false;
}


onBeforeMount(async () => {
	if (isImpersonationLoginRoute(route)) {
		await logOutImpersonation(false);
		logOut({ redirect: false });
	} else {
		unsetUser();
	}
});

async function logIn(keepMeLoggedIn: boolean) {
	isLogInLoading.value = true;
	if (initLogIn.value === 'basic') {
		authTokenRequest.remember_me = keepMeLoggedIn;
		await logInBasic();
	} else if (initLogIn.value === 'teamworks') {
		teamworksToken.value.remember_me = keepMeLoggedIn;
		await logInTeamworks();
	}
}
</script>
