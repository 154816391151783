import { computed, reactive, ref, watch } from 'vue';
import { useUserStore } from '@/stores/userStore';
import type { Gallery, SingleGallery } from '@/types/galleries';
import { ProfileAccessService, type Team } from '@/types/users';

interface UseGalleryDuplicateState {
	isOpen: boolean;
	gallery: Gallery | SingleGallery | undefined;
}

const DEFAULT_STATE: UseGalleryDuplicateState = {
	isOpen: false,
	gallery: undefined,
} as const;

const getDefaultState = () => ({ ...DEFAULT_STATE });

const state = reactive<UseGalleryDuplicateState>(getDefaultState());

function resetState() {
	Object.assign(state, getDefaultState());
}

const isRendered = ref<boolean>(false);

watch(() => state.isOpen, (isOpen) => {
	if (isOpen && !isRendered.value) isRendered.value = true;
	if (!isOpen) resetState();
});

export function useContentHubGalleryDuplicateDialog() {
	const { user, activeTeam, profileAccess } = useUserStore();

	function open(gallery: Gallery | SingleGallery) {
		state.isOpen = true;
		state.gallery = gallery;
	}

	const teams = computed(() => {
		return user?.user_profiles.reduce((teams, profile) => {
			const isTeamUser = 'academic_profile' in profile;
			const profileAccess = new ProfileAccessService(profile);
			if (!isTeamUser || !profileAccess.hasContentHubWrite || activeTeam?.uuid === profile.academic_profile.team.uuid) return teams;
			return [...teams, profile.academic_profile.team];
		}, [] as (Team)[]) ?? [];
	});

	const userCanDuplicateGallery = computed<boolean>(() => {
		return profileAccess.hasContentHubWrite && !!teams.value.length;
	});

	return {
		teams,
		userCanDuplicateGallery,
		open,
		resetState,
		isRendered,
		state,
	};
}
