import { computed } from 'vue';
import type { RouteLocationNamedRaw } from 'vue-router';

export type ToastType =
	| 'error'
	| 'info'
	| 'warning'
	| 'success';

export interface Toast {
	uuid: string;
	title: string;
	type: ToastType;
	description?: string;
	link?: string | RouteLocationNamedRaw;
}

export const linkIsRouteLocation = (link: string | RouteLocationNamedRaw | undefined): link is RouteLocationNamedRaw => {
	return typeof link === 'object';
};

export function useToast(
	props: { toast?: Toast },
) {
	const computedIcon = computed<string>(() => {
		const icons: Record<ToastType, string> = {
			error: 'do_not_disturb_alt',
			info: 'info',
			warning: 'error_outline',
			success: 'check_circle',
		};
		return icons[props.toast?.type ?? 'info'];
	});

	const computedColor = computed<string>(() => {
		const colors: Record<ToastType, string> = {
			error: 'error',
			info: 'info',
			warning: 'warning',
			success: 'success',
		};
		return colors[props.toast?.type ?? 'info'];
	});

	return {
		computedIcon,
		computedColor,
	};
}
