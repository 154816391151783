import { useHead } from '@unhead/vue';

const initReviewApp = () => {
	if (!window.location.hostname.startsWith('review-feature')) return;
	useHead({
		script: [
			{
				'data-project-id': import.meta.env.VITE_CI_PROJECT_ID,
				'data-project-path': import.meta.env.VITE_CI_PROJECT_PATH,
				'data-merge-request-id': import.meta.env.VITE_CI_MERGE_REQUEST_IID,
				'data-mr-url': 'https://git.inflcr.com',
				'data-require-auth': true,
				id: 'review-app-toolbar-script',
				src: 'https://gitlab.com/assets/webpack/visual_review_toolbar.js',
			},
		],
	});
};

export { initReviewApp };
