import type { SortParam } from '@/api/utilities/provider';
import type {
	BulletinApplicantsSortValue,
	SingleBulletinApplicant,
	SingleExchangeBulletin,
} from '@/types/bulletins';
import { cloneDeep } from 'lodash-es';
import { defineStore } from 'pinia';
import { computed, reactive, toRefs } from 'vue';

interface exchangeBulletinStoreState {
	bulletinApplicantsPage: number;
	bulletinApplicantsSearch: string;
	bulletinApplicantsPerPage: number;
	bulletinApplicantsSort: SortParam<BulletinApplicantsSortValue>[];
	selectedBulletinApplicants: SingleBulletinApplicant[];
	cachedBulletin?: SingleExchangeBulletin;
}

export const getDefaultSort = (): SortParam<BulletinApplicantsSortValue>[] => [{ key: 'created_at', order: 'desc' }];


const DEFAULT_STATE: exchangeBulletinStoreState = {
	bulletinApplicantsPage: 1,
	bulletinApplicantsSearch: '',
	bulletinApplicantsPerPage: 20,
	bulletinApplicantsSort: getDefaultSort(),
	selectedBulletinApplicants: [],
	cachedBulletin: undefined,
};

export const useExchangeBulletinStore = defineStore('bulletin', () => {
	const state = reactive<exchangeBulletinStoreState>(cloneDeep(DEFAULT_STATE));

	const setBulletinApplicantsSearch = (search: string) => {
		state.bulletinApplicantsSearch = search;
	};

	const setBulletinApplicantsSort = (sort: SortParam<BulletinApplicantsSortValue>[]) => {
		state.bulletinApplicantsSort = sort;
	};

	const setBulletinApplicantsPage = (page: number) => {
		state.bulletinApplicantsPage = page;
	};

	const setBulletinApplicantsPerPage = (perPage: number) => {
		state.bulletinApplicantsPerPage = perPage;
	};

	const clearSelectedBulletinApplicants = () => {
		state.selectedBulletinApplicants = [];
	};

	const setSelectedBulletinApplicants = (applicants: SingleBulletinApplicant[]) => {
		state.selectedBulletinApplicants = applicants;
	};


	const setCachedBulletin = (bulletin: SingleExchangeBulletin) => {
		state.cachedBulletin = bulletin;
	};

	const getCachedBulletin = (bulletinUuid: string | undefined) => {
		return computed<SingleExchangeBulletin | undefined>(() => {
			if (!bulletinUuid || state.cachedBulletin?.uuid !== bulletinUuid) return undefined;
			return state.cachedBulletin;
		});
	};

	const $reset = () => Object.assign(state, cloneDeep(DEFAULT_STATE));

	return {
		...toRefs(state),
		setBulletinApplicantsPage,
		setBulletinApplicantsSearch,
		setBulletinApplicantsPerPage,
		setBulletinApplicantsSort,
		setSelectedBulletinApplicants,
		clearSelectedBulletinApplicants,
		setCachedBulletin,
		getCachedBulletin,
		$reset,
	};
});
