import { computed } from 'vue';

import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import type { RouteLocationNamedRaw } from 'vue-router';

import {
	useAppSwitcher,
	useBasicAuth,
} from '@/composables';
import { useAuthStore } from '@/stores/authStore';
import { useUserStore } from '@/stores/userStore';
import {
	APP_SWITCHER_SLUGS,
	ROLES,
} from '@teamworksdev/influencer-core';

export class PrimaryNavigationItem {
	id?: string;
	title: string;
	appendIcon?: string;
	prependIcon?: string;
	enabled?: boolean;
	visible?: boolean;
	badge?: string;
	to?: RouteLocationNamedRaw;
	link?: string;
	action?(): void;

	constructor(navItem: PrimaryNavigationItem) {
		this.id = navItem.id ?? crypto.randomUUID();
		this.title = navItem.title;
		this.appendIcon = navItem.appendIcon;
		this.prependIcon = navItem.prependIcon;
		this.enabled = navItem.enabled ?? true;
		this.visible = navItem.visible ?? true;
		this.badge = navItem.badge;
		this.to = navItem.to;
		this.link = navItem.link;
		this.action = navItem.action;
	}
}

const withDefaults = (items: PrimaryNavigationItem[]) => {
	return items.map((i) => new PrimaryNavigationItem(i));
};

export function usePrimaryNavigation() {
	const { t } = useI18n();
	const authStore = useAuthStore();
	const userStore = useUserStore();
	const { appSwitcherProfile } = storeToRefs(useUserStore());
	const { switchApp, appsMetaData } = useAppSwitcher();
	const { logOut } = useBasicAuth();

	const isMenuItemShown = ({ visible }: PrimaryNavigationItem): boolean => !!visible;

	const mainItems = computed(() => withDefaults([
		{
			title: t('navigationMenu.contentHub'),
			prependIcon: 'image',
			to: { name: 'ContentHubIndex' },
			visible: userStore.profileAccess.hasContentHubTaggedOnlyRead,
		},
		{
			title: t('navigationMenu.collaborationHub'),
			prependIcon: 'handshake',
			to: { name: 'ReleaseNotesIndex' },
			enabled: false,
			visible: userStore.profileAccess.hasCollaborationHubRead,
		},
		{
			title: t('navigationMenu.roster'),
			prependIcon: 'person_search',
			to: { name: 'Roster' },
			enabled: true,
			visible: userStore.profileAccess.hasTeamManagementRead,
		},
		{
			title: t('navigationMenu.teamManagement'),
			prependIcon: 'contacts',
			enabled: true,
			visible: userStore.profileAccess.hasTeamManagementRead,
			link: `${import.meta.env.VITE_INFLCR_V2_BASE}/auth/swap?role=${ROLES.TEAM_USER}&token=${authStore.token?.access_token}&profileUuid=${userStore.profile?.uuid}`,
		},
		{
			title: t('navigationMenu.browseAthletes'),
			prependIcon: 'person_search',
			to: { name: 'ExchangeAthletes' },
			visible: userStore.profileAccess.hasExchangeWrite,
		},
		{
			title: t('navigationMenu.bulletins'),
			prependIcon: 'campaign',
			to: { name: 'ExchangeBulletins' },
			visible: userStore.profileAccess.canAccessBulletins,
		},
		{
			title: t('navigationMenu.conversations'),
			prependIcon: 'sms',
			to: { name: 'ExchangeConversations' },
			visible: userStore.profileAccess.hasExchangeWrite,
		},
		{
			title: t('navigationMenu.transactions'),
			prependIcon: 'local_atm',
			to: { name: userStore.transactionsRouteName },
			visible: userStore.canAccessTransactions,
		},
		{
			title: t('navigationMenu.exchangeSettings'),
			prependIcon: 'settings',
			to: { name: 'ExchangeSettings' },
			visible: userStore.profileAccess.hasExchangeWrite,
		},
		{
			title: t('navigationMenu.complianceLedger'),
			prependIcon: 'assignment_turned_in',
			visible: userStore.profileAccess.isComplianceOfficer,
			link: `${import.meta.env.VITE_INFLCR_V2_BASE}/auth/swap?role=${ROLES.COMPLIANCE_OFFICER}&token=${authStore.token?.access_token}`,
		},
		{
			title: t('navigationMenu.inbox'),
			prependIcon: '$twi-conversation',
			enabled: false,
			visible: false,
		},
		{
			title: t('pageDiscloseNilActivity.metaTitle'),
			prependIcon: 'attach_money',
			visible: userStore.profileAccess.hasTransactionsImportWrite,
			to: { name: 'DiscloseNilActivity' },
		},
		{
			title: t('pageServiceProvidersDashboard.metaTitle'),
			prependIcon: 'dashboard',
			visible: userStore.profileAccess.isComplianceOfficer,
			to: { name: 'ServiceProvidersDashboard' },
		},
		{
			title: t('pageNilAssistDashboard.metaTitle'),
			prependIcon: '$twi-ncaa',
			visible: userStore.profileAccess.isComplianceOfficer,
			to: { name: 'NilAssistDashboard' },
		},
		{
			title: t('pageReleaseNotes.metaTitle'),
			prependIcon: 'tips_and_updates',
			to: { name: 'ReleaseNotesIndex' },
			visible: true,
		},
	]).filter(isMenuItemShown));

	const appendedItems = computed(() => withDefaults([
		{
			title: t('navigationMenu.search'),
			enabled: false,
			visible: false,
		},
		{
			title: t('navigationMenu.chatWithSupport'),
			link: 'mailto:support@teamworks.com?subject=INFLCR%20Support%20Question',
		},
		{
			title: t('navigationMenu.helpCenter'),
			link: 'https://help.teamworks.com/influencer/s/',
		},
		{
			title: t('navigationMenu.learningPortal'),
			link: 'https://learn.inflcr.com/',
		},
	]).filter(isMenuItemShown));

	const userItems = computed(() => withDefaults([
		{
			title: t('navigationMenu.myProfile'),
			to: { name: 'Profile' },
			visible: userStore.profileAccess.hasMyProfile,
		},
		{
			title: t('navigationMenu.accountSettings'),
			to: { name: 'AccountSettingsParent' },
		},
		{
			appendIcon: 'logout',
			title: t('logOut'),
			action: () => {
				logOut();
			},
		},
	]).filter(isMenuItemShown));

	const appSwitcherItems = computed(() => {
		const allItems = appsMetaData.map((app) => {
			const isThisApp = app.slug === APP_SWITCHER_SLUGS.INFLCR;
			return {
				title: app.title,
				action: isThisApp ? undefined : () => switchApp(app.slug),
				prependIcon: app.icon,
				appendIcon: isThisApp ? 'check' : undefined,
				visible: isThisApp || (!!appSwitcherProfile?.value?.[`${app.slug}_target_url`] && appSwitcherProfile.value.login_access.web[`${app.slug}`]),
			};
		});
		return withDefaults(allItems.filter(isMenuItemShown));
	});

	return {
		mainItems,
		appendedItems,
		userItems,
		appSwitcherItems,
	};
}
