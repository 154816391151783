import type {
	RouteLocationNormalized,
	RouteRecordRaw,
} from 'vue-router';

function checkForLocalRedirect(to: RouteLocationNormalized) {
	if (
		!localStorage.getItem('inflcr-local-provider-auth-redirect')
	) return true;

	window.location.assign(`http://localhost:5173${to.fullPath}`);
}

const routes: RouteRecordRaw[] = [
	{
		path: '/provider/auth',
		children: [
			{
				path: 'smugmug',
				name: 'ProviderAuthSmugMug',
				component: () => import('@/views/ProviderAuthCallbackSmugMug.vue'),
				meta: {
					requiresAuth: true,
					layout: 'LayoutDefault',
				},
				beforeEnter: [checkForLocalRedirect],
			},
			{
				path: 'photoshelter',
				name: 'ProviderAuthPhotoshelter',
				component: () => import('@/views/ProviderAuthCallbackPhotoshelter.vue'),
				meta: {
					requiresAuth: true,
					layout: 'LayoutDefault',
				},
				beforeEnter: [checkForLocalRedirect],
			},
			{
				path: 'box',
				name: 'ProviderAuthBox',
				component: () => import('@/views/ProviderAuthCallbackBox.vue'),
				meta: {
					requiresAuth: true,
					layout: 'LayoutDefault',
				},
				beforeEnter: [checkForLocalRedirect],
			},
			{
				path: 'dropbox',
				name: 'ProviderAuthDropbox',
				component: () => import('@/views/ProviderAuthCallbackDropbox.vue'),
				meta: {
					requiresAuth: true,
					layout: 'LayoutDefault',
				},
				beforeEnter: [checkForLocalRedirect],
			},
			{
				path: 'gettyimages',
				name: 'ProviderAuthGettyImages',
				component: () => import('@/views/ProviderAuthCallbackGettyImages.vue'),
				meta: {
					requiresAuth: true,
					layout: 'LayoutDefault',
				},
				beforeEnter: [checkForLocalRedirect],
			},
			{
				path: 'google',
				name: 'ProviderAuthGoogleDrive',
				component: () => import('@/views/ProviderAuthCallbackGoogleDrive.vue'),
				meta: {
					requiresAuth: true,
					layout: 'LayoutDefault',
				},
				beforeEnter: [checkForLocalRedirect],
			},
		],
	},
];

export default routes;
