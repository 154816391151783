<template>
	<svg
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M1.66667 0H15C15.9167 0 16.6667 0.75 16.6667 1.66667V11.6667C16.6667 12.5833 15.9167 13.3333 15 13.3333H3.33333L0 16.6667V1.66667C0 0.75 0.75 0 1.66667 0ZM6.43794 3.51285C6.21058 3.8539 6.37163 4.32285 6.75058 4.4839C7.03952 4.60232 7.38531 4.51706 7.5511 4.24706C7.77373 3.90127 8.13373 3.61232 8.68794 3.61232C9.91005 3.61232 10.1516 4.7539 9.72058 5.36495C9.53932 5.62419 9.29993 5.84497 9.05789 6.0682C8.75594 6.34668 8.44987 6.62896 8.24742 6.99443C8.11479 7.23127 8.05794 7.48232 8.02952 7.7239C7.98215 8.13601 8.31847 8.49127 8.73058 8.49127C9.0811 8.49127 9.36531 8.22127 9.40794 7.87548C9.43637 7.66232 9.479 7.55811 9.55005 7.43022C9.6925 7.16829 9.89402 6.97323 10.1287 6.74611C10.3778 6.50499 10.6642 6.22775 10.9569 5.79601C11.4685 5.03811 11.2743 4.01969 10.9427 3.47495C10.5543 2.84022 9.79163 2.33337 8.67847 2.33337C7.66479 2.33337 6.89742 2.83074 6.43794 3.51285ZM8.68321 11.3334C9.20426 11.3334 9.63058 10.9071 9.63058 10.386C9.63058 9.86495 9.20426 9.43864 8.68321 9.43864C8.16215 9.43864 7.73584 9.86495 7.73584 10.386C7.73584 10.9071 8.16215 11.3334 8.68321 11.3334Z"
			fill="white"
		/>
	</svg>
</template>
