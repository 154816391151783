<template>
	<Component
		:is="currentLayoutComponent"
		ref="subLayoutParent"
	>
		<template
			v-for="slotName in Object.keys($slots)"
			:key="slotName"
			#[slotName]="slotProps"
		>
			<slot
				:name="slotName"
				:layout="layout"
				v-bind="slotProps"
			/>
		</template>
	</Component>
</template>

<script setup lang="ts">
import type { Layout } from '@/composables';


const props = defineProps<{
	useSubLayouts: () => {
		layout: Layout;
		currentLayoutComponent: string;
	};
}>();

const { layout, currentLayoutComponent } = props.useSubLayouts();

</script>
