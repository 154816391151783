import type { Toast } from '@/composables/useToast';
import { i18n } from '@/plugins/i18n';

export const getDefaultErrorToast = (): Omit<Toast, 'uuid'> => {
	return {
		type: 'error',
		title: i18n.global.t('inflcrErrorCodes.default.title'),
		description: i18n.global.t('inflcrErrorCodes.default.message'),
	};
};
