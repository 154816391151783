import { SOCIAL_MEDIA_PLATFORM_IDS, type SocialMediaPlatformId } from '@teamworksdev/influencer-core';

const SOCIAL_MEDIA_PLATFORMS = {
	instagram: {
		name: 'Instagram',
		icon: '$twi-instagram',
		color: 'brands-instagram',
	},
	twitter: {
		name: 'X',
		icon: '$twi-x',
		color: 'brands-x',
	},
	x: {
		name: 'X',
		icon: '$twi-x',
		color: 'brands-x',
	},
	tiktok: {
		name: 'TikTok',
		icon: '$twi-tiktok',
		color: 'brands-tiktok',
	},
	linkedIn: {
		name: 'LinkedIn',
		icon: '$twi-linkedin',
		color: 'brands-linkedin',
	},
	influential: {
		name: 'Influential',
		icon: '$twi-influential',
		color: 'brands-influential',
	},
	facebook: {
		name: 'Facebook',
		icon: '$twi-facebook',
		color: 'brands-facebook',
	},
} as const;

type SocialMediaPlatform = keyof typeof SOCIAL_MEDIA_PLATFORMS;

const SOCIAL_MEDIA_PLATFORMS_MAPPED: Record<SocialMediaPlatformId, typeof SOCIAL_MEDIA_PLATFORMS[SocialMediaPlatform] | null> = {
	[SOCIAL_MEDIA_PLATFORM_IDS.FACEBOOK]: SOCIAL_MEDIA_PLATFORMS.facebook,
	[SOCIAL_MEDIA_PLATFORM_IDS.INSTAGRAM]: SOCIAL_MEDIA_PLATFORMS.instagram,
	[SOCIAL_MEDIA_PLATFORM_IDS.TWITTER]: SOCIAL_MEDIA_PLATFORMS.twitter,
	[SOCIAL_MEDIA_PLATFORM_IDS.TIKTOK]: SOCIAL_MEDIA_PLATFORMS.tiktok,
	[SOCIAL_MEDIA_PLATFORM_IDS.LINKEDIN]: SOCIAL_MEDIA_PLATFORMS.linkedIn,
} as const;

export {
	SOCIAL_MEDIA_PLATFORMS,
	SOCIAL_MEDIA_PLATFORMS_MAPPED,
	type SocialMediaPlatform,
};
