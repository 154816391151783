<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			fill="currentcolor"
			fill-rule="evenodd"
			d="M10.754 2.346a2.58 2.58 0 0 1 2.593.007L19.7 6.06c.804.47 1.3 1.337 1.3 2.276v7.313c0 .938-.494 1.804-1.297 2.274l-6.352 3.721a2.58 2.58 0 0 1-2.6.007l-6.443-3.73A2.633 2.633 0 0 1 3 15.642V8.345c0-.943.5-1.814 1.312-2.282l6.442-3.717Zm3.9 8.742 1.44-.892a.29.29 0 0 0 .093-.396l-.376-.618a.283.283 0 0 0-.392-.095l-1.905 1.183a2.827 2.827 0 0 1-1.492.426h-.044a2.829 2.829 0 0 1-1.492-.426L8.581 9.087a.283.283 0 0 0-.392.095l-.376.618a.29.29 0 0 0 .094.396l1.44.892c.679.422.96.597 1.254.936.289.331.508.723.654 1.168.068.207.097.425.097.643l.004 2.326c0 .16.127.288.285.288h.719a.286.286 0 0 0 .284-.288l.004-2.326c0-.218.029-.436.097-.643.146-.445.365-.837.654-1.168.295-.339.575-.514 1.255-.936Z"
			clip-rule="evenodd"
		/>
	</svg>
</template>
