import { reactive, ref, watch } from 'vue';

interface UseMediaDetailsState {
	isOpen: boolean;
	mediaUuid: string | undefined;

	onOpen: (() => void) | undefined;
}

interface UseMediaDetailsOptions {
	onOpen?: () => void;
}

const DEFAULT_STATE: UseMediaDetailsState = {
	isOpen: false,
	mediaUuid: undefined,
	onOpen: undefined,
} as const;

const getDefaultState = () => ({ ...DEFAULT_STATE });

const state = reactive<UseMediaDetailsState>(getDefaultState());

function resetState() {
	Object.assign(state, getDefaultState());
}

const isRendered = ref<boolean>(false);

watch(() => state.isOpen, (isOpen) => {
	if (isOpen && !isRendered.value) isRendered.value = true;
	if (!isOpen) resetState();
});

export function useMediaDetails(options?: UseMediaDetailsOptions) {
	function open(uuid?: string) {
		state.isOpen = true;
		state.mediaUuid = uuid;

		state.onOpen = options?.onOpen;
		state.onOpen?.();
	}

	return {
		state,
		isRendered,
		resetState,
		open,
	};
}
