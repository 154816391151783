import { EStorageKey, setLocalStorageItem } from '@/utils/localStorage';
import { i18n } from '@/plugins/i18n';
import { useLocale } from 'vuetify';
import { setDefaultOptions } from 'date-fns';
import { enUS, fr, de, es, it, pt, ptBR } from 'date-fns/locale';
import { type Locale } from 'date-fns';

const dateFnsLocales: Record<string, Locale> = {
	'de-DE': de,
	'en-US': enUS,
	'es-419': es,
	'fr-FR': fr,
	'it-IT': it,
	'pt-BR': ptBR,
	'pt-PT': pt,
};

export function useLocales() {
	function setLocale(locale: string) {
		if (!i18n.global.availableLocales.includes(locale)) return;

		const { current } = useLocale();

		i18n.global.locale.value = locale;
		current.value = locale;
		setLocalStorageItem(EStorageKey.APP_LOCALE, locale);
		setDefaultOptions({ locale: dateFnsLocales[locale] ?? enUS });
		document.querySelector('html')!.setAttribute('lang', locale);
	}

	return {
		setLocale,
	};
}
