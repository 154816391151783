import { isAfter } from 'date-fns';

export const EStorageKey = {
	TOKEN: 'inflcr-token',
	IMPERSONATION_TOKEN: 'inflcr-impersonation-token',
	INFLCR_ID: 'inflcr-profile-id',
	INFLCR_IMPERSONATED_BY_ID: 'inflcr-impersonated-by-profile-id',
	APP_LOCALE: 'inflcr-app-locale',
	PROVIDER_AUTH_REDIRECT: 'inflcr-provider-auth-redirect',
} as const;
export type StorageKey = typeof EStorageKey[keyof typeof EStorageKey];

interface LocalStorageItem<T> {
	value: T;
	expiresAt?: string;
}

export function setLocalStorageItem<T>(
	key: StorageKey,
	value: T,
	expiresAt?: Date,
	store: 'localStorage' | 'sessionStorage' = 'localStorage',
) {
	removeLocalStorageItem(key);
	const item: LocalStorageItem<T> = {
		value,
		expiresAt: expiresAt?.toISOString(),
	};
	if (store === 'localStorage') localStorage.setItem(key, JSON.stringify(item));
	else sessionStorage.setItem(key, JSON.stringify(item));
}

export function getLocalStorageItem<T>(key: StorageKey) {
	const serializedItem = localStorage.getItem(key) ?? sessionStorage.getItem(key);
	if (!serializedItem) return null;

	const item: LocalStorageItem<T> = JSON.parse(serializedItem);

	if (item.expiresAt && isAfter(new Date(), new Date(item.expiresAt))) {
		removeLocalStorageItem(key);
		return null;
	}

	return item.value;
}

export function removeLocalStorageItem(key: StorageKey) {
	sessionStorage.removeItem(key);
	localStorage.removeItem(key);
}
