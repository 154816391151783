<template>
	<svg
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M18.5718 13.7892V5.89828C18.5718 5.20009 18.2001 4.55658 17.5956 4.2067L10.7621 0.261235C10.1577 -0.0870783 9.41418 -0.0870783 8.80971 0.261235L1.97621 4.2067C1.37174 4.55501 1 5.20009 1 5.89828V13.7892C1 14.4874 1.37174 15.1309 1.97621 15.4808L8.80971 19.4263C9.41418 19.7746 10.1577 19.7746 10.7621 19.4263L17.5956 15.4808C18.2001 15.1325 18.5718 14.4874 18.5718 13.7892Z"
			fill="#1A6AFF"
		/>
		<path
			d="M14.3203 12.2585C14.3203 12.4834 14.2 12.6927 14.0063 12.8036L10.1015 15.0591C10.0031 15.1153 9.94061 15.2215 9.94061 15.3355C9.94061 15.5433 9.77348 15.712 9.56574 15.712C9.50483 15.712 9.44391 15.6964 9.38924 15.6667C8.78477 15.3402 7.53053 14.6295 7.51648 14.6217L5.52344 13.469C5.4797 13.4425 5.44222 13.4144 5.41098 13.3831C5.39848 13.3706 5.38599 13.3566 5.37505 13.3441L5.3688 13.3363L5.35631 13.3206L5.34225 13.2988L5.32819 13.2785L5.32507 13.2738V13.2691C5.27353 13.1847 5.24854 13.0926 5.24854 12.9958V12.2601C5.2501 12.0414 5.42816 11.8633 5.64683 11.8633C5.71556 11.8633 5.78272 11.8821 5.84364 11.9165L9.62666 14.1016C9.67508 14.1282 9.72975 14.1438 9.78442 14.1438C9.83908 14.1438 9.89531 14.1282 9.94217 14.1016L13.7268 11.9165C13.7877 11.8805 13.8548 11.8633 13.9251 11.8633C14.1438 11.8633 14.3203 12.0398 14.3203 12.2585Z"
			fill="#34AFFF"
		/>
		<path
			d="M14.3218 9.84221C14.3218 10.0375 14.2155 10.2186 14.0484 10.3155L10.0592 12.6178C9.98737 12.66 9.94207 12.7381 9.94207 12.8224C9.94207 13.0458 9.76089 13.2269 9.53753 13.2269C9.47193 13.2269 9.40477 13.2098 9.34541 13.1785C8.72844 12.8427 7.53044 12.1648 7.51794 12.157L5.52178 11.0059C5.35465 10.9075 5.25 10.7247 5.25 10.531V9.6501C5.25 9.47828 5.38901 9.33771 5.56239 9.33771C5.61706 9.33771 5.67016 9.35333 5.71858 9.37988L9.6703 11.6603C9.70466 11.6806 9.74527 11.6916 9.78588 11.6916C9.82649 11.6916 9.86554 11.6806 9.90146 11.6603L13.686 9.47672C13.7517 9.43924 13.8235 9.41893 13.8969 9.41893C14.1312 9.41893 14.3218 9.60948 14.3218 9.84221Z"
			fill="white"
		/>
		<path
			d="M14.2484 6.86669C14.2374 6.84951 14.2265 6.83389 14.2171 6.81827L14.214 6.81359L14.2093 6.80733C14.1937 6.78703 14.1781 6.77298 14.1609 6.7558C14.1265 6.71987 14.0906 6.69176 14.0484 6.66833L10.0592 4.36446C9.97646 4.31604 9.88274 4.29105 9.7859 4.29105C9.68906 4.29105 9.59691 4.31604 9.51413 4.3629L5.52336 6.66833C5.48119 6.69176 5.44371 6.71987 5.41247 6.75423C5.39216 6.77141 5.37654 6.79171 5.36405 6.8089C5.33593 6.8417 5.31094 6.88544 5.2922 6.9323C5.26408 7.00102 5.25159 7.0713 5.25159 7.14159V8.06626C5.25159 8.16154 5.27658 8.25525 5.32344 8.3396C5.33437 8.35834 5.34843 8.38021 5.36405 8.40052V8.40364L5.36873 8.40676L5.37498 8.41458C5.38748 8.42863 5.39997 8.44269 5.41403 8.45519C5.44683 8.48799 5.48275 8.5161 5.52336 8.53953C5.55304 8.55515 8.34736 10.153 9.35169 10.7075C9.41104 10.7403 9.4782 10.7575 9.5438 10.7575C9.76404 10.7575 9.94366 10.5779 9.94366 10.3576C9.94366 10.2733 9.98896 10.1952 10.0608 10.153L14.05 7.85071C14.2062 7.76168 14.3077 7.6008 14.3202 7.42117C14.3218 7.40556 14.3233 7.39306 14.3233 7.37745V7.14159C14.3233 7.04475 14.2968 6.94947 14.2484 6.86669ZM9.6219 5.35005L9.62659 5.54684C9.62971 5.73584 9.73436 5.91234 9.89836 6.00762L12.5146 7.51958C12.5771 7.5555 12.6458 7.57893 12.7177 7.5883L9.78747 9.26271L6.31058 7.24936C6.34182 7.23843 6.37306 7.22437 6.40274 7.20719L9.6219 5.35005Z"
			fill="white"
		/>
	</svg>
</template>
