import type { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
	{
		path: '/account-settings',
		name: 'AccountSettingsParent',
		component: () => import('@/views/AccountSettings.vue'),
		redirect: { name: 'AccountSettingsPersonalInformation' },
		children: [
			{
				path: 'personal-information',
				name: 'AccountSettingsPersonalInformation',
				component: () => import('@/views/AccountSettingsPersonalInformation.vue'),
				meta: {
					layout: 'LayoutAuthenticated',
					requiresAuth: true,
					title: 'pageAccountSettingsPersonalInformation.metaTitle',
				},
			},
			{
				path: 'personal-preferences',
				name: 'AccountSettingsPersonalPreferences',
				component: () => import('@/views/AccountSettingsPersonalPreferences.vue'),
				meta: {
					layout: 'LayoutAuthenticated',
					requiresAuth: true,
					title: 'pageAccountSettingsPersonalPreferences.metaTitle',
					requiredAccess: 'isServiceProvider',
				},
			},
		],
	},
];

export default routes;
