<template>
	<svg
		viewBox="0 0 20 21"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M18.8626 14.0174V5.99589C18.8626 5.28615 18.4847 4.63198 17.8703 4.27631L10.9237 0.265558C10.3092 -0.0885193 9.55342 -0.0885193 8.93895 0.265558L1.99237 4.27631C1.37789 4.63039 1 5.28615 1 5.99589V14.0174C1 14.7271 1.37789 15.3813 1.99237 15.737L8.93895 19.7477C9.55342 20.1018 10.3092 20.1018 10.9237 19.7477L17.8703 15.737C18.4847 15.3829 18.8626 14.7271 18.8626 14.0174Z"
			fill="#A0D346"
		/>
		<path
			d="M12.3161 13.3045C12.0589 13.4871 11.781 13.6411 11.4873 13.7681C11.4873 13.7666 11.4841 13.7634 11.4841 13.7618C11.7779 13.6379 12.0541 13.4839 12.313 13.2997C12.313 13.3013 12.313 13.3029 12.3161 13.3045Z"
			fill="white"
		/>
		<path
			d="M11.8207 8.60942C11.8207 8.96032 11.5365 9.24454 11.1856 9.24454H9.96619V7.9743H11.1856C11.5365 7.9743 11.8207 8.25852 11.8207 8.60942Z"
			fill="#83B60B"
		/>
		<path
			d="M11.8208 10.5608C11.8208 10.9117 11.5366 11.1959 11.1857 11.1959H8.82465V9.92566H11.1857C11.5366 9.92566 11.8208 10.2115 11.8208 10.5608Z"
			fill="#83B60B"
		/>
		<path
			d="M6.23016 7.9743C6.20317 7.9743 6.17617 7.97589 6.15077 7.97907C6.14918 7.97748 6.15077 7.97589 6.15395 7.9743H6.23016Z"
			fill="white"
		/>
		<path
			d="M6.23017 11.1959H6.15395C6.15395 11.1959 6.14919 11.1928 6.15078 11.1912C6.17618 11.1943 6.20317 11.1959 6.23017 11.1959Z"
			fill="white"
		/>
		<path
			d="M12.7322 13.9825C14.1294 13.0219 15.0456 11.4103 15.0456 9.58589C15.0456 6.63737 12.6559 4.24615 9.70741 4.24615C6.75889 4.24615 4.36926 6.63737 4.36926 9.58589C4.36926 12.5344 6.75889 14.924 9.70741 14.924C10.4902 14.924 11.2333 14.7557 11.9033 14.4525H11.9049L12.991 16.2229C13.0815 16.3689 13.2371 16.4499 13.3974 16.4499C13.4832 16.4499 13.5689 16.4277 13.6467 16.38C13.8706 16.2419 13.9405 15.9482 13.8023 15.7243L12.7322 13.9825ZM11.4873 13.7681C10.9411 13.9999 10.3394 14.1301 9.70741 14.1301C7.20188 14.1301 5.16316 12.0914 5.16316 9.58589C5.16316 7.08036 7.20188 5.04005 9.70741 5.04005C12.2129 5.04005 14.2517 7.07877 14.2517 9.58589C14.2517 11.1213 13.4864 12.482 12.3161 13.3045C12.0589 13.4871 11.7811 13.6411 11.4873 13.7681Z"
			fill="white"
		/>
		<path
			d="M11.8208 6.65642C11.8208 7.00732 11.535 7.29153 11.1857 7.29153H8.82465V6.0213H11.1857C11.2381 6.0213 11.2905 6.02765 11.3397 6.04036C11.4334 6.06417 11.5175 6.10704 11.589 6.16579C11.7303 6.28329 11.8208 6.45953 11.8208 6.65642Z"
			fill="#83B60B"
		/>
		<path
			d="M11.8208 12.5138C11.8208 12.7043 11.7382 12.8758 11.608 12.9917C11.608 12.9933 11.608 12.9933 11.608 12.9933C11.5286 13.0647 11.4318 13.1156 11.3238 13.1362C11.3222 13.1362 11.3222 13.1378 11.3206 13.1378C11.2825 13.1457 11.2428 13.1489 11.2031 13.1489H9.96783V11.8787H11.2031C11.5445 11.8787 11.8224 12.1629 11.8224 12.5138H11.8208Z"
			fill="#83B60B"
		/>
		<path
			d="M9.96624 11.8787V13.1489H8.11964C7.75762 12.9885 7.42419 12.7742 7.13045 12.5138C6.91451 12.3248 6.71921 12.1121 6.5509 11.8787H9.96624Z"
			fill="white"
		/>
		<path
			d="M8.82466 9.92566V11.1959H6.23021C6.20321 11.1959 6.17622 11.1943 6.15082 11.1911C6.05714 10.9895 5.98251 10.7783 5.92694 10.5608C5.88407 10.3988 5.85231 10.2337 5.83326 10.0638C5.82691 10.0178 5.82215 9.97171 5.81897 9.92566H8.82466Z"
			fill="white"
		/>
		<path
			d="M9.96628 7.9743V9.24454H5.81897C5.82215 9.19849 5.82691 9.15244 5.83326 9.1064C5.8539 8.9365 5.88566 8.77137 5.92694 8.60942C5.98251 8.39189 6.05714 8.18072 6.15082 7.97907C6.17622 7.97589 6.20321 7.9743 6.23021 7.9743H9.96628Z"
			fill="white"
		/>
		<path
			d="M8.82462 6.0213V7.29153H6.5509C6.71921 7.05813 6.91451 6.84536 7.13045 6.65642C7.42419 6.39602 7.75762 6.18167 8.11964 6.0213H8.82462Z"
			fill="white"
		/>
	</svg>
</template>
