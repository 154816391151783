import { ref, type Ref } from 'vue';
import { useAsyncState, watchImmediate } from '@vueuse/core';
import { useTeamsService } from '@/api';
import type { TeamGroup } from '@/types/teams';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/userStore';

const cachedGroups = ref<TeamGroup[] | undefined>();

export function useTeamAsync(teamId: Ref<string | undefined>) {
	const teamsService = useTeamsService();
	const { inflcrId } = storeToRefs(useUserStore());

	const getGroupsPromise = async () => {
		if (!teamId.value) throw new Error('Team ID is missing');
		return await teamsService.getGroups(teamId.value);
	};

	const {
		isLoading: isGroupsLoading,
		execute,
	} = useAsyncState(
		getGroupsPromise,
		undefined,
		{ immediate: false },
	);

	const getGroups = async () => {
		if (!teamId.value) throw new Error('Team ID is missing');
		if (!cachedGroups.value) cachedGroups.value = await execute();
		return cachedGroups.value;
	};

	const clearGroupsCache = () => {
		cachedGroups.value = undefined;
	};

	watchImmediate(() => inflcrId.value, clearGroupsCache);

	return {
		groups: cachedGroups,
		isGroupsLoading,
		getGroups,
		clearGroupsCache,
	};
}
