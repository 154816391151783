import { useUserStore } from '@/stores/userStore';
import type { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
	{
		path: '/content-hub',
		component: () => import('@/views/ContentHubParent.vue'),
		children: [
			{
				path: '',
				name: 'ContentHubIndex',
				redirect: () => {
					const userStore = useUserStore();
					return userStore.profileAccess.hasContentHubRead ? { name: 'ContentHubGalleries' } : { name: 'ContentHubMyContent' };
				},
			},
			{
				path: 'galleries',
				name: 'ContentHubGalleries',
				component: () => import('@/views/ContentHubGalleries.vue'),
				meta: {
					requiresAuth: true,
					layout: 'LayoutAuthenticated',
					title: 'pageContentHubGalleries.metaTitle',
					requiredAccess: 'hasContentHubRead',
				},
			},
			{
				path: 'galleries/:galleryId',
				name: 'ContentHubGallery',
				component: () => import('@/views/ContentHubGallery.vue'),
				props: true,
				meta: {
					requiresAuth: true,
					layout: 'LayoutAuthenticated',
					title: 'pageContentHubGallery.metaTitle',
					requiredAccess: 'hasContentHubRead',
					profileSwitchRedirect: 'ContentHubIndex',
				},
			},
			{
				path: 'galleries/:galleryId/upload',
				name: 'ContentHubGalleryUpload',
				component: () => import('@/views/ContentHubGalleryUpload.vue'),
				props: true,
				meta: {
					requiresAuth: true,
					layout: 'LayoutAuthenticated',
					title: 'pageContentHubGalleryUpload.metaTitle',
					requiredAccess: 'hasContentHubWrite',
					profileSwitchRedirect: 'ContentHubIndex',
				},
			},
			{
				path: 'galleries/:galleryId/media/:mediaId',
				name: 'ContentHubSingleMedia',
				component: () => import('@/views/ContentHubSingleMedia.vue'),
				props: true,
				meta: {
					requiresAuth: true,
					layout: 'LayoutAuthenticated',
					title: 'pageContentHubSingleMedia.metaTitle',
					requiredAccess: 'hasContentHubRead',
					profileSwitchRedirect: 'ContentHubIndex',
				},
			},
			{
				path: 'my-content',
				name: 'ContentHubMyContent',
				component: () => import('@/views/ContentHubMyContent.vue'),
				meta: {
					requiresAuth: true,
					layout: 'LayoutAuthenticated',
					title: 'pageContentHubMyContent.metaTitle',
					requiredAccess: 'hasContentHubTaggedOnlyRead',
				},
			},
			{
				path: 'media',
				name: 'ContentHubMedia',
				component: () => import('@/views/ContentHubMedia.vue'),
				meta: {
					requiresAuth: true,
					layout: 'LayoutAuthenticated',
					title: 'pageContentHubMedia.metaTitle',
					requiredAccess: 'hasContentHubRead',
					hideTabsNavigation: true,
				},
			},
			{
				path: 'content-keywords',
				name: 'ContentHubContentKeywords',
				component: () => import('@/views/ContentHubContentKeywords.vue'),
				meta: {
					requiresAuth: true,
					layout: 'LayoutAuthenticated',
					title: 'pageContentHubContentKeywords.metaTitle',
					requiredAccess: 'hasContentHubWrite',
				},
			},
			{
				path: 'content-keywords/:contentKeyword',
				name: 'ContentHubContentKeyword',
				component: () => import('@/views/ContentHubContentKeyword.vue'),
				props: true,
				meta: {
					requiresAuth: true,
					layout: 'LayoutAuthenticated',
					title: 'pageContentHubContentKeyword.metaTitle',
					requiredAccess: 'hasContentHubWrite',
				},
			},
		],
	},
];

export default routes;
