import { computed } from 'vue';
import { useRoute } from 'vue-router';
import layouts from '@/layouts';

export function useLayouts() {
	const route = useRoute();
	const layout = computed(() => route.meta.layout);
	const layoutOptions = computed(() => route.meta.layoutOptions);

	return {
		layout,
		layouts,
		layoutOptions,
	};
}
