import { useI18n } from 'vue-i18n';
import { useUserStore } from '@/stores/userStore';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { groupBy, sortBy } from 'lodash-es';
import { type UserProfile } from '@/types/users';
import { type Role, ROLES } from '@teamworksdev/influencer-core';
import { useAuthStore } from '@/stores/authStore';
import { orderBy } from 'lodash-es';

export interface IDisplayProfile {
	uuid: string;
	role: Role;
	title: string;
	subtitle?: string;
	image?: string | null;
	image_alt?: string;
	avatar?: string | null;
	avatar_alt?: string;
	disabled: boolean;
	is_default: boolean;
	href?: string | null;
}

export function useUserProfiles() {
	const { t } = useI18n();
	const {
		profile,
		userProfiles,
	} = storeToRefs(useUserStore());
	const authStore = useAuthStore();
	const profilesSearch = ref('');

	class DisplayProfile implements IDisplayProfile {
		uuid: string;
		role: Role;
		title: string;
		subtitle?: string;
		image?: string | null;
		image_alt?: string;
		avatar?: string | null;
		avatar_alt?: string;
		disabled: boolean;
		is_default: boolean;
		href?: string | null;

		constructor(profile: UserProfile) {
			this.uuid = profile.uuid;
			this.role = profile.role_id;
			this.is_default = profile.is_default;
			this.title = getDisplayName(profile.role_id);
			this.subtitle = '';
			this.image = null;
			this.image_alt = '';
			this.avatar = null;
			this.avatar_alt = '';
			this.disabled = false;
			this.href = null;

			switch (profile.role_id) {
				case ROLES.TEAM_USER:
					this.title = profile.academic_profile.team.name;
					this.subtitle = profile.academic_profile.group.name;
					this.image = profile.academic_profile.team.banner;
					this.image_alt = `${this.title} ${t('image')}`;
					this.avatar = profile.academic_profile.team.logo;
					this.avatar_alt = `${this.title} ${t('logo')}`;
					break;
				case ROLES.BRAND_USER:
					this.title = profile.exchange_brand.name;
					this.avatar = profile.exchange_brand.logo;
					this.avatar_alt = `${this.title} ${t('logo')}`;
					break;
				case ROLES.COMPLIANCE_OFFICER:
					this.title = profile.school.name;
					this.image = profile.school.banner;
					this.image_alt = `${this.title} ${t('image')}`;
					this.avatar = profile.school.logo;
					this.avatar_alt = `${this.title} ${t('logo')}`;
					break;
				case ROLES.AGENT:
					this.title = `${profile.athlete.first_name} ${profile.athlete.last_name}`;
					this.avatar = profile.athlete.image;
					this.avatar_alt = this.title;
					this.disabled = false;
					break;
				case ROLES.SUPER_ADMINISTRATOR:
					this.href = `${import.meta.env.VITE_INFLCR_V2_BASE}/auth/swap?role=${ROLES.SUPER_ADMINISTRATOR}&token=${authStore.token?.access_token}`;
					break;
				case ROLES.ADMINISTRATOR:
					this.href = `${import.meta.env.VITE_INFLCR_V2_BASE}/auth/swap?role=${ROLES.ADMINISTRATOR}&token=${authStore.token?.access_token}`;
					break;
				case ROLES.CLIENT_SUCCESS:
					this.href = `${import.meta.env.VITE_INFLCR_V2_BASE}/auth/swap?role=${ROLES.CLIENT_SUCCESS}&token=${authStore.token?.access_token}`;
					break;
				default:
					break;
			}
		}
	}

	const displayProfiles = computed<IDisplayProfile[]>(() => {
		return orderBy(userProfiles.value?.map((p) => new DisplayProfile(p)), 'title', 'asc');
	});

	const profilesFiltered = computed<IDisplayProfile[]>(() => {
		return displayProfiles.value.filter((profile) => {
			const checkKeys: (keyof IDisplayProfile)[] = ['title', 'subtitle'];
			return checkKeys.some((key) => (
				(profile[key] as string)
					?.toLowerCase()
					.includes(profilesSearch.value.toLowerCase())
			));
		});
	});

	const getDisplayName = (role: Role) => (
		{
			1: t('userProfileRoles.superAdmin'),
			2: t('userProfileRoles.admin'),
			3: t('userProfileRoles.clientSuccess'),
			4: t('userProfileRoles.teamUser'),
			5: t('userProfileRoles.complianceOfficer'),
			6: t('userProfileRoles.agent'),
			7: t('userProfileRoles.brandUser'),
		}[role]
	);

	const profilesGrouped = computed<{
		displayName?: string;
		profiles: IDisplayProfile[];
		role: Role;
	}[]>(() => (
		sortBy(Object.entries(groupBy(profilesFiltered.value, 'role'))
			.map(([, profiles]) => {
				const role = profiles[0].role;
				return {
					displayName: getDisplayName(role),
					profiles,
					role,
				};
			}), 'displayName')
	));

	const activeDisplayProfile = computed<IDisplayProfile | undefined>(() => {
		return profile && profile.value ? new DisplayProfile(profile.value) : undefined;
	});

	return {
		DisplayProfile,
		displayProfiles,
		profilesGrouped,
		profilesSearch,
		activeDisplayProfile,
	};
}
