<template>
	<VSheet
		class="login-preview d-flex flex-column ga-8 bg-gradient-radial-primary overflow-hidden"
		color="black"
		width="100%"
		rounded
	>
		<div class="flex-1-1 position-relative overflow-hidden">
			<video
				v-if="autoplayEnabled"
				ref="loginVideo"
				class="preview-video position-absolute fill-height w-100 rounded"
				:src="previewVideo"
				:alt="`Influencer ${t('preview')}`"
				muted
			/>
			<VImg
				v-else
				cover
				:src="previewImage"
				:alt="`Influencer ${t('preview')}`"
				class="preview-image"
				loading="lazy"
				width="100%"
				height="100%"
			/>
		</div>
	</VSheet>
</template>

<script setup lang="ts">
import previewImage from '@/assets/login__preview.png';
import previewVideo from '@/assets/login__preview.mp4';
import { onMounted, onBeforeMount, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const loginVideo = ref<HTMLVideoElement | null>(null);
const autoplayEnabled = ref(true);

onBeforeMount(() => {
	// @ts-expect-error getAutoplayPolicy is not in the types but supported in most modern browsers
	autoplayEnabled.value = window.navigator.getAutoplayPolicy ? window.navigator.getAutoplayPolicy('mediaelement') !== 'disallowed' : true;
});

onMounted(async () => {
	if (autoplayEnabled.value) {
		try {
			await loginVideo.value?.play();
		} catch {
			autoplayEnabled.value = false;
		}
	}
});
</script>

<style scoped lang="scss">
.preview-video {
	object-fit: cover;
};
</style>
