import { reactive, ref, watch, type MaybeRef, unref } from 'vue';
import type { TransactionsStoreId } from '@/stores/exchangeTransactionsStore';
import type { BaseTransaction, TransactionCostBreakdown } from '@/types/transactions';

interface UseExchangeTransactionPaymentState {
	isOpen: boolean;
	transactions: BaseTransaction[];
	costBreakdown: TransactionCostBreakdown | undefined;
	transactionsStoreId: TransactionsStoreId | undefined;
	onPaymentSent: (() => void) | undefined;
}

const DEFAULT_STATE: UseExchangeTransactionPaymentState = {
	isOpen: false,
	transactions: [],
	costBreakdown: undefined,
	transactionsStoreId: undefined,
	onPaymentSent: undefined,
};

const getDefaultState = () => ({ ...DEFAULT_STATE });

const state = reactive<UseExchangeTransactionPaymentState>(getDefaultState());

function resetState() {
	Object.assign(state, getDefaultState());
}

const isRendered = ref<boolean>(false);

watch(() => state.isOpen, (isOpen) => {
	if (isOpen && !isRendered.value) isRendered.value = true;
	if (!isOpen) resetState();
});

interface UseExchangeTransactionPaymentDialogOptions {
	transactionsStoreId?: MaybeRef<TransactionsStoreId>;
	onPaymentSent?: () => void;
}

export function useExchangeTransactionPaymentDialog(options?: UseExchangeTransactionPaymentDialogOptions) {
	state.onPaymentSent = () => {
		close();
		options?.onPaymentSent?.();
	};

	async function open(transactions: MaybeArray<BaseTransaction>, costBreakdown?: TransactionCostBreakdown) {
		state.transactions = Array.isArray(transactions) ? transactions : [transactions];
		state.costBreakdown = costBreakdown;
		state.transactionsStoreId = unref(options?.transactionsStoreId);

		state.isOpen = true;
	}

	function close() {
		state.isOpen = false;
	}

	return {
		state,
		isRendered,
		resetState,
		open,
		close,
	};
}
