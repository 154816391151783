<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		fill="none"
	>
		<path
			d="M12.609 19.0562C11.6944 19.3114 10.7257 19.4513 9.72271 19.4574C11.0731 20.251 12.6659 20.7046 14.346 20.7046C14.9051 20.7046 15.6098 20.5904 16.0939 20.5007C16.1745 20.4857 16.2523 20.4708 16.3263 20.4563L17.861 21.6624C18.8868 22.4685 20.3934 21.7403 20.3934 20.4384V18.4132C21.9839 16.9669 23 14.9319 23 12.6512C23 9.65653 21.2781 7.12134 18.7749 5.73403C19.2013 6.51292 19.5145 7.35379 19.6947 8.23815C20.842 9.40723 21.5403 10.9544 21.5403 12.6512C21.5403 14.6962 20.526 16.524 18.9336 17.7343V20.4384C18.9336 20.5252 18.8332 20.5737 18.7648 20.52L16.6919 18.891C16.6919 18.891 15.1674 19.2498 14.346 19.2498C13.7471 19.2498 13.1652 19.1827 12.609 19.0562Z"
			fill="currentColor"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M5.22971 17.9163C5.16141 17.9701 5.0611 17.9215 5.0611 17.8348V15.1317C3.47081 13.9218 2.45783 12.0947 2.45783 10.0504C2.45783 6.40748 5.67468 3.45427 9.64285 3.45427C13.611 3.45427 16.8279 6.40748 16.8279 10.0504C16.8279 13.6934 13.611 16.6466 9.64285 16.6466C8.8225 16.6466 7.29991 16.288 7.29991 16.288L5.22971 17.9163ZM7.66513 17.8527C7.73899 17.8672 7.81669 17.8821 7.89714 17.897C8.38061 17.9868 9.08448 18.1009 9.64285 18.1009C14.2998 18.1009 18.2857 14.608 18.2857 10.0504C18.2857 5.49294 14.2998 2 9.64285 2C4.98591 2 1 5.49294 1 10.0504C1 12.3303 2.01482 14.3646 3.60327 15.8103V17.8348C3.60327 19.1362 5.10791 19.8641 6.13235 19.0583L7.66513 17.8527Z"
			fill="currentColor"
		/>
		<path
			d="M11.1146 8.41157L11.1148 8.41143L11.6847 7.85838L11.6849 7.85824C11.8503 7.69838 11.8225 7.43634 11.6467 7.30123C11.254 6.99567 10.7703 6.81705 10.2696 6.79416V6.11458C10.2696 5.91166 10.1036 5.75 9.90488 5.75H9.38049C9.18179 5.75 9.01572 5.91166 9.01572 6.11458V6.79265C7.94324 6.82692 7.08837 7.75258 7.18901 8.85211C7.26242 9.65237 7.87524 10.2936 8.63615 10.5149L10.3155 11.003L10.3156 11.0031C10.4781 11.0509 10.5889 11.2 10.5889 11.3636C10.5889 11.5692 10.4207 11.7396 10.208 11.7396H9.12158C8.94166 11.7396 8.76482 11.6853 8.61795 11.5861L8.61749 11.5858C8.47557 11.4911 8.28869 11.5153 8.17073 11.6301L8.17059 11.6302L7.60066 12.1833L7.60055 12.1834C7.43504 12.3433 7.46289 12.6054 7.63874 12.7405C8.03141 13.046 8.51509 13.2246 9.01572 13.2475V13.9271C9.01572 14.13 9.18179 14.2917 9.38049 14.2917H9.90488C10.1036 14.2917 10.2696 14.13 10.2696 13.9271V13.2424C11.0311 13.1911 11.7381 12.7271 11.9958 11.9943C12.3695 10.9309 11.7409 9.84319 10.7393 9.55283L8.96954 9.03852L8.96913 9.0384C8.80777 8.99225 8.69649 8.84222 8.69649 8.67806C8.69649 8.47245 8.86472 8.30208 9.07733 8.30208H10.1638C10.3437 8.30208 10.5206 8.35635 10.6674 8.45555L10.6679 8.45586C10.8098 8.5506 10.9967 8.52636 11.1146 8.41157Z"
			fill="currentColor"
		/>
	</svg>
</template>
