import { i18n } from '@/plugins/i18n';
import { sortBy, camelCase } from 'lodash-es';
import { SPORTS, type Sport } from '@teamworksdev/influencer-core';

interface SportsList {
	id: Sport;
	name: string;
}

const getSportKeyById = (id: Sport): string | undefined => {
	const key = Object.keys(SPORTS).find((k) => SPORTS[k as keyof typeof SPORTS] === id);
	if (!key) console.error(`Sport with id ${id} not found`);
	return key;
};

const generateSportsListFromIds = (ids: Sport[] | undefined): SportsList[] => {
	if (!ids) return [];
	const list = ids.map((id) => {
		const sportKey = getSportKeyById(id);
		if (!sportKey) return;
		return {
			id,
			name: i18n.global.t(`sports.${camelCase(sportKey)}`),
		};
	}).filter((i) => i !== undefined);
	return sortBy(list, 'name');
};

const generateSportsListFromKeys = (keys: string[]): SportsList[] => {
	const list = keys.map((key) => ({
		id: SPORTS[key as keyof typeof SPORTS],
		name: i18n.global.t(`sports.${camelCase(key)}`),
	}));
	return sortBy(list, 'name');
};

const sportIds = Object.values(SPORTS);

const sports = generateSportsListFromKeys(Object.keys(SPORTS));

const sportsObj = sports.reduce((obj, sport) => {
	obj[sport.id] = sport.name;
	return obj;
}, {} as { [key in Sport]: string });

const getSportName = (id: Sport) => sportsObj[id];

export {
	type SportsList,
	getSportKeyById,
	generateSportsListFromKeys,
	generateSportsListFromIds,
	sportIds,
	sports,
	sportsObj,
	getSportName,
};
