import axios, { type AxiosResponse, type InternalAxiosRequestConfig } from 'axios';
import { isEmpty, unset } from 'lodash-es';
import { useAuthStore } from '@/stores/authStore';
import { useUserStore } from '@/stores/userStore';
import { useMainStore } from '@/stores/mainStore';

declare module 'axios' {
	export interface AxiosRequestConfig {
		key: string;
		bypassAbort?: boolean;
		mock?: boolean;
		requiresAuth?: boolean;
		requiresInflcrId?: boolean;
		requiresAcceptedTou?: boolean;
		bypassImpersonation?: boolean;
		bypassErrorToast?: boolean;
	}
}

const getAbortController = (config: InternalAxiosRequestConfig) => {
	if (!config.key || config.bypassAbort) return new AbortController();
	const mainStore = useMainStore();
	mainStore.abortControllers[config.key]?.abort('Aborted by new request');
	return mainStore.abortControllers[config.key] = new AbortController();
};

const removeAbortController = (key?: string) => {
	if (!key) return;
	const mainStore = useMainStore();
	unset(mainStore.abortControllers, key);
};

function getAuthToken(bypassImpersonation?: boolean) {
	const authStore = useAuthStore();
	if (bypassImpersonation) return authStore.token?.access_token;
	return authStore.impersonationToken?.access_token || authStore.token?.access_token;
}

function hasUserAcceptedTermsOfUse() {
	const userStore = useUserStore();
	return userStore.hasUserAcceptedLatestTermsOfUse;
}


function setAuthHeader(config: InternalAxiosRequestConfig, token?: string) {
	if (!isEmpty(token)) config.headers.set('Authorization', `Bearer ${token}`);
	return config;
}

function getInflcrId(bypassImpersonation?: boolean) {
	const userStore = useUserStore();
	if (bypassImpersonation) return userStore.inflcrImpersonatedById ?? undefined;
	return userStore.inflcrId ?? undefined;
}

function setInflcrIdHeader(config: InternalAxiosRequestConfig, id?: string) {
	if (!isEmpty(id)) config.headers.set('Inflcr-Id', id);
	return config;
}

function onRequestFulfilled(config: InternalAxiosRequestConfig) {
	const controller = getAbortController(config);
	config.signal = controller.signal;

	if (import.meta.env.MODE !== 'production' && !!config.mock) {
		config.baseURL = import.meta.env.VITE_MOCK_API_BASE;
	}

	if (config.requiresAuth) {
		config = setAuthHeader(config, getAuthToken(config.bypassImpersonation));
		if (isEmpty(config.headers.get('Authorization'))) {
			controller.abort('Missing authorization header');
		}
	}

	if (config.requiresAuth && config.requiresInflcrId) {
		config = setInflcrIdHeader(config, getInflcrId(config.bypassImpersonation));
		if (isEmpty(config.headers.get('Inflcr-Id'))) {
			controller.abort('Missing INFLCR profile ID header');
		}
	}

	if (config.requiresAcceptedTou && !hasUserAcceptedTermsOfUse()) {
		controller.abort('User has not accepted Terms of Use');
	}

	return config;
}

function onResponseFulfilled(response: AxiosResponse) {
	removeAbortController(response.config.key);
	return response;
}

const initAxios = () => {
	axios.defaults.baseURL = import.meta.env.VITE_API_BASE;
	axios.defaults.requiresAuth = true;
	axios.defaults.requiresInflcrId = true;
	axios.defaults.requiresAcceptedTou = true;
	axios.interceptors.request.use(onRequestFulfilled);
	axios.interceptors.response.use(onResponseFulfilled);
};

export { initAxios };
