import { computed, unref, type MaybeRef } from 'vue';
import { useHead } from '@unhead/vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

export function getRouteTitle() {
	const { t } = useI18n();
	const route = useRoute();
	return computed(() => route.meta?.title ? t(route.meta.title) : null);
}

export function setTitleTag(
	metaTitle: MaybeRef<string | undefined | null>,
	options: {
		fallback?: string;
		context?: string[];
	} = {},
) {
	const routeTitle = getRouteTitle();

	useHead({
		title: () => unref(metaTitle)
			? [unref(metaTitle), ...(options.context ?? [])].join(' | ')
			: options.fallback || routeTitle.value || null,
	});
}
