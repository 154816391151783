<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		version="1.2"
		viewBox="0 0 83 83"
	>
		<path
			fill="currentColor"
			fill-rule="evenodd"
			d="M78 60.9c-7 13.1-20.7 22-36.5 22C18.7 82.9.2 64.4.2 41.6.2 18.8 18.7.4 41.5.4c22.8 0 41.2 18.4 41.2 41.2 0 6.1-1.3 11.9-3.7 17.1l-1.2-17.3H67.1l-1.2 4.4h1.6l-5.3 8.4-.7-12.8H50.6l-1.2 4.4h1.7L44 56.6l-5.9-.1c-.6 0-2.9-.5-2.9-3.4 0-3 2.7-7.2 5.6-7.2H42c-.2.7-.7 2.2-.7 2.2h5.2l1.9-6.7-8-.1c-5 0-9 3.6-10.3 7.3l2.1-7.2h-7.1l-1.3 4.4h1.9l-1.9 6.5-4.7-10.9h-6.6l-1.3 4.4H13L8.9 60.9h5.3s2.1-7.5 2.6-9.4c.8 1.8 3.9 9.4 3.9 9.4h5.9l2.8-9.6c-.3 1.4-.3 2.9 0 4.4.5 3.1 3.5 5.2 6.9 5.2H47l1.9-2.8h7.6l.2 2.8h6.7l1.7-2.8h7.8l.3 2.8zM56.1 47.1l.2 6.3H52zm12.4 6.2 4.1-6.2.2 6.2z"
		/>
	</svg>
</template>
