<template>
	<svg
		width="34"
		height="39"
		viewBox="0 0 34 39"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M33.9999 26.9099V11.7793C33.9999 10.431 33.2808 9.20258 32.1123 8.54342L19.019 0.963118C17.8505 0.303961 16.4423 0.303961 15.2738 0.963118L2.18056 8.51346C1.01205 9.20258 0.292969 10.431 0.292969 11.7793V26.9099C0.292969 28.2582 1.01205 29.4866 2.18056 30.1458L15.2738 37.7261C16.4423 38.3853 17.8505 38.3853 19.019 37.7261L32.1123 30.1458C33.2808 29.4866 33.9999 28.2283 33.9999 26.9099Z"
			fill="#EAA934"
		/>
		<path
			d="M21.8559 10.7091L21.8559 11.8666L20.1937 11.8666L20.1937 10.7091C20.1937 10.57 20.0788 10.4522 19.9398 10.4522L14.527 10.4522C14.385 10.4522 14.2701 10.57 14.2701 10.7091L14.2701 11.8666L12.6079 11.8666L12.6079 10.7091C12.6079 9.65128 13.4692 8.79297 14.527 8.79297L19.9398 8.79297C20.9946 8.79297 21.8559 9.65128 21.8559 10.7091Z"
			fill="#AD7C3B"
		/>
		<path
			d="M26.9206 19.4162L23.2819 19.7668C21.9007 19.8998 20.5195 19.9874 19.1323 20.0267L19.1323 22.3599C19.1323 22.6984 18.8543 22.9764 18.5128 22.9764L15.953 22.9764C15.6115 22.9764 15.3334 22.6984 15.3334 22.3599L15.3334 20.0267C13.9462 19.9874 12.5651 19.8998 11.1839 19.7668L7.54514 19.4162C7.47261 19.4102 7.40008 19.3981 7.33057 19.377L7.33057 26.5275C7.33057 27.1954 7.87154 27.7364 8.53946 27.7364L25.9263 27.7364C26.5942 27.7364 27.1352 27.1954 27.1352 26.5275L27.1352 19.377C27.0657 19.3981 26.9931 19.4102 26.9206 19.4162Z"
			fill="white"
		/>
		<path
			d="M15.3334 20.0267L15.3334 21.1268C12.6406 21.2084 9.9599 21.695 7.33057 22.0728L7.33057 19.377C7.40008 19.3981 7.47261 19.4102 7.54514 19.4162L11.1839 19.7668C12.5651 19.8998 13.9462 19.9874 15.3334 20.0267Z"
			fill="#AD7C3B"
		/>
		<path
			d="M27.1357 19.377L27.1357 22.0728C24.5093 21.7373 21.8256 21.2417 19.1328 21.145L19.1328 20.0267C20.52 19.9874 21.9012 19.8998 23.2823 19.7668L26.9211 19.4162C26.9936 19.4102 27.0661 19.3981 27.1357 19.377Z"
			fill="#AD7C3B"
		/>
		<path
			d="M28.0148 13.8348L28.0148 18.214C28.0148 18.764 27.6491 19.2355 27.1354 19.3775C27.0658 19.3987 26.9933 19.4108 26.9208 19.4168L23.282 19.7674C21.9009 19.9004 20.5197 19.988 19.1325 20.0273L19.1325 18.897C19.1325 18.7489 19.0811 18.6099 18.9874 18.5071C18.8756 18.3681 18.7064 18.2805 18.513 18.2805L15.9531 18.2805C15.7597 18.2805 15.5905 18.3681 15.4786 18.5071C15.385 18.6099 15.3336 18.7489 15.3336 18.897L15.3336 20.0273C13.9464 19.988 12.5652 19.9004 11.1841 19.7674L7.54531 19.4168C7.47278 19.4108 7.40024 19.3987 7.33073 19.3775C6.81695 19.2355 6.44824 18.764 6.44824 18.214L6.44824 13.8348C6.44824 13.1699 6.98922 12.6289 7.65713 12.6289L26.809 12.6289C27.4769 12.6289 28.0148 13.1699 28.0148 13.8348Z"
			fill="white"
		/>
		<path
			d="M19.1329 18.8958L19.1329 22.3593C19.1329 22.6978 18.8549 22.9758 18.5134 22.9758L15.9535 22.9758C15.612 22.9758 15.334 22.6978 15.334 22.3593L15.334 18.8958C15.334 18.7477 15.3854 18.6087 15.4791 18.506C15.5909 18.3669 15.7601 18.2793 15.9535 18.2793L18.5134 18.2793C18.7068 18.2793 18.876 18.3669 18.9879 18.506C19.0815 18.6087 19.1329 18.7477 19.1329 18.8958Z"
			fill="#EAA934"
		/>
	</svg>
</template>
