import { unref, type MaybeRef } from 'vue';
import { useI18n } from 'vue-i18n';
import router from '@/router';
import { useAsyncState } from '@vueuse/core';
import { useMainStore } from '@/stores/mainStore';
import { BULLETIN_TYPES } from '@teamworksdev/influencer-core';
import { useBulletinsService } from '@/api';
import { useExchangeBulletinStore } from '@/stores/exchangeBulletinStore';
import { isApiError } from '@/api/utilities/provider';
import { useConfirmationDialog } from '@/composables';
import type {
	SingleExchangeBulletin,
	ApiCreateBulletinApplicationRequestModel,
} from '@/types/bulletins';


interface UseBulletinAsyncOptions {
	redirectOnError?: boolean;
	onApplicationCreated?: () => void;
}

const defaultOptions: UseBulletinAsyncOptions = {
	redirectOnError: true,
	onApplicationCreated: () => {},
};

export function useBulletinAsync(
	bulletinUuid: MaybeRef<string | undefined>,
	options?: UseBulletinAsyncOptions,

) {
	const exchangeBulletinsService = useBulletinsService();
	const exchangeBulletinStore = useExchangeBulletinStore();
	const mainStore = useMainStore();
	const { t } = useI18n();
	const {
		open: openConfirmationDialog,
		reset: resetConfirmationDialog,
	} = useConfirmationDialog();
	const _options = { ...defaultOptions, ...options };

	const getExchangeBulletinPromise = async (uuid: MaybeRef<string | undefined>): Promise<SingleExchangeBulletin | null> => {
		const localUuid = unref(uuid);
		if (!localUuid) return null;
		try {
			const bulletin = await exchangeBulletinsService.getBulletin(localUuid);
			exchangeBulletinStore.setCachedBulletin(bulletin);
			return bulletin;
		} catch (error) {
			if (_options.redirectOnError && isApiError(error)) {
				error.status === 403
					? router.replace({ name: 'NoAccess' })
					: router.replace({ name: 'NotFound' });
			}
			return null;
		}
	};

	const {
		state: bulletin,
		isLoading: isBulletinLoading,
		execute: getBulletin,
	} = useAsyncState(
		() => getExchangeBulletinPromise(bulletinUuid),
		exchangeBulletinStore.getCachedBulletin(unref(bulletinUuid))?.value,
		{ immediate: false, onError: (error) => { throw error; } },
	);

	async function createBulletinApplication(answers: ApiCreateBulletinApplicationRequestModel) {
		if (!bulletin.value) return;

		if (bulletin.value.type === BULLETIN_TYPES.OFFER) {
			const userResponse = await openConfirmationDialog({
				title: t('bulletinApplicationConfirmation.title'),
				description: t('bulletinApplicationConfirmation.description'),
				confirmText: t('bulletinApplicationConfirmation.confirmText'),
				confirmColor: 'black',
			});

			if (!userResponse) return;
		}

		try {
			await exchangeBulletinsService.createBulletinApplication(bulletin.value.uuid, answers);
			mainStore.setToast({
				title: t('bulletinDrawer.applicationCreated'),
				type: 'success',
			});
			_options.onApplicationCreated?.();
		} catch (error) {
			console.error(error);
		} finally {
			resetConfirmationDialog();
		}
	}

	return {
		bulletin,
		isBulletinLoading,
		getBulletin,
		createBulletinApplication,
	};
}
