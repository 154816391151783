<template>
	<VAppBar
		v-if="schoolSite"
		:height="96"
		:color="transparentBackground ? 'transparent' : 'school-background'"
		class="layout-exchange-school-site__app-bar"
	>
		<VContainer class="d-flex align-center ga-4 ga-sm-6">
			<RouterLink :to="{ name: 'ExchangeSchoolSiteIndex' }">
				<VImg
					:src="schoolSite.exchangeLogo"
					:alt="`${schoolSite.exchangeTitle} ${t('logo')}`"
					height="64"
					width="64"
				/>
			</RouterLink>
			<nav
				v-if="!mobile"
				class="d-flex ga-6"
			>
				<VBtn
					v-for="item, index in mainNavItems"
					:key="index"
					:to="item.route"
					:active="false"
					variant="text"
					:color="transparentBackground ? 'white' : undefined"
					:data-testid="item.testId"
				>
					{{ item.text }}
				</VBtn>
			</nav>
			<VSpacer />
			<nav class="d-flex align-center ga-4 ga-sm-6">
				<template v-if="!mobile">
					<VBtn
						v-for="item, index in appendedNavItems"
						:key="index"
						:to="item.route"
						:active="false"
						variant="text"
						:color="transparentBackground ? 'white' : undefined"
						:data-testid="item.testId"
					>
						{{ item.text }}
					</VBtn>
				</template>
				<VBtnSchool
					:active="false"
					class="text-decoration-none"
					data-testid="buttonRegisterNow"
					@click="openRegisterNowDialog"
				>
					{{ t('registerNow') }}
				</VBtnSchool>
				<VMenu v-if="mobile">
					<template #activator="{ props }">
						<VBtn
							v-bind="props"
							:active="false"
							icon
							:color="transparentBackground ? 'white' : undefined"
						>
							<VIcon>menu</VIcon>
						</VBtn>
					</template>
					<VList nav>
						<VListItem
							v-for="item, index in [mainNavItems, appendedNavItems].flat(1)"
							:key="index"
							class="text-decoration-none"
							@click="router.push(item.route)"
						>
							<VListItemTitle>{{ item.text }}</VListItemTitle>
						</VListItem>
					</VList>
				</VMenu>
			</nav>
		</VContainer>
	</VAppBar>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useDisplay } from 'vuetify';
import { useRouter, type RouteLocationNamedRaw } from 'vue-router';
import { isAuthenticated } from '@/stores/authStore';
import { useExchangeSchoolSiteRegisterNowDialog } from '@/composables';
import { useExchangeSchoolSiteStore } from '@/stores/exchangeSchoolSiteStore';


withDefaults(defineProps<{
	transparentBackground: boolean;
}>(), {
	transparentBackground: false,
});

const { t } = useI18n();
const { mobile } = useDisplay();

const { schoolSite } = storeToRefs(useExchangeSchoolSiteStore());

interface NavItem {
	route: RouteLocationNamedRaw;
	text: string;
	testId: string;
}

const mainNavItems: NavItem[] = [
	{
		route: { name: 'ExchangeSchoolSiteIndex' },
		text: t('navigationMenu.browseAthletes'),
		testId: 'linkBrowseAthletes',
	},
	{
		route: { name: 'ExchangeSchoolSiteLearnMore' },
		text: t('learnMore'),
		testId: 'linkLearnMore',
	},
];

const appendedNavItems: NavItem[] = [
	{
		route: isAuthenticated() ? { name: 'ProfileChooserIndex' } : { name: 'LoginIndex' },
		text: t('logIn'),
		testId: 'linkLogin',
	},
];

const router = useRouter();

const { open: openRegisterNowDialog } = useExchangeSchoolSiteRegisterNowDialog();

</script>
