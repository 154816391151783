import type { SingleBulletinApplicant, SingleExchangeBulletin } from '@/types/bulletins';
import { reactive, ref, watch, type MaybeRef, unref } from 'vue';

interface UseBulletinApplicantProfileDrawerState {
	isOpen: boolean;
	applicant?: SingleBulletinApplicant;
	bulletin?: SingleExchangeBulletin | null;
	hideActions?: boolean;
	onProposeTransaction?: (payload: SingleBulletinApplicant) => void;
	onSendMessage?: (payload: SingleBulletinApplicant) => void;
}

const DEFAULT_STATE: UseBulletinApplicantProfileDrawerState = {
	isOpen: false,
	applicant: undefined,
	bulletin: undefined,
	hideActions: false,
	onProposeTransaction: undefined,
	onSendMessage: undefined,
} as const;

const getDefaultState = () => ({ ...DEFAULT_STATE });

const state = reactive<UseBulletinApplicantProfileDrawerState>(getDefaultState());

function resetState() {
	Object.assign(state, getDefaultState());
}

const isRendered = ref<boolean>(false);

watch(() => state.isOpen, (isOpen) => {
	if (isOpen && !isRendered.value) isRendered.value = true;
	if (!isOpen) resetState();
});

interface UseBulletinApplicantProfileDrawerOptions {
	applicant?: MaybeRef<SingleBulletinApplicant | undefined>;
	bulletin?: MaybeRef<SingleExchangeBulletin | null | undefined>;
	hideActions?: boolean;
	onProposeTransaction?: (payload: SingleBulletinApplicant) => void;
	onSendMessage?: (payload: SingleBulletinApplicant) => void;
}

export function useBulletinApplicantProfileDrawer(options?: UseBulletinApplicantProfileDrawerOptions) {
	const open = () => {
		state.applicant = unref(options?.applicant);
		state.bulletin = unref(options?.bulletin);
		state.hideActions = options?.hideActions;
		state.onProposeTransaction = options?.onProposeTransaction;
		state.onSendMessage = options?.onSendMessage;
		state.isOpen = true;
	};

	return {
		state,
		isRendered,
		resetState,
		open,
	};
}
