import type { RouteLocationNormalized, RouteRecordRaw } from 'vue-router';
import LoginView from '@/views/LoginIndex.vue';
import LoginOktaCallback from '@/views/LoginOktaCallback.vue';
import LoginAxleCallback from '@/views/LoginAxleCallback.vue';
import { isAuthenticated } from '@/stores/authStore';
import { useUserStore } from '@/stores/userStore';
import { storeToRefs } from 'pinia';

function guardAgainstAuthenticatedUser(to: RouteLocationNormalized, from: RouteLocationNormalized) {
	const { profileDefaultRoute } = storeToRefs(useUserStore());
	if (isAuthenticated()) return from.name ? false : { name: profileDefaultRoute.value };
	else return true;
}

const routes: RouteRecordRaw[] = [
	{
		path: '/login',
		children: [
			{
				path: '',
				name: 'LoginIndex',
				component: LoginView,
				meta: {
					layout: 'LayoutLogin',
					requiresAuth: false,
					title: 'pageLogin.metaTitle',
				},
			},
			{
				path: 'forgot-password',
				name: 'LoginForgotPassword',
				component: () => import('@/views/LoginForgotPassword.vue'),
				meta: {
					layout: 'LayoutLogin',
					requiresAuth: false,
					title: 'pageLoginForgotPassword.metaTitle',
				},
			},
			{
				path: 'reset-password',
				name: 'LoginResetPassword',
				component: () => import('@/views/LoginResetPassword.vue'),
				meta: {
					layout: 'LayoutLogin',
					requiresAuth: false,
					title: 'pageLoginResetPassword.metaTitle',
				},
			},
			{
				path: 'confirm-account/:confirmationCode',
				name: 'LoginConfirmAccount',
				component: () => import('@/views/LoginConfirmAccount.vue'),
				props: true,
				meta: {
					layout: 'LayoutLogin',
					requiresAuth: false,
					title: 'pageLoginConfirmAccount.metaTitle',
				},
			},
			{
				path: 'callback',
				name: 'LoginCallback',
				component: LoginOktaCallback,
				meta: {
					layout: 'LayoutLogin',
					requiresAuth: false,
				},
			},
		],
		beforeEnter: [guardAgainstAuthenticatedUser],
	},
	{
		path: '/login/impersonate',
		name: 'LoginImpersonate',
		component: LoginView,
		meta: {
			layout: 'LayoutLogin',
			requiresAuth: false,
			title: 'pageLogin.metaTitle',
		},
	}, {
		path: '/login/axle',
		name: 'LoginAxle',
		component: LoginAxleCallback,
		meta: {
			layout: 'LayoutDefault',
			requiresAuth: false,
		},
	},
	{
		path: '/logout',
		name: 'Logout',
		component: () => import('@/views/LogoutIndex.vue'),
		meta: {
			requiresAuth: false,
			layout: 'LayoutDefault',
			title: 'pageLogout.metaTitle',
		},
		beforeEnter: (to, from) => {
			if (!isAuthenticated()) return from.name ? false : { name: 'LoginIndex' };
		},
	},
];

export default routes;
