import type { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
	{
		path: '/ncaa-registration',
		name: 'NcaaRegistration',
		component: () => import('@/views/NcaaRegistration.vue'),
		meta: {
			requiresAuth: false,
			layout: 'LayoutDefault',
			title: 'pageNcaaRegistration.metaTitle',
		},
	},
];

export default routes;
