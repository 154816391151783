import { computed, reactive, toRefs } from 'vue';
import { defineStore } from 'pinia';
import { cloneDeep, isEqual, omit, uniq } from 'lodash-es';
import { useUserStore } from '@/stores/userStore';
import type { GetExchangeConversationsFilters, ExchangeConversation } from '@/types/conversations';
interface UseExchangeConversationsStoreState {
	filters: GetExchangeConversationsFilters;
	selectedAthletesUuids: string[];
	loadedSelectableAthletesUuids: string[];
	cachedConversation: ExchangeConversation | null;
}

const DEFAULT_FILTERS: GetExchangeConversationsFilters = {
	schools: undefined,
	is_read: undefined,
	type: undefined,
	last_message_sent_by: undefined,
	between: undefined,
	athlete_name: undefined,
};

const getDefaultFilters = (): GetExchangeConversationsFilters => cloneDeep(DEFAULT_FILTERS);

const DEFAULT_STATE: UseExchangeConversationsStoreState = {
	filters: getDefaultFilters(),
	selectedAthletesUuids: [],
	loadedSelectableAthletesUuids: [],
	cachedConversation: null,
};

const getDefaultState = (): UseExchangeConversationsStoreState => cloneDeep(DEFAULT_STATE);

export const useExchangeConversationsStore = defineStore('exchangeConversations', () => {
	const state = reactive(getDefaultState());

	// FILTERS

	const setFilters = (payload: GetExchangeConversationsFilters) => Object.assign(state.filters, payload);

	const resetFilters = () => setFilters(getDefaultFilters());

	const resetDrawerFilters = () => setFilters(omit(getDefaultFilters(), 'athlete_name'));

	const hasActiveFilters = computed(() => !isEqual(state.filters, getDefaultFilters()));

	const hasActiveDrawerFilters = computed(() => !isEqual(
		omit(state.filters, 'athlete_name'),
		omit(getDefaultFilters(), 'athlete_name'),
	));

	// SELECTION

	const { maxAthletesPerBulkMessage } = useUserStore();

	const setSelectedAthletesUuids = (athletesUuids: string[]) => {
		state.selectedAthletesUuids = athletesUuids;
	};

	const selectAthletes = (athletesUuids: string[]) => {
		const selectedAthletesUnique = uniq([...state.selectedAthletesUuids, ...athletesUuids]);
		setSelectedAthletesUuids(selectedAthletesUnique);
	};

	const deselectAthletes = (athletesUuids: string[]) => {
		setSelectedAthletesUuids(state.selectedAthletesUuids.filter((a) => !athletesUuids.includes(a)));
	};

	const toggleSelectAllAthletesOnPage = () => {
		if (!state.loadedSelectableAthletesUuids.length) return;
		allAthletesOnPageSelected.value
			? deselectAthletes(state.loadedSelectableAthletesUuids)
			: selectAthletes(state.loadedSelectableAthletesUuids);
	};

	const deselectAllAthletes = () => setSelectedAthletesUuids([]);

	const toggleSelectedAthlete = (athleteUuid: string) => {
		state.selectedAthletesUuids.includes(athleteUuid)
			? deselectAthletes([athleteUuid])
			: selectAthletes([athleteUuid]);
	};

	const allAthletesOnPageSelected = computed(() => {
		const loadedAthletes = state.loadedSelectableAthletesUuids;
		if (!loadedAthletes.length) return false;
		return everyLoadedAthleteSelected.value;
	});

	const everyLoadedAthleteSelected = computed(() => {
		return state.loadedSelectableAthletesUuids.every((a) => state.selectedAthletesUuids.includes(a));
	});

	const maxAthletesExceeded = computed(() => {
		return state.selectedAthletesUuids.length > maxAthletesPerBulkMessage;
	});

	const setCachedConversation = (conversation: ExchangeConversation) => {
		state.cachedConversation = conversation;
	};

	const clearCachedConversation = () => {
		state.cachedConversation = null;
	};

	const $reset = () => Object.assign(state, getDefaultState());

	return {
		...toRefs(state),
		hasActiveFilters,
		hasActiveDrawerFilters,
		setFilters,
		resetFilters,
		resetDrawerFilters,
		setSelectedAthletesUuids,

		allAthletesOnPageSelected,
		maxAthletesExceeded,
		toggleSelectAllAthletesOnPage,
		deselectAllAthletes,
		toggleSelectedAthlete,

		setCachedConversation,
		clearCachedConversation,
		$reset,
	};
});
