<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 270 329"
	>
		<g filter="url(#a)">
			<ellipse
				cx="88.1083"
				cy="332.133"
				fill="currentColor"
				rx="200.953"
				ry="119.038"
				transform="rotate(25 88.1083 332.133)"
			/></g>
		<g filter="url(#b)">
			<path
				fill="currentColor"
				d="M230.6 121.045-22.7215 243.377l.0001 11.156L230.6 121.045Z"
			/></g>
		<g filter="url(#c)"><ellipse
			cx="16.9442"
			cy="328.846"
			fill="currentColor"
			rx="87.7375"
			ry="85.4141"
			transform="rotate(25 16.9442 328.846)"
		/></g>
		<defs>
			<filter
				id="a"
				width="764.946"
				height="663.671"
				x="-294.365"
				y=".297455"
				color-interpolation-filters="sRGB"
				filterUnits="userSpaceOnUse"
			>
				<feFlood
					flood-opacity="0"
					result="BackgroundImageFix"
				/><feBlend
					in="SourceGraphic"
					in2="BackgroundImageFix"
					result="shape"
				/>
				<feGaussianBlur
					result="effect1_foregroundBlur_2861_19880"
					stdDeviation="96.9609"
				/></filter>
			<filter
				id="b"
				width="328.554"
				height="208.72"
				x="-60.3376"
				y="83.4292"
				color-interpolation-filters="sRGB"
				filterUnits="userSpaceOnUse"
			>
				<feFlood
					flood-opacity="0"
					result="BackgroundImageFix"
				/><feBlend
					in="SourceGraphic"
					in2="BackgroundImageFix"
					result="shape"
				/>
				<feGaussianBlur
					result="effect1_foregroundBlur_2861_19880"
					stdDeviation="18.8081"
				/></filter>
			<filter
				id="c"
				width="347.561"
				height="344.63"
				x="-156.836"
				y="156.531"
				color-interpolation-filters="sRGB"
				filterUnits="userSpaceOnUse"
			>
				<feFlood
					flood-opacity="0"
					result="BackgroundImageFix"
				/><feBlend
					in="SourceGraphic"
					in2="BackgroundImageFix"
					result="shape"
				/>
				<feGaussianBlur
					result="effect1_foregroundBlur_2861_19880"
					stdDeviation="43.2235"
				/></filter>
		</defs>
	</svg>
</template>
